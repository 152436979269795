import { toast } from 'react-toastify';
import { getTokenFromLocalStorage } from '../index.js';

export const fetchNotes = async (patient_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/notes/patient-notes?patient_id=${patient_id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            toast.error('Failed to fetch notes');
            throw new Error('Failed to fetch notes');
        }
        return response.json();
    } catch (error) {
        toast.error('error fetching notes');
        throw error;
    }
};