import { toast } from 'react-toastify';
import { storeTokenInLocalStorage } from '../../helpers';
export const forgotPassword = async (email) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email })
    });

    const data = await response.json();
    if (response.ok) {
      toast.success('Password reset link sent to your email');
      setTimeout(() => {
        alert('Please check your spam folder if you don\'t see the email in your inbox');
      }, 3000);
    } else {
      toast.error(data.message || 'Failed to send reset link');
    }
  } catch (error) {
    toast.error('Network error');
  }
}

export const signIn = async (email, password, navigate) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password })
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/signin`, requestOptions);
    const data = await response.json();

    if (response.ok && data.token) {
      storeTokenInLocalStorage(data.token);
      toast.success('Signed in successfully');
      setTimeout(() => navigate('/patient-list'), 1000);
    } else {
      toast.error('Invalid email or password');
      setTimeout(() => navigate('/signin'), 3000);
    }
  } catch (error) {
    console.error('Error:', error);
    toast.error('Network error');
  }
}