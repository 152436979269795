import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Charges.module.css'
import styles1 from '../../assets/css/ChargesTab.module.css'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { getPatients, addPatientIdToChargesPage , updatePatientOrder} from '../../helpers/index';
import AddPatientTableRow from './AddPatientTableRow';
import t from '../../assets/css/Table.module.css'

const ChargesAddPatient = ({ setShowModal, reRenderPatients, patientsList }) => {

    const [patients, setPatients] = useState([]);
    const [originalPatients, setOriginalPatients] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedPatients, setSelectedPatients] = useState({});

    useEffect(() => {
        getPatients().then(res => {
            const activePatients = res.filter(patient => patient.status === 'active');
            setPatients(activePatients);
            setOriginalPatients(activePatients);
        })
    }, [])

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        const newSelectedPatients = {};
        patients.forEach(patient => {
            newSelectedPatients[patient.id] = isChecked;
        });
        setSelectedPatients(newSelectedPatients);
    };

    const updateSelectedPatient = (patientId, isSelected) => {
        setSelectedPatients(prev => ({ ...prev, [patientId]: isSelected }));
    };

    const handleSearch = (searchTerm) => {
        if (searchTerm.trim() === '') {
            // If the search term is empty, reset to the original patients list
            setPatients(originalPatients);
            return;
        }

        const filteredPatients = originalPatients.filter(patient => {
            return (
                (patient.firstname && patient.firstname.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (patient.middlename && patient.middlename.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (patient.lastname && patient.lastname.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        });

        setPatients(filteredPatients);
    }

    const handleSave = async () => {
        let promises = [];

        // Step 1: Get IDs of selected patients (where value is true)
        Object.entries(selectedPatients).forEach(([patientId, isSelected]) => {
            if (isSelected) {
                // Step 2: Find full patient object from patients array
                const patient = patients.find(p => p.id === parseInt(patientId));

                // Step 3: Create fetch promise with patient data
                promises.push(addPatientIdToChargesPage(patient.id));
            }
        });

        try {
            await Promise.all(promises);

            const selectedPatientIds = Object.keys(selectedPatients).map(Number);

            const arrayOfPatientsId = patients
                .filter(patient => selectedPatientIds.includes(patient.id))
                .map(patient => patient.patient_id);

            if (arrayOfPatientsId.length > 0) {
                await updatePatientOrder(arrayOfPatientsId);
            }
            reRenderPatients(' Patients Added! ');
        } catch (error) {
            console.log(error);
        }

        setSelectedPatients({});
        setShowModal(false);
    }

    return (
        <div className="modal">
            <div className="modalContent">
                <div className="closeModalButton" onClick={() => setShowModal(false)}><Cross fill={'red'} /></div>
                <div className={styles.ColumnContainer} style={{ width: '100%' }}>
                    <h4>Add Patients :</h4>
                    <div className={styles.searchBarContainer}>
                        <SearchIcon className={styles.searchIcon} />
                        <input checked={selectAll} className={styles.input} type="text" placeholder="Search..." onChange={(e) => handleSearch(e.target.value)} />
                    </div>
                    <div className={`${t.container} ${t.container2}`}>
                        <div className={t.tableWrapper}>
                            <table className={`${t.compactTable2} ${t.mobileText}`}>
                                <thead id="tableHeader">
                                    <tr>
                                        <th scope="col">
                                            <input onChange={handleSelectAll} className={styles1.checkbox} type='checkbox' style={{ height: '15px', width: '15px' }} />
                                        </th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Admit Date</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Visit Type</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {patients.map((patient, index) => (
                                        <AddPatientTableRow
                                            key={patient.id}
                                            patient={patient}
                                            isSelected={selectedPatients[patient.id] || false}
                                            onSelectChange={updateSelectedPatient}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={t.totalCounter}>
                            Total Patients: {patients.length}
                        </div>
                    </div>
                    <div onClick={() => handleSave()} className={[styles1.button, styles1.SaveButton].join(' ')}>
                        Save
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChargesAddPatient;