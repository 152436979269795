import React, { useRef, useEffect, useCallback } from 'react';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler.css';
import { getTokenFromLocalStorage } from '../../../helpers';
import { toast } from 'react-toastify';

const Scheduler = ({ events, onEventAdded, startDate, refreshCalendarOnFailure }) => {
    const schedulerContainer = useRef(null);
    const schedulerInstance = useRef(null);

    const postEmployeeCalendarEvent = useCallback(async (text, start_date, end_date, id = null, classname = 'purple') => {
        classname = classname || 'purple';
        schedulerInstance.current.loading = true;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
            body: JSON.stringify({ text, start_date, end_date, id, classname })
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/calendar/employee-calendar', requestOptions);
            const data = await response.json();
            if (response.ok) {
                schedulerInstance.current.loading = false;
                if (data.success) {
                    toast.success(data.message);
                    onEventAdded(start_date);
                }
                if (data.success === false) {
                    refreshCalendarOnFailure();
                    toast.error(data.message);
                    return;
                }
            } else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            schedulerInstance.current.loading = false;
        }
    }, []);

    const deleteEmployeeCalendarEvent = useCallback(async (id) => {
        schedulerInstance.current.loading = true;
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            }
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/calendar/employee-calendar/${id}`, requestOptions);
            const data = await response.json();
            if (response.ok) {
                schedulerInstance.current.loading = false;
                onEventAdded();
            } else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            schedulerInstance.current.loading = false;
        }
    }, []);

    const initScheduler = useCallback(() => {
        const scheduler = schedulerInstance.current;
        const isMobile = window.innerWidth < 630;
        scheduler.skin = 'terrace';
        scheduler.config.header = [
            'day',
            'week',
            'month',
            'date',
            'prev',
            'today',
            'next'
        ];
        scheduler.config.multi_day = true;
        scheduler.config.max_month_events = isMobile ? 5 : 5;
        scheduler.config.preserve_scroll = true;
        scheduler.config.responsive_lightbox = true;
        scheduler.config.agenda_end = 24;
        scheduler.config.scroll_hour = 24;
        scheduler.config.start_on_monday = false;
        scheduler.config.time_step = 15;
        scheduler.config.hour_height = 100;
        scheduler.config.hour_size_px = 100;
        scheduler.config.last_hour = 24; // Extends timeline to show full day
        scheduler.templates.event_class = function (start, end, event) {
            return event.classname || "purple";
        };

        scheduler.templates.month_events_link = function (date, count) {
            return `<div class="dhx_month_link" style="padding-right: unset;">+${count} more</div>`;
        };

        window.addEventListener('resize', () => {
            const isMobile = window.innerWidth < 630;
            scheduler.config.max_month_events = isMobile ? 5 : 5;
            scheduler.updateView();
        });
        const colors = [
            { key: 'purple', label: 'Purple' },
            { key: 'violet', label: 'Violet' },
            { key: 'green', label: 'Green' },
            { key: 'yellow', label: 'Yellow' },
            { key: 'blue', label: 'Blue' },
            { key: 'orange', label: 'Orange' }
        ];

        const selectmeExists = scheduler.config.lightbox.sections.some(section => section.name === "selectme");
        if (!selectmeExists) {
            scheduler.config.lightbox.sections.push({
                name: "selectme",
                height: 110,
                options: colors,
                map_to: "classname",
                type: "radio",
                vertical: false
            });
        }
        scheduler.clearAll();
        scheduler.parse(events);
        scheduler.locale.labels.section_selectme = "Event Color";
        scheduler.plugins({
            editors: true
        });
        startDate ? scheduler.init(schedulerContainer.current, new Date(startDate ? (new Date(startDate.year, startDate.month, startDate.date)) : ''), "month") : scheduler.init(schedulerContainer.current, new Date(), "month")

        const style = document.createElement('style');
        style.innerHTML = `
            .dhx_month_link {
                padding-right: unset !important;
            }
        `;
        document.head.appendChild(style);

        scheduler.createDataProcessor(function (entity, action, data, id) {
            switch (action) {
                case "create":
                    return scheduler.loading === false && postEmployeeCalendarEvent(data.text, data.start_date, data.end_date, null, data.classname);
                case "update":
                    return scheduler.loading === false && postEmployeeCalendarEvent(data.text, data.start_date, data.end_date, id, data.classname);
                case "delete":
                    return scheduler.loading === false && deleteEmployeeCalendarEvent(id);
                default:
                    break;
            }
        });
    }, []);

    useEffect(() => {
        schedulerInstance.current = window.scheduler;
        initScheduler();
    }, []);

    return (
        <div ref={schedulerContainer} style={{ width: '100%', height: '100%' }} />
    );
};

export default Scheduler;