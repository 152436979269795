import React, { useEffect, useRef, useState } from 'react';
import AppointmentSetter from '../reusable/AppointmentSetter/index.js';
import StaffAppointmentSetter from '../reusable/StaffAppointment/index.js';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import Toast from '../reusable/Toast';
import { format } from 'date-fns';
import styles from '../../assets/css/Utilities.module.css'
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { getTokenFromLocalStorage, fetchHospitals } from '../../helpers/index.js';
import MultipleAppointmentModal from './AppointmentSetter/MultipleAppointmentModal.js';

const AppointmentSetterMain = () => {
    const navigate = useNavigate();
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hospitalId, setHospitalId] = useState("1");
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');
    const [dbId, setDbId] = useState(null);
    const [event, setEvent] = useState(null);
    const [hospitals, setHospitals] = useState([]);
    const [showMultipleAppointmentModal, setShowMultipleAppointmentModal] = useState(false);
    const token = useRef(getTokenFromLocalStorage());

    window.scheduler.loading = false;

    useEffect(() => {
        fetchCalendarData();
        handleFetchHospitals();
        window.scheduler.hospitalId = hospitalId;
    }, [hospitalId]);

    const formatDate = (isoString) => {
        return format(new Date(isoString), 'yyyy-MM-dd HH:mm');
    }

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleEventAdded = (hId) => {
        fetchCalendarData(hId);
    };

    const handleFetchHospitals = async () => {
        setLoading(true);
        try {
            const data = await fetchHospitals();
            setHospitals(data);
        } catch (error) {
            console.error('Error fetching hospitals:', error);
        }
        finally {
            setLoading(false);
        }
    };

    const fetchCalendarData = async (hId) => {
        let HID = hId ? hId : hospitalId;
        setHospitalId(HID);
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/appointment/appointment-setter/${HID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.ok) {
                const data = await response.json();
                setCalendarEvents(data);
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleShowModal = (value, id, event) => {
        setDbId(id);
        setEvent(event);
        setShowModal(value);
    };

    const updateAppointmentSetter = async (text, start_date, end_date, id, classname, provider, phone, reasonforvisit, roomnumber, patientname, comments, scheduledby_id, staff_email, user_id, hospital, attachment_id, provider_email) => {
        scheduledby_id = Number(scheduledby_id);
        window.scheduler.loading = true;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ text, start_date, end_date, id, classname, provider, phone, reasonforvisit, roomnumber, patientname, comments, scheduledby_id, staff_email, user_id, hospital, provider_email })
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/appointment/appointment-setter`, requestOptions);
            const data = await response.json();
            if (response.ok) {
                window.scheduler.loading = false;
                window.scheduler.endLightbox(false, window.scheduler.getLightbox());
                setShowModal(false);
                handleEventAdded(window.scheduler.hospitalId);
                showSuccessToast('Appointment booked successfully');
            } else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            window.scheduler.loading = false;
        }
    };

    const handleCloseModal = (choice) => {
        if (choice === true) {
            updateAppointmentSetter(event.text, formatDate(event.start_date), formatDate(event.end_date), Number(dbId), 'red', event.provider, event.phone, event.reasonforvisit, event.roomnumber, event.patientname, event.comments, event.scheduledby_id, event.staff_email, Number(event.user_id), hospitals.find(h => h.id.toString() === hospitalId)?.hospital, event.attachment_id, event.email);
        }
        else {
            setShowModal(false);
        }
    };

    const cancelAppointment = async (choice, event = null) => {
        setShowCancelModal(choice);
        setEvent(event);
    }

    const handleCancelAppointment = async () => {
        let cancelEvent = event;
        cancelEvent.start_date = formatDate(event.start_date);
        cancelEvent.end_date = formatDate(event.end_date);
        const selectedHospital = hospitals.find(h => h.id.toString() === hospitalId);
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                event: cancelEvent,
                hospital: selectedHospital?.hospital
            })
        };
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + `/appointment/cancel_appointment/${event.id}`, requestOptions);
            const data = await response.json();
            if (response.ok) {
                window.scheduler.endLightbox(false, window.scheduler.getLightbox());
                setShowCancelModal(false);
                fetchCalendarData();
                showErrorToast('Appointment cancelled successfully');
            } else {
                console.log('error');
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            setShowCancelModal(false);
        }
    }

    const handleMultipleAppointments = async () => {
        setShowMultipleAppointmentModal(true);
    }

    return (
        <div class={styles.mainContainer}>
            {
                token.current !== null &&
                <div style={{ width: '100%', maxWidth: '1000px', display: 'flex', alignItems: 'center' }}>
                    <div className={styles.navigationContainer}>
                        <BackIcon onClick={() => navigate(-1)} className="back-button" />
                        <h3 className={styles.heading}>Utilities</h3>
                    </div>
                </div>
            }

            {showModal && <div className="modal">
                <div className="modern-modal">
                    <div className="modern-modal-content">
                        <h2 className="modern-modal-header">Confirm Appointment</h2>
                        <div style={{ fontSize: '1.3rem' }} className="modern-modal-body">
                            <p>Please review your appointment details:</p>
                            <p><strong>Location:</strong>{(hospitals.find(h => h.id.toString() === hospitalId))?.hospital}</p>
                            <p><strong>Date & Time:</strong> {event.start_date.toLocaleDateString() + ' ' + event.start_date.toLocaleTimeString('en-US', { hour12: false })} to {event.end_date.toLocaleDateString() + ' ' + event.end_date.toLocaleTimeString('en-US', { hour12: false })}</p>
                            <p><strong>Provider:</strong> {event.provider}</p>
                        </div>
                        <div className="modern-modal-footer">
                            <button className="modern-btn modern-btn-secondary" onClick={() => handleCloseModal(false)}>Cancel</button>
                            <button className="modern-btn modern-btn-primary" onClick={() => handleCloseModal(true)}>Confirm</button>
                        </div>
                    </div>
                </div>
            </div >}
            {showCancelModal && <div className="modal">
                <div className="modern-modal">
                    <div className="modern-modal-content">
                        <h2 className="modern-modal-header">Cancel Appointment ?</h2>
                        <div style={{ fontSize: '1.3rem' }} className="modern-modal-body">
                            <p>Are you sure you want to cancel this appointment?</p>

                        </div>
                        <div className="modern-modal-footer">
                            <button className="modern-btn modern-btn-primary" onClick={() => handleCancelAppointment()}>Yes</button>
                            <button className="modern-btn modern-btn-secondary" onClick={() => setShowCancelModal(false)}>No</button>
                        </div>
                    </div>
                </div>
            </div >}
            {showMultipleAppointmentModal && (
                <MultipleAppointmentModal
                    onClose={() => setShowMultipleAppointmentModal(false)}
                    onSubmit={() => {
                        setShowMultipleAppointmentModal(false);
                        fetchCalendarData(hospitalId);
                    }}
                />
            )}
            {token.current === null && <div style={{ marginTop: '2rem' }}></div>}
            {loading ? <Loader /> : <div className='scheduler-container staff-calendar'>
                <div className='select-container'>
                    <div className='left-div'><select style={{ maxWidth: '200px', height: '30px', visibility: 'hidden' }} value={hospitalId} onChange={(e) => { setHospitalId(e.target.value) }}>
                        {hospitals.map(hospital => (
                            <option key={hospital.id} value={hospital.id}>
                                {hospital.hospital}
                            </option>
                        ))}</select>
                    </div>

                    <div className='center-div'>{hospitals.find(h => h.id.toString() === hospitalId)?.hospital}</div>

                    <div className='right-div'>
                        <select
                            style={{ maxWidth: '200px', height: '30px' }}
                            value={hospitalId}
                            onChange={(e) => { setHospitalId(e.target.value) }}
                        >
                            {hospitals.map(hospital => (
                                <option key={hospital.id} value={hospital.id}>
                                    {hospital.hospital}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {
                    token.current === null && hospitalId !== undefined && loading === false ?
                        <StaffAppointmentSetter cancelAppointment={cancelAppointment} events={calendarEvents} hospitalName={hospitals.find(h => h.id.toString() === hospitalId)?.hospital} showModal={handleShowModal} /> :
                        <AppointmentSetter cancelAppointment={cancelAppointment} handleMultipleAppointments={handleMultipleAppointments} events={calendarEvents} hospitalName={hospitals.find(h => h.id.toString() === hospitalId)?.hospital} onEventAdded={handleEventAdded} />
                }
            </div>}
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
        </div>
    );
};
export default AppointmentSetterMain;