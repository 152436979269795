import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from 'date-fns';
import { convertToCalendarSpecificDate, ViewCalendarDate, isValidDate } from '../../helpers/index';

const CustomDatePicker = ({
    value,
    onChange,
    disabled,
    mode,
    subMode,
    placeholder,
    required,
    className,
    type = "date"
}) => {
    const years = Array.from({ length: getYear(new Date()) - 1980 }, (_, i) => 1981 + i);
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const handleDates = (date) => {
        if (!date) return '';
        if (type === "time") {
            if (date instanceof Date) {
                // Convert to HH:mm format when user changes time
                return date.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false
                });
            }
            // Return original format for initial load
            return date;
        }
        // Ensure consistent timezone handling
        const dateString = date instanceof Date
            ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
            : date;

        if (mode === 'view&edit') {
            return subMode === 'edit'
                ? convertToCalendarSpecificDate(dateString)
                : ViewCalendarDate(dateString);
        }
        if (mode === 'add') {
            return ViewCalendarDate(dateString);
        }
        return '';
    };

    const getSelectedValue = () => {
        if (!value) return null;

        if (type === "time") {
            if (value instanceof Date) {
                return value;
            }
            // Convert HH:mm string to Date object for time picker
            const [hours, minutes] = value.split(':');
            const date = new Date();
            date.setHours(hours, minutes, 0, 0);
            return date;
        }

        return value && isValidDate(value) ? new Date(handleDates(value)) : null;
    };

    return (
        <DatePicker
            renderCustomHeader={(props) => (
                <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
                    <button type="button" onClick={props.decreaseMonth} disabled={props.prevMonthButtonDisabled}>{"<"}</button>
                    <select
                        value={getYear(props.date)}
                        onChange={({ target: { value } }) => props.changeYear(value)}
                    >
                        {years.map((year) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                    <select
                        value={months[getMonth(props.date)]}
                        onChange={({ target: { value } }) => props.changeMonth(months.indexOf(value))}
                    >
                        {months.map((month) => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                    <button type="button" onClick={props.increaseMonth} disabled={props.nextMonthButtonDisabled}>{">"}</button>
                </div>
            )}
            selected={getSelectedValue()}
            onChange={(date) => onChange(handleDates(date))}
            disabled={disabled}
            placeholderText={placeholder}
            required={required}
            dateFormat={type === "time" ? "HH:mm" : "MM-dd-yyyy"}
            className={className}
            showTimeSelect={type === "time"}
            showTimeSelectOnly={type === "time"}
            timeFormat="HH:mm"
            timeIntervals={15}
        />
    );
};

export default CustomDatePicker;
