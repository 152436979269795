import React, { useState } from 'react';
import MacroMateMain from '../reusable/MacroMateMain.js';
import NoteForm from './NoteForm.js';
import styles from '../../assets/css/Note.module.css';

const Note = ({ patient, mode, currentPatientNote, redirectToNotelist }) => {
    const [macroMateText, setMacroMateText] = useState(currentPatientNote?.macro_mate_clinical_text || '');
    return (
        <div className={styles.noteContainer}>
            <div className={styles.macroMateMain}>
                <MacroMateMain macroMateText={macroMateText} setText={setMacroMateText} />
            </div>
            <div className={styles.noteForm}>
                <NoteForm
                    patient={patient}
                    mode={mode}
                    currentPatientNote={currentPatientNote}
                    macroMateText={macroMateText}
                    redirectToNotelist={redirectToNotelist}
                />
            </div>
        </div>
    );
};

export default Note;