import React, { useEffect, useState } from 'react'
import styles from '../../assets/css/ChargesTab.module.css'
import styles1 from '../../assets/css/Patient.module.css'
import { getTokenFromLocalStorage } from '../../helpers'
import PatientChargesHistoryTableRow from '../reusable/PatientChargesHistoryTableRow'
import t from '../../assets/css/Table.module.css'

const PatientChargesHistory = ({ patient }) => {

    const [chargesHistory, setChargesHistory] = useState([]);

    useEffect(() => {
        fetchPatientChargesHistory();
    }, []);

    const fetchPatientChargesHistory = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            }
        };

        const res = await fetch(`${process.env.REACT_APP_API_URL}/charges/patient-charges-history?patientId=${patient.patient_id}`, requestOptions);
        const data = await res.json();
        setChargesHistory(data);
    };

    return (
        <div className={styles.container}>
            <h4 style={{ margin: '1rem 0' }}>Patient Charges History</h4>
            <div className={t.container}>
                <div className={t.tableWrapper}>
                    <table className={`${t.compactTable2} ${t.mobileText}`}>
                        <thead id="tableHeader">
                            <tr>
                                <th scope="col">Date of Service</th>
                                <th scope="col">Admit Date</th>
                                <th scope="col">Visit Codes</th>
                                <th scope="col">Shared Visits</th>
                                <th scope="col">Submitter</th>
                                <th scope="col">Time Stamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chargesHistory.map((history, index) => (
                                <PatientChargesHistoryTableRow key={index} history={history} />
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className={t.totalCounter}>
                    Total Charges: {chargesHistory.length}
                </div>
            </div>
        </div>
    )
}

export default PatientChargesHistory
