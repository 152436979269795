import { getTokenFromLocalStorage } from '../index.js';

export const fetchDashboardStats = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/analytics/dashboard-stats`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + getTokenFromLocalStorage()
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch dashboard stats: ${response.status}`);
  }

  return response.json();
};