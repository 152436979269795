import React from 'react'
import styles from '../../assets/css/ChargesTab.module.css'
import { convertToCustomDate, isValidDate } from '../../helpers'

const PatientChargesHistoryTableRow = ({ history }) => {

    return (
        <>
            <tr>
                <td>{isValidDate(history.date_of_service) && convertToCustomDate(history.date_of_service)}</td>
                <td>{isValidDate(history.admitdate) && convertToCustomDate(history.admitdate)}</td>
                <td>{history.visit_codes && history.visit_codes.map(vc => vc.visit_code + ' ')}</td>
                <td>{history.shared_visits.length > 0 && history.shared_visits.map(sv => sv.name + ' ')}</td>
                <td>{history.name_of_user}</td>
                <td>{new Date(history.timestamp).toLocaleString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                })}</td>
            </tr>
        </>
    )
}

export default PatientChargesHistoryTableRow
