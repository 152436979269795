import React, { useState, useEffect } from 'react';
import ChargesTableRow from '../reusable/ChargesTableRow';
import styles from '../../assets/css/ChargesTab.module.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getTokenFromLocalStorage } from '../../helpers';
import t from '../../assets/css/Table.module.css'

function ChargesTable({ isAllSelected, setIsAllSelected, setSelectedPatients, patients, selectedPatients }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(patients);
  }, [patients]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const newRows = [...rows];
    const [movedRow] = newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, movedRow);

    setRows(newRows);

    try {
      await fetch(`${process.env.REACT_APP_API_URL}/charges/set-patient-order`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
        },
        body: JSON.stringify({
          patientOrder: newRows.map((patient, index) => ({
            patient_id: patient.patient_id,
            order_no: index,
          })),
        }),
      });
    } catch (error) {
      console.error("Error updating patient order:", error);
    }
  };
  const columnWidths = {
    drag: '5%',
    select: '5%',
    name: '15%',
    visitCode: '10%',
    sharedVisit: '10%',
    admitDate: '10%',
    location: '10%',
    visitType: '10%',
    diagnoses: '10%',
    status: '10%',
    facesheet: '5%'
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="patientsList" type="PATIENT">
        {(provided) => (
          <div style={{ padding: '0' }} className={t.container}>
            <div className={t.tableWrapper} {...provided.droppableProps} ref={provided.innerRef}>
              <table className={`${t.compactTable2} ${t.mobileText}`}>
                <thead id="tableHeader">
                  <tr>
                    <th scope="col" style={{ width: columnWidths.drag }}></th>
                    <th scope="col" style={{ width: columnWidths.select }}>
                      <input
                        checked={isAllSelected}
                        className={styles.checkbox}
                        type='checkbox'
                        style={{ height: '15px', width: '15px' }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setIsAllSelected(true);
                            setSelectedPatients(patients);
                          } else {
                            setSelectedPatients([]);
                            setIsAllSelected(false);
                          }
                        }}
                      />
                    </th>
                    <th scope="col" style={{ width: columnWidths.name }}>Name</th>
                    <th scope="col" style={{ width: columnWidths.visitCode }}>Visit Code</th>
                    <th scope="col" style={{ width: columnWidths.sharedVisit }}>Shared Visit</th>
                    <th scope="col" className={styles.HideMobile} style={{ width: columnWidths.admitDate }}>Admit Date</th>
                    <th scope="col" className={styles.HideMobile} style={{ width: columnWidths.location }}>Location</th>
                    <th scope="col" className={styles.HideMobile} style={{ width: columnWidths.visitType }}>Visit Type</th>
                    <th scope="col" style={{ width: columnWidths.diagnoses }}>Diagnoses</th>
                    <th scope="col" className={styles.HideMobile} style={{ width: columnWidths.status }}>Status</th>
                    <th scope="col" style={{ width: columnWidths.facesheet }}>Facesheet</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((patient, index) => (
                    <Draggable key={patient?.id} draggableId={patient?.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            background: snapshot.isDragging && "#dbe6f2",
                            border: snapshot.isDragging && "2px dashed #007bff",
                            ...provided.draggableProps.style,
                          }}
                        >
                          <ChargesTableRow
                            patient={patient}
                            isSelected={selectedPatients.some(item => item.id === patient.id)}
                            onSelect={(isSelected) => {
                              if (isSelected) {
                                setSelectedPatients([...selectedPatients, patient]);
                              } else {
                                setSelectedPatients(selectedPatients.filter(item => item.id !== patient.id));
                              }
                            }}
                          />
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </table>
            </div>
            <div className={t.totalCounter}>
              Total Patients {rows.length}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ChargesTable