import React, { useState } from 'react';
import ICDFavorites from '../reusable/ICDFavorites';
import styles from '../../assets/css/Utilities.module.css'
import { ReactComponent as MacroMateClinicalIcon } from '../../assets/icons/MacroMateClinical.svg';
import { ReactComponent as AppointmentSetterIcon } from '../../assets/icons/clock.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as ShortCodesIcon } from '../../assets/icons/pencil.svg';
import { ReactComponent as ConsultationIcon } from '../../assets/icons/Consultation.svg';
import { ReactComponent as RightArrowHalfIcon } from '../../assets/icons/right-arrow-half.svg';
import { ReactComponent as FeedbackIcon } from '../../assets/icons/new-feedback.svg';
import { ReactComponent as TrainingIcon } from '../../assets/icons/Training.svg';
import { ReactComponent as DirectoryIcon } from '../../assets/icons/directory.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import ICDShortCodes from '../reusable/ICDShortCodes';
import MacroMateClinical from '../reusable/MacroMateClinical';
import EmployeeCalendar from '../reusable/EmployeeCalendar';
import AppointmentSetter from '../reusable/AppointmentSetter';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import ConsultsTrackingTable from '../reusable/ConsultsTrackingTable';
import SuggestionsAndFeedback from '../reusable/SuggestionsAndFeedback';
import TrainingMaterials from '../reusable/TrainingMaterials';
import Directory from './Directory';

const Utilities = () => {
    const [activeTab, setActiveTab] = useState('');
    const navigate = useNavigate();

    return (
        <div className="UtilitiesContainer">
            <div className={`UtilitiesWhiteContainer whiteContainerWide`}>

                <div className={styles.utilitiesScrollContainer}>

                    {
                        (activeTab === 'employeeCalendar' && <EmployeeCalendar />) ||
                        (activeTab === 'MacroMateClinical' && <MacroMateClinical />) ||
                        (activeTab === 'consultsTracking' && <ConsultsTrackingTable />) ||
                        (activeTab === 'SuggestionsAndFeedback' && <SuggestionsAndFeedback />) ||
                        (activeTab === 'trainingMaterials' && <TrainingMaterials />) ||
                        (activeTab === 'directory' && <Directory />)
                    }

                    {activeTab === '' && <div className={activeTab !== '' ? [styles.active, styles.utilitiesContainer].join(' ') : styles.utilitiesContainer}>
                        <h2 className={styles.heading}>Utilities</h2>
                        <div className={styles.optionsContainer}>
                            {
                                activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/macromate-clinical')}>
                                    <div className={styles.options}>
                                        <MacroMateClinicalIcon stroke={'var(--purple)'} />
                                        <p>MacroMate Clinical</p>
                                        <span>Text expansion and macros for clinical notes</span>
                                    </div>
                                    <div className={styles.options}>
                                        <div className={styles.optionButton}>
                                            <span>Open</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/consults-tracking-table')}>
                                    <div className={styles.options}>
                                        <ConsultationIcon stroke={'var(--purple)'} />
                                        <p>Consult Tracker</p>
                                        <span>Monitor and manage patient consultations</span>
                                    </div>
                                    <div className={styles.options}>
                                        <div className={styles.optionButton}>
                                            <span>Open</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                activeTab === '' && <div className={styles.optionContainer} onClick={() => {
                                    sessionStorage.setItem('refreshScheduler', 'true');
                                    navigate('/calendar');
                                }}>
                                    <div className={styles.options}>
                                        <CalendarIcon fill={'var(--purple)'} />
                                        <p>Team Calendar</p>
                                        <span>View and manage team Schedule</span>
                                    </div>
                                    <div className={styles.options}>
                                        <div className={styles.optionButton}>
                                            <span>Open</span>
                                        </div>
                                    </div>
                                </div>
                            }


                            {
                                activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/training-materials')}>
                                    <div className={styles.options}>
                                        <TrainingIcon stroke={'var(--purple)'} />
                                        <p>Training Materials</p>
                                        <span>Access learning resources and documentation</span>
                                    </div>
                                    <div className={styles.options}>
                                        <div className={styles.optionButton}>
                                            <span>Open</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/directory')}>
                                    <div className={styles.options}>
                                        <DirectoryIcon width={20} height={20} stroke={'var(--purple)'} />
                                        <p>Directory</p>
                                        <span>Find contact details for team members</span>
                                    </div>
                                    <div className={styles.options}>
                                        <div className={styles.optionButton}>
                                            <span>Open</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/suggestions-and-feedback')}>
                                    <div className={styles.options}>
                                        <FeedbackIcon width={20} height={20} fill="var(--purple)" />
                                        <p>Suggestions and Feedback</p>
                                        <span>Provide input to improve the system</span>
                                    </div>
                                    <div className={styles.options}>
                                        <div className={styles.optionButton}>
                                            <span>Open</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                activeTab === '' && <div className={styles.optionContainer} onClick={() => navigate('/Dispo-Consult')}>
                                    <div className={styles.options}>
                                        <FeedbackIcon width={20} height={20} fill="var(--purple)" />
                                        <p>Dispo Consult</p>
                                        <span>Patient Discharge Planning Tool</span>
                                    </div>
                                    <div className={styles.options}>
                                        <div className={styles.optionButton}>
                                            <span>Open</span>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                        </div>

                    </div>}
                </div>


            </div>
        </div >
    );
};

export default Utilities;