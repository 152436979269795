import { toast } from 'react-toastify';
import { getTokenFromLocalStorage } from '../../../helpers';

export const isAdminUser = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/charges/is-admin-user`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data.isAdmin;
        } else {
            console.error('Error:', response.status);
            toast.error('Something went wrong');
            throw new Error('Something went wrong');
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('Something went wrong');
        throw new Error('Something went wrong', error);
    }
}

export const fetchUserAddedCharges = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/charges/all-submitted-charges`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error('Error:', response.status);
            toast.error('Something went wrong');
            throw new Error('Something went wrong');
        }
    } catch (error) {
        console.error('Error:', error);
        toast.error('Something went wrong');
        throw new Error('Something went wrong', error);
    }
}

export const shared_visits = (patient) => {
    return patient && patient.shared_visits && patient.shared_visits.length > 0 ?
        patient.shared_visits.map((visit, index) => (
            <div key={index}>{visit.name}&nbsp;
            </div>
        )) : '-'
};

export const submitCharges = async () => {

}