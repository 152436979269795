import React, { useState, useEffect } from "react";
import { getTokenFromLocalStorage } from '../../helpers/index.js';
import styles from '../../assets/css/DispoConsultForm.module.css';
import s1 from '../../assets/css/Charges.module.css';
import { toast } from "react-toastify";
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { useNavigate } from "react-router-dom";
import t from '../../assets/css/Table.module.css';

const options = {
  diagnosis: [
    "Amputation - LE", "Amputation - UE", "Burns", "Cardiac", "Congenital Deformity",
    "Hip/Knee Replacement", "MMT w/ BSCI", "MMT w/o BSCI", "Neurological Conditions",
    "Non-traumatic Brain Injury", "Non-traumatic Spinal Cord Injury",
    "Orthopedic - Hip/Femur Fracture", "Orthopedic - Major Multiple Fractures",
    "Orthopedic - Other", "Other Medically Complex/Debility", "Polyarticular Inflammatory Arthritis",
    "Pulmonary", "Severe/Advanced Osteoarthritis", "Stroke", "Systemic Vasculitis",
    "Traumatic Brain Injury", "Traumatic Spinal Cord Injury"
  ],
  insurancePayerType: ["Medicare", "Medicaid", "Managed Medicare", "Managed Medicaid", "Commercial", "Workers Comp", "Uninsured"],
  priorLevelOfFunction: ["Independent", "Mostly Independent, but Used a Device", "Needed Some Help, but Could Do Most Things Alone", "Needed a Lot of Help Every Day", "Completely Dependent on Others"],
  priorLivingArrangement: ["House/Home/Apartment", "Assisted Living Facility"],
  availableSupport: [
    "Full-Time Hands-On Help (24/7, lives with patient)", "Full-Time Hands-Off Help (24/7, lives with patient)",
    "Regular Hands-On Help (Daily, but not 24/7)", "Intermittent Hands-On Help (A few times per week)",
    "Intermittent Hands-Off Help (A few times per week, mostly supervision)", "Proximity Support (Family/friends nearby)",
    "Proximity Assist - check-in", "PT eval states no home assistance (home equip rw, manual wheelchair, cane, stair glide inside)",
    "No Support"
  ],
  functionalLevelTransfers: ["Independent", "Minimal Assistance", "Moderate Assistance", "Maximal Assistance", "Contact Guard Assist", "Supervision", "Dependent"],
  functionalLevelAmbulation: ["Independent", "Minimal Assistance", "Moderate Assistance", "Maximal Assistance", "Contact Guard Assist", "Supervision", "Standing poor, unable to sustain", "Dependent"],
  otNeeds: ["True", "False"],
  toleratesTherapiesStanding: ["True", "False"],
  providerRecommendedDisposition: ["Home with Home Health/Outpatient", "IRF", "SNF"]
};

const DispoConsult = () => {
  const navigate = useNavigate();
  const [diagnosis, setDiagnosis] = useState([]);
  const [insurancePayerType, setInsurancePayerType] = useState("");
  const [priorLevelOfFunction, setPriorLevelOfFunction] = useState("");
  const [priorLivingArrangement, setPriorLivingArrangement] = useState("");
  const [availableSupport, setAvailableSupport] = useState("");
  const [functionalLevelTransfers, setFunctionalLevelTransfers] = useState("");
  const [functionalLevelAmbulation, setFunctionalLevelAmbulation] = useState("");
  const [otNeeds, setOtNeeds] = useState("");
  const [toleratesTherapiesStanding, setToleratesTherapiesStanding] = useState("");
  const [providerRecommendedDisposition, setProviderRecommendedDisposition] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('Dispo Consult');
  const [executionLogs, setExecutionLogs] = useState([]);
  const [loadingLogs, setLoadingLogs] = useState(false);

  const handleSubmit = async () => {
    // check conditions to check empty fields and show error toast 
    if (!diagnosis || !insurancePayerType || !priorLevelOfFunction || !priorLivingArrangement || !availableSupport || !functionalLevelTransfers || !functionalLevelAmbulation || !otNeeds || !toleratesTherapiesStanding || !providerRecommendedDisposition) {
      toast.error('Please fill all the fields');
      return;
    }
    const formData = {
      diagnosis: diagnosis.trim(),
      insurancePayerType: insurancePayerType.trim(),
      priorLevelOfFunction: priorLevelOfFunction.trim(),
      priorLivingArrangement: priorLivingArrangement.trim(),
      availableSupport: availableSupport.trim(),
      functionalLevelTransfers: functionalLevelTransfers.trim(),
      functionalLevelAmbulation: functionalLevelAmbulation.trim(),
      OTNeeds: otNeeds.trim(),
      toleratesTherapiesStanding: toleratesTherapiesStanding.trim(),
    };
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/dispo-consult`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }

      const data = await response.json();
      toast.success('Form submitted successfully');
      setResult(data);
      await saveExecutionLog(data.confidence, data.disposition, formData);
      return data;
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.message || 'Failed to submit form');
    }
    finally {
      setLoading(false);
    }
  };

  const saveExecutionLog = async (confidence, disposition, formData) => {
    try {
      const executionLogData = {
        ...formData,
        providerRecommendedDisposition: providerRecommendedDisposition.trim(),
        confidence: confidence,
        disposition: disposition
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/dispo-consult/execution-log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify(executionLogData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error('Failed to save execution log');
        throw new Error(errorData.message || 'Failed to submit form');
      }

      if (response.ok) {
        const log_result = await response.json();
        if (log_result.success) {
          toast.success('Execution Log Saved!');
        }
      }

    } catch (error) {
      console.error('Error submitting form:', error);
    }
    finally {
      setLoading(false);
    }
  }

  const fetchExecutionLogs = async () => {
    try {
      setLoadingLogs(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/dispo-consult/execution-log`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getTokenFromLocalStorage()
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch execution logs');
      }

      const result = await response.json();
      setExecutionLogs(result.data);
    } catch (error) {
      console.error('Error fetching execution logs:', error);
      toast.error('Failed to fetch execution logs');
    } finally {
      setLoadingLogs(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'Execution Log') {
      fetchExecutionLogs();
    }
  }, [activeTab]);

  return (
    <div className="UtilitiesContainer">
      <div className='NavigationContainer'>
        <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 0, borderRadius: '0 0 30px 30px' }} className={['UtilitiesMainContainer whiteContainerWide'].join(' ')}>

        <div className={s1.tabsContainer}>
          <div id={s1.ChargesHistory} onClick={() => setActiveTab('Dispo Consult')} className={`${s1.tab} ${activeTab === 'Dispo Consult' ? s1.activeTab : ''}`}>Dispo Consult</div>
          <div id={s1.ChargesHistory} onClick={() => setActiveTab('Execution Log')} className={`${s1.tab} ${activeTab === 'Execution Log' ? s1.activeTab : ''}`}>Execution Log</div>
        </div>

        {
          activeTab === 'Dispo Consult' && <div className={styles.formContainer}>
            <h2>Dispo Consult Form</h2>

            <div className={styles.columnContainer}>
              <div className={styles.inputWrapper}>
                <label>Diagnosis <span className={styles.required}>*</span></label>
                <select value={diagnosis} onChange={(e) => setDiagnosis(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.diagnosis.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>Insurance Payer Type <span className={styles.required}>*</span></label>
                <select value={insurancePayerType} onChange={(e) => setInsurancePayerType(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.insurancePayerType.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>Prior Level of Function <span className={styles.required}>*</span></label>
                <select value={priorLevelOfFunction} onChange={(e) => setPriorLevelOfFunction(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.priorLevelOfFunction.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>Prior Living Arrangement <span className={styles.required}>*</span></label>
                <select value={priorLivingArrangement} onChange={(e) => setPriorLivingArrangement(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.priorLivingArrangement.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>Available Support <span className={styles.required}>*</span></label>
                <select value={availableSupport} onChange={(e) => setAvailableSupport(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.availableSupport.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>Functional Level - Transfers <span className={styles.required}>*</span></label>
                <select value={functionalLevelTransfers} onChange={(e) => setFunctionalLevelTransfers(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.functionalLevelTransfers.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>Functional Level - Ambulation <span className={styles.required}>*</span></label>
                <select value={functionalLevelAmbulation} onChange={(e) => setFunctionalLevelAmbulation(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.functionalLevelAmbulation.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>OT Needs <span className={styles.required}>*</span></label>
                <select value={otNeeds} onChange={(e) => setOtNeeds(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.otNeeds.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>Tolerates Therapies Standing <span className={styles.required}>*</span></label>
                <select value={toleratesTherapiesStanding} onChange={(e) => setToleratesTherapiesStanding(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.toleratesTherapiesStanding.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              <div className={styles.inputWrapper}>
                <label>Provider's Recommended Disposition <span className={styles.required}>*</span></label>
                <select value={providerRecommendedDisposition} onChange={(e) => setProviderRecommendedDisposition(e.target.value)} className={styles.selectInputField}>
                  <option value="">Select</option>
                  {options.providerRecommendedDisposition.map((item) => <option key={item} value={item}>{item}</option>)}
                </select>
              </div>

              {result && <div className={styles.resultContainer}>
                <p>Result: {result.disposition}</p>
                <p>Confidence: {result.confidence}</p>
              </div>}
              {loading ? <div>Loading...</div> : <button className={styles.rectangleBtn} onClick={handleSubmit}>Submit</button>}
              <div className="pb1"></div>
            </div>

          </div>
        }

        {
          activeTab === 'Execution Log' &&
          <div className={t.container}>
            <div style={{ paddingTop: '20px' }} className={t.tableWrapper}>
              <table className={`${t.compactTable2} ${t.mobileText}`}>
                <thead id="tableHeader">
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Diagnosis</th>
                    <th scope="col">Insurance Payer Type</th>
                    <th scope="col">Prior Level Of Function</th>
                    <th scope="col">Prior Living Arrangement</th>
                    <th scope="col">Available Support</th>
                    <th scope="col">Functional Level Transfers</th>
                    <th scope="col">Functional Level Ambulation</th>
                    <th scope="col">OT Needs</th>
                    <th scope="col">Tolerates Therapies Standing</th>
                    <th scope="col">Provider Recommended Disposition</th>
                    <th scope="col">Disposition</th>
                    <th scope="col">Confidence</th>
                    <th scope="col">Provider</th>
                    <th scope="col">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {executionLogs.map((log, index) => (
                    <tr key={index}>
                      <td>{log.id}</td>
                      <td>{log.diagnosis}</td>
                      <td>{log.insurance_payer_type}</td>
                      <td>{log.prior_level_of_function}</td>
                      <td>{log.prior_living_arrangement}</td>
                      <td>{log.available_support}</td>
                      <td>{log.functional_level_transfers}</td>
                      <td>{log.functional_level_ambulation}</td>
                      <td>{log.ot_needs}</td>
                      <td>{log.tolerates_therapies_standing}</td>
                      <td>{log.provider_recommended_disposition}</td>
                      <td>{log.disposition}</td>
                      <td>{log.confidence}</td>
                      <td>{log.provider}</td>
                      <td>{new Date(log.created_at).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={t.totalCounter}>
              Total Logs: {executionLogs.length}
            </div>
          </div>
        }
      </div>
    </div>
  );
};


export default DispoConsult