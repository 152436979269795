import React, { useEffect, useState } from 'react';
import ChargesTab from '../single/ChargesTab';
import styles from '../../assets/css/Charges.module.css';
import { useNavigate } from 'react-router-dom';
import SubmittedChargesHistory from '../single/SubmittedChargesHistory';
import '../../App.css';
import ChargeReview from '../single/ChargeReview';
import { isAdminUser } from '../../helpers/Charges/charges-review/index.js';

const Charges = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Charges');
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }
        isAdminUser().then((result) => {
            setIsAdmin(result);
        });
    }, [navigate]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    return (
        <div className={styles.mainContainer}>
            <div className={`${styles.whiteContainer} whiteContainerWide`}>
                <div className={styles.tabsContainer}>
                    <div id={styles.ChargesHistory} onClick={() => handleTabChange('Charges')} className={`${styles.tab} ${activeTab === 'Charges' ? styles.activeTab : ''}`}>Charges</div>
                    <div id={styles.ChargesHistory} onClick={() => handleTabChange('Charges History')} className={`${styles.tab} ${activeTab === 'Charges History' ? styles.activeTab : ''}`}>Charges History</div>
                    {isAdmin && <div id={styles.ChargesHistory} onClick={() => handleTabChange('Charge Review')} className={`${styles.tab} ${activeTab === 'Charge Review' ? styles.activeTab : ''}`}>Charge Review</div>}
                </div>
                {activeTab === 'Charges' && <ChargesTab />}
                {activeTab === 'Charges History' && <SubmittedChargesHistory />}
                {isAdmin && activeTab === 'Charge Review' && <ChargeReview />}
            </div>
            <div className='mb-6'></div>
        </div>
    );
};

export default Charges;
