import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTokenFromLocalStorage, getTitlePrefix, fetchHospitals, fetchAuthorizedProviders } from '../../helpers/index';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import styles from '../../assets/css/ConsultsTrackingTable.module.css'
import '../../App.css';
import TableRowForConsultsTracking from './TableRowForConsultsTracking';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import t from '../../assets/css/Table.module.css'

const ConsultsTrackingTable = () => {
    const getInitialColumns = () => {
        const savedColumns = localStorage.getItem('consultTableColumns');
        const defaultColumns = {
            name: true,
            facilityName: true,
            roomNumber: true,
            dob: true,
            dateRequested: true,
            timeRequested: true,
            visitDate: true,
            followupDate: true,
            assignedProvider: true,
            insuranceCarrier: true,
            rehabDiagnosis: true,
            rehabRecs: true,
            notes: true,
            status: true
        };

        if (!savedColumns) return defaultColumns;

        const parsedColumns = JSON.parse(savedColumns);
        const updatedColumns = { ...defaultColumns, ...parsedColumns };

        if (Object.keys(updatedColumns).length !== Object.keys(parsedColumns).length) {
            localStorage.setItem('consultTableColumns', JSON.stringify(updatedColumns));
        }

        return updatedColumns;
    };
    const navigate = useNavigate();
    const [consults, setConsults] = useState([]);
    const [originalConsults, setOriginalConsults] = useState([]);
    const [sortOrder, setSortOrder] = useState(() => {
        const savedSort = localStorage.getItem('consultTableSort');
        return savedSort ? JSON.parse(savedSort) : { column: null, order: null };
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [showColumnSelector, setShowColumnSelector] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState(getInitialColumns);

    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem('consultTableFilters');
        return savedFilters ? JSON.parse(savedFilters) : {
            facilityName: [],
            assignedProvider: [],
            status: 'open'
        };
    });
    const [hospitals, setHospitals] = useState([]);
    const [authorizedProviders, setAuthorizedProviders] = useState([]);
    const [showFacilityDropdown, setShowFacilityDropdown] = useState(false);
    const [showProviderDropdown, setShowProviderDropdown] = useState(false);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);

    useEffect(() => {
        const loadInitialData = async () => {
            const hospitalsData = await fetchHospitals();
            const providersData = await fetchAuthorizedProviders();

            setHospitals(hospitalsData);
            setAuthorizedProviders(providersData);
        };

        loadInitialData();
    }, []);

    const handleFilterChange = (field, value) => {
        const newFilters = { ...filters };

        if (field === 'facilityName' || field === 'assignedProvider') {
            if (newFilters[field].includes(value)) {
                newFilters[field] = newFilters[field].filter(item => item !== value);
            } else {
                newFilters[field] = [...newFilters[field], value];
            }
        } else {
            newFilters[field] = value;
            setShowStatusDropdown(false);
        }

        setFilters(newFilters);

        const filteredResults = originalConsults?.filter(consult => {
            const matchesFacility = newFilters.facilityName.length === 0 ||
                newFilters.facilityName.includes(consult.hospital_id);
            const matchesProvider = newFilters.assignedProvider.length === 0 ||
                newFilters.assignedProvider.some(selectedProvider =>
                    consult.owning_provider_name === selectedProvider
                );
            const matchesStatus = !newFilters.status ||
                consult.status?.toLowerCase() === newFilters.status.toLowerCase();
            const matchesSearch = !searchQuery ||
                `${consult.firstname} ${consult.middlename} ${consult.lastname}`.toLowerCase().includes(searchQuery.toLowerCase());

            return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
        });

        setConsults(filteredResults);
    };


    const handleFilterClick = (filterType) => {
        if (filterType === 'facility') {
            setShowProviderDropdown(false);
            setShowStatusDropdown(false);
            setShowFacilityDropdown(!showFacilityDropdown);
        } else if (filterType === 'provider') {
            setShowFacilityDropdown(false);
            setShowStatusDropdown(false);
            setShowProviderDropdown(!showProviderDropdown);
        } else if (filterType === 'status') {
            setShowFacilityDropdown(false);
            setShowProviderDropdown(false);
            setShowStatusDropdown(!showStatusDropdown);
        }
    };


    useEffect(() => {
        localStorage.setItem('consultTableColumns', JSON.stringify(visibleColumns));
    }, [visibleColumns]);

    const ColumnSelector = ({ visibleColumns, setVisibleColumns, onClose }) => {
        const [tempColumns, setTempColumns] = useState(visibleColumns);

        const handleAllToggle = () => {
            const allSelected = Object.values(tempColumns).every(v => v);
            setTempColumns(Object.keys(tempColumns).reduce((acc, key) => ({
                ...acc,
                [key]: !allSelected
            }), {}));
        };

        const handleColumnToggle = (key) => {
            setTempColumns(prev => ({
                ...prev,
                [key]: !prev[key]
            }));
        };

        const handleDone = () => {
            if (Object.values(tempColumns).every(v => !v)) {
                alert('Please select at least one column');
                return;
            }
            setVisibleColumns(tempColumns);
            setShowColumnSelector(false);
        };

        return (
            <div className={[styles.mobileColumnSelector].join(' ')}>
                <div className={styles.selectorHeader}>
                    <h4>Select Visible Columns</h4>
                    <button onClick={handleDone}>Done</button>
                </div>
                <div className={styles.columnOptions}>
                    <div className={styles.columnOption}>
                        <label>
                            <input
                                type="checkbox"
                                checked={Object.values(tempColumns).every(v => v)}
                                onChange={handleAllToggle}
                            />
                            All
                        </label>
                    </div>
                    {Object.entries(tempColumns)?.map(([key, value]) => (
                        <div key={key} className={styles.columnOption}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={value}
                                    onChange={() => handleColumnToggle(key)}
                                />
                                {key.replace(/([A-Z])/g, ' $1')
                                    .trim()
                                    .toLowerCase()
                                    .split(' ')
                                    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (!isEditMode) {
            fetchConsultsList().then((p) => {
                setOriginalConsults(p);
                const filteredResults = p?.filter(consult => {
                    const fullName = `${consult.firstname || ''} ${consult.middlename || ''} ${consult.lastname || ''}`.toLowerCase();

                    const matchesFacility = filters.facilityName.length === 0 ||
                        filters.facilityName.includes(consult.hospital_id);

                    const matchesProvider = filters.assignedProvider.length === 0 ||
                        filters.assignedProvider.some(provider =>
                            consult.owning_provider_name?.toLowerCase().includes(provider.toLowerCase())
                        );

                    const matchesStatus = !filters.status ||
                        consult.status?.toLowerCase() === filters.status.toLowerCase();

                    const matchesSearch = !searchQuery ||
                        fullName.includes(searchQuery.toLowerCase());

                    return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
                });
                setConsults(filteredResults);
            });
        }
    }, [isEditMode]);

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }

        fetchConsultsList().then((p) => {
            setOriginalConsults(p);
            const filteredResults = p?.filter(consult => {
                const fullName = `${consult.firstname || ''} ${consult.middlename || ''} ${consult.lastname || ''}`.toLowerCase();

                const matchesFacility = filters.facilityName.length === 0 ||
                    filters.facilityName.includes(consult.hospital_id);
                const matchesProvider = filters.assignedProvider.length === 0 ||
                    filters.assignedProvider.some(provider =>
                        consult.owning_provider_name === provider
                    );
                const matchesStatus = !filters.status ||
                    consult.status?.toLowerCase() === filters.status.toLowerCase();
                const matchesSearch = !searchQuery ||
                    fullName.includes(searchQuery.toLowerCase());

                return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
            });
            setConsults(filteredResults);
        });
    }, [navigate]);

    const fetchConsultsList = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/consults/consults-list`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });

            if (response.ok) {
                const data = await response.json();
                setOriginalConsults(data);

                // Apply saved filters
                let filteredData = data;
                if (filters && (filters.facilityName?.length || filters.assignedProvider?.length || filters.status)) {
                    filteredData = data.filter(consult => {
                        const matchesFacility = !filters.facilityName?.length ||
                            filters.facilityName.includes(consult.hospital_id);
                        const matchesProvider = !filters.assignedProvider?.length ||
                            filters.assignedProvider.some(provider =>
                                consult.owning_provider_name === provider
                            );
                        const matchesStatus = !filters.status ||
                            consult.status?.toLowerCase() === filters.status.toLowerCase();

                        return matchesFacility && matchesProvider && matchesStatus;
                    });
                }

                // Apply saved sort with specific handling for status column
                if (sortOrder.column && sortOrder.order) {
                    const sortedData = [...filteredData].sort((a, b) => {
                        if (!a[sortOrder.column]) return 1;
                        if (!b[sortOrder.column]) return -1;

                        if (sortOrder.column === 'status') {
                            const statusA = a.status?.toLowerCase() || '';
                            const statusB = b.status?.toLowerCase() || '';
                            return sortOrder.order === 'asc'
                                ? statusA.localeCompare(statusB)
                                : statusB.localeCompare(statusA);
                        }

                        const valueA = a[sortOrder.column].toString().toLowerCase();
                        const valueB = b[sortOrder.column].toString().toLowerCase();

                        return sortOrder.order === 'asc'
                            ? valueA.localeCompare(valueB)
                            : valueB.localeCompare(valueA);
                    });
                    setConsults(sortedData);
                } else {
                    setConsults(filteredData);
                }

                return data;
            }
        } catch (error) {
            console.error('Error fetching consults:', error);
        }
    };

    useEffect(() => {
        localStorage.setItem('consultTableSort', JSON.stringify(sortOrder));
    }, [sortOrder]);

    useEffect(() => {
        localStorage.setItem('consultTableFilters', JSON.stringify(filters));
    }, [filters]);

    const handleSort = (column) => {
        let order = 'asc';
        if (sortOrder.column === column && sortOrder.order === 'asc') {
            order = 'desc';
        }

        setSortOrder({ column, order });

        const sortedConsults = [...consults].sort((a, b) => {
            if (column === 'hospitalfacilityname') {
                const facilityA = (a.hospital_abbreviation || '').toLowerCase();
                const facilityB = (b.hospital_abbreviation || '').toLowerCase();

                if (facilityA === facilityB) {
                    const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
                    const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                }

                return order === 'asc'
                    ? facilityA.localeCompare(facilityB)
                    : facilityB.localeCompare(facilityA);
            }

            if (column === 'assignedprovider') {
                const providerA = (a.owning_provider_name || '').toLowerCase();
                const providerB = (b.owning_provider_name || '').toLowerCase();

                if (providerA < providerB) return order === 'asc' ? -1 : 1;
                if (providerA > providerB) return order === 'asc' ? 1 : -1;
                return 0;
            }

            if (column === 'roomnumber') {
                const parseRoom = (room) => {
                    if (!room) return '';
                    const parts = room.match(/([A-Za-z]+|\d+|[.-])/g) || [];
                    return parts?.map(part =>
                        isNaN(part) ? part : part.padStart(5, '0')
                    ).join('');
                };

                const roomA = parseRoom(a[column]);
                const roomB = parseRoom(b[column]);

                if (roomA < roomB) return order === 'asc' ? -1 : 1;
                if (roomA > roomB) return order === 'asc' ? 1 : -1;
                return 0;
            }

            if (['daterequested', 'visitdate', 'followupdate'].includes(column)) {
                const dateA = a[column] ? new Date(a[column]) : null;
                const dateB = b[column] ? new Date(b[column]) : null;

                if (!dateA && !dateB) return 0;
                if (!dateA) return 1;
                if (!dateB) return -1;

                return order === 'asc' ? dateA - dateB : dateB - dateA;
            }

            if (column === 'timerequested') {
                if (!a[column] && !b[column]) return 0;
                if (!a[column]) return 1;
                if (!b[column]) return -1;

                const timeA = a[column].split(':').reduce((acc, time) => (60 * acc) + +time, 0);
                const timeB = b[column].split(':').reduce((acc, time) => (60 * acc) + +time, 0);
                return order === 'asc' ? timeA - timeB : timeB - timeA;
            }

            if (a[column] === null || a[column] === undefined) return 1;
            if (b[column] === null || b[column] === undefined) return -1;

            const valueA = a[column].toString().toLowerCase();
            const valueB = b[column].toString().toLowerCase();

            if (valueA < valueB) return order === 'asc' ? -1 : 1;
            if (valueA > valueB) return order === 'asc' ? 1 : -1;

            const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
            const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0;
        });

        setConsults(sortedConsults);
    };

    const filterConsults = (searchTerm) => {
        const filteredConsults = originalConsults?.filter(consult => {
            const fullName = `${consult.firstname || ''} ${consult.middlename || ''} ${consult.lastname || ''}`.toLowerCase();

            const matchesFacility = filters.facilityName.length === 0 ||
                filters.facilityName.includes(consult.hospital_id);

            const matchesProvider = filters.assignedProvider.length === 0 ||
                filters.assignedProvider.some(provider =>
                    consult.owning_provider_name === provider
                );

            const matchesStatus = !filters.status ||
                consult.status?.toLowerCase() === filters.status.toLowerCase();

            const matchesSearch = !searchTerm ||
                fullName.includes(searchTerm.toLowerCase());

            return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
        });

        setConsults(filteredConsults);
    };

    //create a function to handle the search input
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
        filterConsults(searchTerm);
    };

    const AddConsult = () => {
        navigate('/consult-details', {
            state: {
                consults: null,
                mode: 'add',
            }
        });
    }

    const handleClearFilters = () => {
        const defaultFilters = {
            facilityName: [],
            assignedProvider: [],
            status: 'open'
        };
        setFilters(defaultFilters);

        const openConsults = originalConsults?.filter(consult =>
            consult.status?.toLowerCase() === 'open'
        );
        setConsults(openConsults);

        localStorage.setItem('consultTableFilters', JSON.stringify(defaultFilters));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const multiSelectContainer = event.target.closest(`.${CSS.escape(styles.multiSelect)}`);
            const isOptionClick = event.target.closest(`.${CSS.escape(styles.optionLabel)}`);
            if (isOptionClick) {
                return;
            }
            if (!multiSelectContainer) {
                setShowFacilityDropdown(false);
                setShowProviderDropdown(false);
                setShowStatusDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div style={{ paddingBottom: 0, marginLeft: 0 }} className="UtilitiesContainer">
            <div style={{ maxWidth: '2250px' }} className='NavigationContainer'>
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
            </div>
            <div style={{ overflowY: 'hidden', color: 'black', padding: 0, maxWidth: '2250px' }} className="UtilitiesMainContainer whiteContainerWide">
                <div className="marginContainer paddingBottomContainer">
                    <div className={[styles.topContainer1].join(' ')}>
                        <div className={styles.toggleContainer}>
                            <span>Edit Mode</span>
                            <div className="toggle" onClick={() => setIsEditMode(!isEditMode)}>
                                <div className={`toggle-slider ${isEditMode ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>

                    {showColumnSelector && (
                        <>
                            <div className={styles.overlay} onClick={() => setShowColumnSelector(false)} />
                            <ColumnSelector
                                visibleColumns={visibleColumns}
                                setVisibleColumns={setVisibleColumns}
                                onClose={() => setShowColumnSelector(false)}
                            />
                        </>
                    )}
                    <div className={[styles.topContainer, styles.HideMobile].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All Consults</h3>
                        </div>
                        <div className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" value={searchQuery} placeholder="Name search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                        <div onClick={() => AddConsult()} className={[styles.button, styles.AddConsultsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Consult
                        </div>
                        <button
                            className={[styles.columnSelectorBtn].join(' ')}
                            onClick={() => setShowColumnSelector(true)}
                        >
                            Select Columns
                        </button>
                    </div>

                    <div className={[styles.topContainer, styles.HideOnDesktop].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All Consults</h3>
                        </div>
                        <div onClick={() => AddConsult()} className={[styles.button, styles.AddConsultsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Consult
                        </div>
                        <div style={{ width: '77%', marginTop: '1rem' }} className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" placeholder="Name search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                        <button
                            className={[styles.columnSelectorBtn].join(' ')}
                            onClick={() => setShowColumnSelector(true)}
                        >
                            Select Columns
                        </button>
                    </div>
                    <div className={styles.filterBar}>
                        <FilterIcon className={styles.filterIcon} />
                        <div className={styles.filterField}>
                            <div className={styles.multiSelect}>
                                <div className={styles.selectHeader} onClick={() => handleFilterClick('facility')}>
                                    {filters.facilityName.length ? `${filters.facilityName.length} Selected` : 'All Facilities'}
                                </div>
                                {showFacilityDropdown && (
                                    <div className={styles.optionsContainer}>
                                        {hospitals.map(hospital => (
                                            <label key={hospital.id} className={styles.optionLabel}>
                                                <input
                                                    type="checkbox"
                                                    checked={filters.facilityName.includes(hospital.id)}
                                                    onChange={() => handleFilterChange('facilityName', hospital.id)}
                                                />
                                                {hospital.hospital} ({hospital.abbreviation})
                                            </label>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.filterField}>
                            <div className={styles.multiSelect}>
                                <div className={styles.selectHeader} onClick={() => handleFilterClick('provider')}>
                                    {filters.assignedProvider.length ? `${filters.assignedProvider.length} Selected` : 'All Providers'}
                                </div>
                                {showProviderDropdown && (
                                    <div className={styles.optionsContainer}>
                                        {authorizedProviders
                                            .sort((a, b) => a.lastname.localeCompare(b.lastname))
                                            .map(provider => {
                                                const prefix = getTitlePrefix(provider.title);
                                                const providerName = `${prefix} ${provider.firstname} ${provider.lastname}`;
                                                return (
                                                    <label key={provider.id} className={styles.optionLabel}>
                                                        <input
                                                            type="checkbox"
                                                            checked={filters.assignedProvider.includes(providerName)}
                                                            onChange={() => handleFilterChange('assignedProvider', providerName)}
                                                        />
                                                        {providerName}
                                                    </label>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.filterField}>
                            <div className={styles.multiSelect}>
                                <div className={styles.selectHeader} onClick={() => handleFilterClick('status')}>
                                    {filters.status ? filters.status.charAt(0).toUpperCase() + filters.status.slice(1) : 'All Status'}
                                </div>
                                {showStatusDropdown && (
                                    <div className={styles.optionsContainer}>
                                        <label className={styles.optionLabel} onClick={() => handleFilterChange('status', '')}>
                                            All Status
                                        </label>
                                        <label className={styles.optionLabel} onClick={() => handleFilterChange('status', 'open')}>
                                            Open
                                        </label>
                                        <label className={styles.optionLabel} onClick={() => handleFilterChange('status', 'resolved')}>
                                            Resolved
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        <button
                            onClick={handleClearFilters}
                            className={styles.clearFiltersBtn}
                        >
                            Clear Filters
                        </button>
                    </div>

                    <div className={t.container}>
                        <div className={t.tableWrapper}>
                            <table className={`${t.compactTable2} ${t.mobileText}`}>
                                <thead id="tableHeader">
                                    <tr>
                                        <th scope="col"></th>
                                        {visibleColumns.name && (
                                            <th scope="col" onClick={() => handleSort('lastname')}>
                                                Name {sortOrder.column === 'lastname' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.facilityName && (
                                            <th scope="col" onClick={() => handleSort('hospitalfacilityname')}>
                                                Facility {sortOrder.column === 'hospitalfacilityname' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.roomNumber && (
                                            <th scope="col" onClick={() => handleSort('roomnumber')}>
                                                Room {sortOrder.column === 'roomnumber' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.dob && (
                                            <th scope="col">DOB</th>
                                        )}
                                        {visibleColumns.dateRequested && (
                                            <th scope="col" onClick={() => handleSort('daterequested')}>
                                                Date Req. {sortOrder.column === 'daterequested' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.timeRequested && (
                                            <th scope="col" onClick={() => handleSort('timerequested')}>
                                                Time Req. {sortOrder.column === 'timerequested' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.visitDate && (
                                            <th scope="col" onClick={() => handleSort('visitdate')}>
                                                Visit Date {sortOrder.column === 'visitdate' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.followupDate && (
                                            <th scope="col" onClick={() => handleSort('followupdate')}>
                                                F/U Date {sortOrder.column === 'followupdate' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.assignedProvider && (
                                            <th scope="col" onClick={() => handleSort('assignedprovider')}>
                                                Provider {sortOrder.column === 'assignedprovider' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.insuranceCarrier && (
                                            <th scope="col" onClick={() => handleSort('insurancecarrier')}>
                                                Insurance {sortOrder.column === 'insurancecarrier' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.rehabDiagnosis && (
                                            <th scope="col" onClick={() => handleSort('rehabdiagnosis')}>
                                                Rehab Dx {sortOrder.column === 'rehabdiagnosis' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.rehabRecs && (
                                            <th scope="col" onClick={() => handleSort('rehabrecs')}>
                                                Rec {sortOrder.column === 'rehabrecs' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.notes && (
                                            <th scope="col" onClick={() => handleSort('notes')}>
                                                Notes {sortOrder.column === 'notes' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                        {visibleColumns.status && (
                                            <th scope="col" onClick={() => handleSort('status')}>
                                                Status {sortOrder.column === 'status' ? (sortOrder.order === 'asc' ? '▲' : '▼') : '▼'}
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {consults?.length > 0 && consults?.map((consults, index) => (
                                        <TableRowForConsultsTracking
                                            key={index}
                                            consults={consults}
                                            isEditMode={isEditMode}
                                            setConsults={setConsults}
                                            visibleColumns={visibleColumns}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={t.totalCounter}>
                            Total Patients: {consults?.length}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsultsTrackingTable;
