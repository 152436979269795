import DOMPurify from 'dompurify';
import { format, isValid, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { toast } from 'react-toastify';

export const isValidJWTToken = (token) => {
    const tokenParts = token.split('.');
    return tokenParts.length === 3;
}

export const sanitizeToken = (token) => {
    return DOMPurify.sanitize(token);
}

export const encodeToken = (token) => {
    return btoa(token);
}

export const getTokenFromLocalStorage = () => {
    const encodedToken = localStorage.getItem('token');
    if (encodedToken) {
        const decodedToken = atob(encodedToken);
        return decodedToken;
    }
    return null;
}

export const storeTokenInLocalStorage = (token) => {
    // Validate the token structure
    if (!isValidJWTToken(token)) {
        console.error('Invalid JWT token structure');
        return;
    }

    // Sanitize the token value
    const sanitizedToken = sanitizeToken(token);

    // Encode the token (optional)
    const encodedToken = encodeToken(sanitizedToken);

    // Store the sanitized and encoded token in localStorage
    localStorage.setItem('token', encodedToken);
}

export const isValidDate = (dateString) => {
    return isValid(new Date(dateString));
};

export const convertToCalendarSpecificDate = (dateString) => {
    if (!dateString) return '';
    const date = parseISO(dateString);
    const zonedDate = toZonedTime(date, 'UTC');
    const formattedDate = format(zonedDate, 'yyyy-MM-dd');
    return formattedDate;
};

export const convertToCustomDate = (dateString) => {
    if (!dateString) return '';
    const datePart = dateString.split('T')[0];
    const date = parseISO(datePart);
    const formattedDate = format(date, 'MM/dd/yyyy');
    return formattedDate;
}

export const ViewCalendarDate = (dateString) => {
    if (!dateString) return '';
    const datePart = dateString.split('T')[0];
    const date = parseISO(datePart);
    const formattedDate = format(date, 'yyyy-MM-dd');
    return formattedDate;
};

export const getPatients = async () => {
    const accessToken = getTokenFromLocalStorage();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/patient/patients-list`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    const data = await response.json();
    return data;
};

export function capitalizeNames(firstname, middlename, lastname) {
    const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
    const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
    const capitalizedMiddleName = middlename ? capitalizeFirstLetter(middlename) : '';
    return `${capitalizedLastName}, ${capitalizedFirstName}`;
}

export const setupInactivityTimer = (navigate) => {
    let inactivityTimeout;
    const TIMEOUT_DURATION = 60 * 60 * 1000; // 15 minutes in milliseconds

    const resetTimer = () => {
        clearTimeout(inactivityTimeout);
        inactivityTimeout = setTimeout(() => {
            localStorage.removeItem('token');
            navigate('/signin');
        }, TIMEOUT_DURATION);
    };

    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

    events.forEach(event => {
        document.addEventListener(event, resetTimer);
    });

    resetTimer();

    // Cleanup function
    return () => {
        events.forEach(event => {
            document.removeEventListener(event, resetTimer);
        });
        clearTimeout(inactivityTimeout);
    };
};

export const handleDates = (date, mode, subMode) => {
    if ((mode === 'view&edit' && subMode === 'edit') || (mode === 'add' && subMode === 'addMultiplePatients') || (mode === 'add' && subMode === 'edit')) {
        return (convertToCalendarSpecificDate(date));
    }
    if ((mode === 'view&edit' && subMode === 'view') || (mode === 'add' && subMode === 'view')) {
        return (ViewCalendarDate(date));
    }
}

export const fetchPatientDiagnoses = async (admission_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/diagnoses/patient-diagnoses?admission_id=${admission_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage()
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            console.error('Error:', response.status);
        }
    } catch (error) {
        return error;
    }
}

export const saveDiagnosis = async (admission_id, selectedDiagnosis) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getTokenFromLocalStorage()
    };
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ admission_id, selectedDiagnosis })
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/diagnoses/patient-diagnoses`, requestOptions);
    if (response.ok) {
        return true;
    } else {
        console.error('Error:', response.status);
        return false;
    }
}

export const addPatient = async (id, firstname, lastname, middlename = null, gender = null, dateofbirth, room = null, hospital_id, admitdate = null, dischargedate = null, visittype, status = null, facesheetalias = null, owningProvider = null) => {
    const formData = new URLSearchParams();
    formData.append('id', id);
    if (firstname) { formData.append('firstname', firstname); }
    if (lastname) { formData.append('lastname', lastname); }
    if (middlename) { formData.append('middlename', middlename); }
    if (gender) { formData.append('gender', gender); }
    if (dateofbirth) { formData.append('dateofbirth', dateofbirth); }
    if (room) { formData.append('room', room); }
    if (hospital_id) { formData.append('hospital_id', hospital_id); }
    if (admitdate) { formData.append('admitdate', admitdate); }
    if (dischargedate) { formData.append('dischargedate', dischargedate); }
    if (visittype) { formData.append('visittype', visittype); }
    if (status) { formData.append('status', status); }
    if (facesheetalias) { formData.append('facesheetalias', facesheetalias); }
    if (owningProvider) { formData.append('owning_provider_id', owningProvider); }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/patient/add-patient`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
            },
            body: formData.toString(),
        });

        return response;
    } catch (error) {
        throw error;
    }
}

export const addPatientIdToChargesPage = async (admission_id) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
        },
        body: JSON.stringify({ admission_id })
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/charges/charges-patients-list`, requestOptions);
    return response;
}

export const extractAgeFromDob = (dob) => {
    const dobDate = new Date(dob);
    const today = new Date();
    const age = today.getFullYear() - dobDate.getFullYear();
    const monthDiff = today.getMonth() - dobDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
        return age - 1;
    }
    return age;
};

export const checkAccessPassword = () => {
    const userInput = prompt("Please enter access password:");
    if (userInput === "777333") {
        return true;
    }
    return false;
};

export const columnWidths = {
    checkbox: '40px',
    name: '50px',
    facilityName: '50px',
    roomNumber: '50px',
    dob: '50px',
    dateRequested: '50px',
    timeRequested: '50px',
    visitDate: '50px',
    followupDate: '50px',
    assignedProvider: '50px',
    insuranceCarrier: '50px',
    rehabDiagnosis: '50px',
    rehabRecs: '50px',
    notes: '50px',
    status: '50px'
};

export const updateCommonPatientDetails = async (patient) => {
    const { patient_id, firstname, lastname, middlename, dateofbirth, gender, status } = patient;

    if (!patient_id) {
        toast.error('Patient ID is required');
        throw new Error('patient_id is required');
    }

    const requestBody = {
        patient_id,
        firstname,
        lastname,
        middlename,
        dateofbirth,
        gender,
        status
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/patient/update-common-patient-details`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            },
            body: JSON.stringify(requestBody)
        });

        const data = await response.json();

        if (!response.ok) {
            toast.error(data.message || 'Failed to update patient details');
            throw new Error(data.message || 'Failed to update patient details');
        }

        toast.success('Patient details updated successfully');
        return data;
    } catch (error) {
        toast.error(error.message || 'An error occurred while updating patient details');
        throw error;
    }
};

export const authorizedTitles = ['Physician', 'Nurse Practitioner', "Physician's Assistant"];

export const getTitlePrefix = (title) => {
    const prefixes = {
        'Physician': 'Dr.',
        'Nurse Practitioner': 'NP',
        "Physician's Assistant": 'PA'
    };
    return prefixes[title] || '';
};

export const formatProviderName = (provider) => {
    const prefix = getTitlePrefix(provider.title);
    if (provider.title === 'Physician') {
        return `${prefix} ${provider.firstname} ${provider.lastname}`;
    }
    return `${provider.firstname} ${provider.lastname}, ${prefix}`;
};

export const titleToDivision = {
    'Physician': 'Clinical',
    'Nurse Practitioner': 'Clinical',
    "Physician's Assistant": 'Clinical',
    'Operations': 'Business Strategy & Operations',
    'IT Admin': 'Engineering',
    'Hospital Staff': 'Other'
};








export const handleNotificationSetting = async (type, setState, isAllow) => {
    try {
        const serviceValue = !isAllow
        const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/notifications/${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
            },
            body: JSON.stringify({ value: serviceValue })
        });

        const data = await response.json();
        setState(prev => !prev)
        type = type.charAt(0).toUpperCase() + type.slice(1);
        const active = serviceValue === true ? "Enabled" : "Disabled";
        toast.success(`${type} Service ${active}`)
    } catch (error) {
        toast.error('Network error');
    }
}



export const handleSubmit = async (fromWorkHours, toWorkHours) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/workhours`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
            },
            body: JSON.stringify({ work_from_hours: fromWorkHours, to_work_hours: toWorkHours })
        });

        toast.success("Work Hours Added")
    } catch (e) {
        console.log(e)
    }
}


export const getNotificationSetting = async (setEmail, setSms, setFromWorkHour, setToWorkHour) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/notifications`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
            },
        });

        const { data } = await response.json();

        console.log(data)

        data.forEach((setting) => {
            setEmail(prev => setting.email_notification)
            setSms(prev => setting.sms_notification)
            setFromWorkHour(prev => setting.work_from_hours)
            setToWorkHour(prev => setting.to_work_hours)

        })


    } catch (error) {
        toast.error('Network error');
    }
}

export const fetchHospitals = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/facilities/hospitals`, {
        headers: {
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        }
    });
    if (response.ok) {
        const data = await response.json();
        return data;
    }
    return [];
};

export const fetchAuthorizedProviders = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/facilities/authorized-providers`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch authorized providers');
    }
    return response.json();
};

export const fetchVisitCodes = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        }
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}/charges/visit-codes`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch visit codes');
    }
    return response.json();
};

export const fetchSharedVisitUsers = async () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        }
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/charges/user-shared-visits`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch shared visit users');
    }
    return response.json();
}

export const ViewFacesheet = async (row) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
    };
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            id: row.id,
            filealias: row.facesheetalias
        })
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/view-file`, requestOptions);
        const data = await response.json();
        setTimeout(() => {
            window.open(data.signedUrl, '_blank');
        }, 100);
    } catch (error) {
        console.error('Error:', error);
    }
};

export const updatePatientOrder = (patientIds) => {
    return fetch(`${process.env.REACT_APP_API_URL}/charges/update-patient-order`, {
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
        },
        body: JSON.stringify({ arrayOfPatientsId: patientIds })
    });
};

export const attachSharedVisitsToAdmission = async (idsArray) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify({ ids_array: idsArray })
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/charges/attach-shared-visits-to-admission-id`, requestOptions);

    if (!response.ok) {
        throw new Error('Failed to attach shared visits to admission ID');
    }

    return response.json();
};

export const updatePatientCharges = (ids_array) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        },
        body: JSON.stringify({ ids_array })
    };
    return fetch(`${process.env.REACT_APP_API_URL}/charges/patient-charges`, requestOptions);
};