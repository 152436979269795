import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAuthorizedProviders, fetchHospitals, getPatients, getTitlePrefix } from '../../helpers/index';
import { deleteAllPatients } from '../../helpers/patient-list';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg';
import styles from '../../assets/css/PatientList.module.css'
import '../../App.css';
import TableRow from '../reusable/TableRow';
import DashboardStats from '../reusable/DashboardStats';
import tableStyle from '../../assets/css/Table.module.css'

const PatientList = () => {
    const navigate = useNavigate();
    const [patients, setPatients] = useState([]);
    const [patientStatus, setPatientStatus] = useState('');
    const [originalPatients, setOriginalPatients] = useState([]);
    const [sortOrder, setSortOrder] = useState({ column: null, order: null });
    const [searchQuery, setSearchQuery] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem('patientTableFilters');
        return savedFilters ? JSON.parse(savedFilters) : {
            facilityName: [],
            assignedProvider: [],
            status: 'active'
        };
    });
    const [hospitals, setHospitals] = useState([]);
    const [authorizedProviders, setAuthorizedProviders] = useState([]);
    const [showFacilityDropdown, setShowFacilityDropdown] = useState(false);
    const [showProviderDropdown, setShowProviderDropdown] = useState(false);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);

    useEffect(() => {
        const loadInitialData = async () => {
            const hospitalsData = await fetchHospitals();
            const providersData = await fetchAuthorizedProviders();

            setHospitals(hospitalsData);
            setAuthorizedProviders(providersData);
        };

        loadInitialData();
    }, []);

    useEffect(() => {
        const accessToken = localStorage.getItem('token');
        if (!accessToken) {
            navigate('/signin');
        }

        getPatients().then((p) => {
            setOriginalPatients(p);
            const filteredResults = p.filter(patient => {
                const matchesFacility = filters.facilityName.length === 0 ||
                    filters.facilityName.includes(patient.hospital_id);
                const matchesProvider = filters.assignedProvider.length === 0 ||
                    filters.assignedProvider.some(selectedProvider =>
                        patient.owning_provider_name === selectedProvider
                    );
                const matchesStatus = !filters.status ||
                    patient.status?.toLowerCase() === filters.status.toLowerCase();
                const matchesSearch = !searchQuery ||
                    `${patient.firstname} ${patient.middlename} ${patient.lastname}`.toLowerCase().includes(searchQuery.toLowerCase());

                return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
            });
            setPatients(filteredResults);
        });
    }, [navigate]);

    const handleSort = (column) => {
        let order = 'asc';
        if (sortOrder.column === column && sortOrder.order === 'asc') {
            order = 'desc';
        }

        setSortOrder({ column, order });

        const sortedPatients = [...patients].sort((a, b) => {
            if (column === 'facesheetalias') {
                if (a.facesheetalias === b.facesheetalias) {
                    const nameA = `${a.firstname} ${a.middlename} ${a.lastname}`.toLowerCase();
                    const nameB = `${b.firstname} ${b.middlename} ${b.lastname}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                }
                return order === 'asc'
                    ? (a.facesheetalias ? -1 : 1)
                    : (a.facesheetalias ? 1 : -1);
            }

            if (column === 'hospitalfacilityname') {
                const facilityA = (a.hospital_abbreviation || '').toLowerCase();
                const facilityB = (b.hospital_abbreviation || '').toLowerCase();

                if (facilityA === facilityB) {
                    const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
                    const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                }

                return order === 'asc'
                    ? facilityA.localeCompare(facilityB)
                    : facilityB.localeCompare(facilityA);
            }

            if (column === 'provider') {
                const providerA = (a.owning_provider_name || '').toLowerCase();
                const providerB = (b.owning_provider_name || '').toLowerCase();

                if (providerA < providerB) return order === 'asc' ? -1 : 1;
                if (providerA > providerB) return order === 'asc' ? 1 : -1;
                return 0;
            }

            if (a[column] === null) return -1;
            if (b[column] === null) return -1;
            const locationA = a[column].toLowerCase();
            const locationB = b[column].toLowerCase();

            if (locationA < locationB) return order === 'asc' ? -1 : 1;
            if (locationA > locationB) return order === 'asc' ? 1 : -1;

            const nameA = `${a.firstname} ${a.middlename} ${a.lastname}`.toLowerCase();
            const nameB = `${b.firstname} ${b.middlename} ${b.lastname}`.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;

            return 0;
        });
        setPatients(sortedPatients);
    };

    const filterPatients = (searchTerm) => {
        const filteredPatients = originalPatients.filter(patient => {
            const fullName = `${patient.firstname || ''} ${patient.middlename || ''} ${patient.lastname || ''}`.toLowerCase();

            const matchesFacility = filters.facilityName.length === 0 ||
                filters.facilityName.includes(patient.hospital_id);
            const matchesProvider = filters.assignedProvider.length === 0 ||
                filters.assignedProvider.some(selectedProvider =>
                    patient.owning_provider_name === selectedProvider
                );
            const matchesStatus = !filters.status ||
                patient.status?.toLowerCase() === filters.status.toLowerCase();
            const matchesSearch = !searchTerm ||
                fullName.includes(searchTerm.toLowerCase());

            return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
        });

        setPatients(filteredPatients);
    };

    //create a function to handle the search input
    const handleSearch = (searchTerm) => {
        setSearchQuery(searchTerm);
        filterPatients(searchTerm);
    };

    const AddPatient = () => {
        navigate('/patient', {
            state: {
                patient: null,
                mode: 'add',
                defaultChoice: 'multiple'
            }
        });
    }

    const handleDeleteAllPatients = async () => {
        const confirmed = window.confirm("Are you sure you want to delete all patient data? This action cannot be undone.");

        if (confirmed) {
            try {
                const response = await deleteAllPatients();
                const data = await response.json();

                if (response.ok) {
                    setPatients([]);
                    setOriginalPatients([]);
                } else {
                    const errorMessage = data.error || "Failed to delete patient data";
                    alert(errorMessage);
                }
            } catch (error) {
                console.error("Error deleting patients:", error);
                alert("An error occurred while deleting patient data");
            }
        }
    };

    const handleFilterChange = (field, value) => {
        const newFilters = { ...filters };

        if (field === 'facilityName' || field === 'assignedProvider') {
            if (newFilters[field].includes(value)) {
                newFilters[field] = newFilters[field].filter(item => item !== value);
            } else {
                newFilters[field] = [...newFilters[field], value];
            }
        } else if (field === 'status') {
            newFilters[field] = value;
            setShowStatusDropdown(false);
        }

        setFilters(newFilters);
        localStorage.setItem('patientTableFilters', JSON.stringify(newFilters));

        const filteredResults = originalPatients.filter(patient => {
            const matchesFacility = newFilters.facilityName.length === 0 ||
                newFilters.facilityName.includes(patient.hospital_id);
            const matchesProvider = newFilters.assignedProvider.length === 0 ||
                newFilters.assignedProvider.some(selectedProvider =>
                    patient.owning_provider_name === selectedProvider
                );
            const matchesStatus = !newFilters.status ||
                patient.status?.toLowerCase() === newFilters.status.toLowerCase();
            const matchesSearch = !searchQuery ||
                `${patient.firstname} ${patient.middlename} ${patient.lastname}`.toLowerCase().includes(searchQuery.toLowerCase());

            return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
        });

        setPatients(filteredResults);
    };

    const handleFilterClick = (filterType) => {
        if (filterType === 'facility') {
            setShowProviderDropdown(false);
            setShowStatusDropdown(false);
            setShowFacilityDropdown(!showFacilityDropdown);
        } else if (filterType === 'provider') {
            setShowFacilityDropdown(false);
            setShowStatusDropdown(false);
            setShowProviderDropdown(!showProviderDropdown);
        } else if (filterType === 'status') {
            setShowFacilityDropdown(false);
            setShowProviderDropdown(false);
            setShowStatusDropdown(!showStatusDropdown);
        }
    };

    const handleClearFilters = () => {
        const defaultFilters = {
            facilityName: [],
            assignedProvider: [],
            status: ''
        };
        setFilters(defaultFilters);
        setPatients(originalPatients);
        localStorage.setItem('patientTableFilters', JSON.stringify(defaultFilters));
    };

    const handleEditModeToggle = () => {
        const newEditMode = !isEditMode;
        setIsEditMode(newEditMode);

        if (!newEditMode) {
            getPatients().then((p) => {
                setOriginalPatients(p);
                const filteredResults = p.filter(patient => {
                    const matchesFacility = filters.facilityName.length === 0 ||
                        filters.facilityName.includes(patient.hospital_id);
                    const matchesProvider = filters.assignedProvider.length === 0 ||
                        filters.assignedProvider.some(selectedProvider =>
                            patient.owning_provider_name === selectedProvider
                        );
                    const matchesStatus = !filters.status ||
                        patient.status?.toLowerCase() === filters.status.toLowerCase();
                    const matchesSearch = !searchQuery ||
                        `${patient.firstname} ${patient.middlename} ${patient.lastname}`.toLowerCase().includes(searchQuery.toLowerCase());

                    return matchesFacility && matchesProvider && matchesStatus && matchesSearch;
                });
                setPatients(filteredResults);
            });
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const multiSelectContainer = event.target.closest(`.${CSS.escape(styles.multiSelect)}`);
            const isOptionClick = event.target.closest(`.${CSS.escape(styles.optionLabel)}`);

            if (isOptionClick) {
                return;
            }
            if (!multiSelectContainer) {
                setShowFacilityDropdown(false);
                setShowProviderDropdown(false);
                setShowStatusDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div style={{ height: '100vh', color: 'black', padding: 0, margin: '0.5rem' }} className="UtilitiesContainer">
            <DashboardStats />
            <div style={{ overflowY: 'hidden' }} className="UtilitiesMainContainer whiteContainerWide">
                <div className="marginContainer paddingBottomContainer">
                    <div className={[styles.topContainer1].join(' ')}>
                        <div className={styles.toggleContainer}>
                            <span>Edit Mode</span>
                            <div className="toggle" onClick={handleEditModeToggle}>
                                <div className={`toggle-slider ${isEditMode ? 'active' : ''}`}></div>
                            </div>
                        </div>
                    </div>
                    <div className={[styles.topContainer, styles.HideMobile].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={[styles.m5, styles.m0].join(' ')}>
                            <h3>All patients</h3>
                        </div>
                        <div onClick={handleDeleteAllPatients} className={[styles.button, styles.DeletePatientsBtn].join(' ')} style={{ backgroundColor: '#dc3545', marginLeft: '10px' }}>
                            Delete Patient List
                        </div>
                        <div className={[styles.inputField, styles.m5, styles.m0].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" value={searchQuery} placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                        <div onClick={() => AddPatient()} className={[styles.button, styles.AddPatientsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Patients
                        </div>
                    </div>

                    <div className={[styles.topContainer, styles.HideOnDesktop].join(' ')}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className={styles.m5}>
                            <h3>All patients</h3>
                        </div>
                        <div onClick={() => AddPatient()} className={[styles.button, styles.AddPatientsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            Add Patients
                        </div>
                        <div style={{ width: '77%', marginTop: '1rem' }} className={[styles.inputField, styles.m5].join(' ')}>
                            <SearchIcon className={styles.searchIcon} />
                            <input className={styles.input} type="text" placeholder="Search" onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.filterBar}>
                        <FilterIcon className={styles.filterIcon} />
                        <div className={styles.filterField}>
                            <div className={styles.multiSelect}>
                                <div className={styles.selectHeader} onClick={() => handleFilterClick('facility')}>
                                    {filters.facilityName.length ? `${filters.facilityName.length} Selected` : 'All Facilities'}
                                </div>
                                {showFacilityDropdown && (
                                    <div className={styles.optionsContainer}>
                                        {hospitals.map(hospital => (
                                            <label key={hospital.id} className={styles.optionLabel}>
                                                <input
                                                    type="checkbox"
                                                    checked={filters.facilityName.includes(hospital.id)}
                                                    onChange={() => handleFilterChange('facilityName', hospital.id)}
                                                />
                                                {hospital.hospital} ({hospital.abbreviation})
                                            </label>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.filterField}>
                            <div className={styles.multiSelect}>
                                <div className={styles.selectHeader} onClick={() => handleFilterClick('provider')}>
                                    {filters.assignedProvider.length ? `${filters.assignedProvider.length} Selected` : 'All Providers'}
                                </div>
                                {showProviderDropdown && (
                                    <div className={styles.optionsContainer}>
                                        {authorizedProviders
                                            .sort((a, b) => a.lastname.localeCompare(b.lastname))
                                            .map(provider => {
                                                const prefix = getTitlePrefix(provider.title);
                                                const providerName = `${prefix} ${provider.firstname} ${provider.lastname}`;
                                                return (
                                                    <label key={provider.id} className={styles.optionLabel}>
                                                        <input
                                                            type="checkbox"
                                                            checked={filters.assignedProvider.includes(providerName)}
                                                            onChange={() => handleFilterChange('assignedProvider', providerName)}
                                                        />
                                                        {providerName}
                                                    </label>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.filterField}>
                            <div className={styles.multiSelect}>
                                <div className={styles.selectHeader} onClick={() => handleFilterClick('status')}>
                                    {filters.status ? filters.status.charAt(0).toUpperCase() + filters.status.slice(1) : 'All Status'}
                                </div>
                                {showStatusDropdown && (
                                    <div className={styles.optionsContainer}>
                                        <label className={styles.optionLabel} onClick={() => handleFilterChange('status', '')}>
                                            All Status
                                        </label>
                                        <label className={styles.optionLabel} onClick={() => handleFilterChange('status', 'active')}>
                                            Active
                                        </label>
                                        <label className={styles.optionLabel} onClick={() => handleFilterChange('status', 'inactive')}>
                                            Inactive
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        <button
                            onClick={handleClearFilters}
                            className={styles.clearFiltersBtn}
                        >
                            Clear Filters
                        </button>
                    </div>
                    <div className={tableStyle.container}>
                        <div className={tableStyle.tableWrapper} style={{ left: '0rem', right: '0rem' }}>
                            <table className={`${tableStyle.compactTable2} ${tableStyle.mobileText}`}>
                                <thead id="tableHeader">
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col" onClick={() => handleSort('lastname')}>
                                            Name {sortOrder.column === 'lastname' ? (sortOrder.order === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                                        </th>
                                        <th scope="col" className={styles.HideMobile}>DOB</th>
                                        <th scope="col" className={styles.HideMobile}>Admit Date</th>
                                        <th scope="col" className={styles.HideMobile} onClick={() => handleSort('hospitalfacilityname')}>
                                            Facility Name {sortOrder.column === 'hospitalfacilityname' ? (sortOrder.order === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                                        </th>
                                        <th scope="col" className={styles.HideMobile} onClick={() => handleSort('provider')}>
                                            Provider {sortOrder.column === 'provider' ? (sortOrder.order === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                                        </th>
                                        <th scope="col" onClick={() => handleSort('visittype')}>
                                            Visit Type {sortOrder.column === 'visittype' ? (sortOrder.order === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                                        </th>
                                        <th scope="col" className={styles.HideMobile}>Diagnoses</th>
                                        <th scope="col" onClick={() => handleSort('status')}>
                                            Status {sortOrder.column === 'status' ? (sortOrder.order === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                                        </th>
                                        <th scope="col" onClick={() => handleSort('facesheetalias')}>
                                            Facesheet {sortOrder.column === 'facesheetalias' ? (sortOrder.order === 'asc' ? ' ▲' : ' ▼') : ' ▼'}
                                        </th>
                                        <th scope="col">Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {patients.length > 0 ? (
                                        patients.map((patient, index) => (
                                            <TableRow
                                                key={index}
                                                patient={patient}
                                                isEditMode={isEditMode}
                                                setPatients={setPatients}
                                            />
                                        ))
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                        <div className={tableStyle.totalCounter}>
                            Total Patients: {patients.length}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientList;
