import React, { useState, useEffect } from 'react';
import PatientDetails from './PatientDetails';
import { ReactComponent as PatientDetailsLeftArrow } from '../../assets/icons/PatientDetailsLeftArrow.svg';
import { ReactComponent as PatientDetailsRightArrow } from '../../assets/icons/PatientDetailsRightArrow.svg';
import { toast } from 'react-toastify';
import { addPatient, addPatientIdToChargesPage, saveDiagnosis, formatProviderName } from '../../helpers';
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate, capitalizeNames } from '../../helpers/index.js';
import styles from '../../assets/css/Patient.module.css'
import t from '../../assets/css/Table.module.css'

const AddMultiplePatients = ({ patients, owningProvider }) => {
    const [patientIndex, setPatientIndex] = useState(0);
    const [addPatientToChargesPage, setAddPatientToChargesPage] = useState(true)
    const [providers, setProviders] = useState([]);
    const isSummaryPage = patientIndex === patients.length;
    const [hospitals, setHospitals] = useState([]);

    useEffect(() => {
        const fetchHospitals = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/facilities/hospitals`, {
                    headers: {
                        'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                    }
                });
                const data = await response.json();
                setHospitals(data);
            } catch (error) {
                console.error('Error fetching hospitals:', error);
            }
        };
        fetchHospitals();
    }, []);

    const changePatientIndex = (type) => {
        if (type === 'left') {
            if (patientIndex > 0) {
                setPatientIndex(patientIndex - 1);
            }
        } else {
            if (patientIndex < patients.length) {
                const currentPatient = patients[patientIndex];
                if (validateCurrentPatient(currentPatient)) {
                    setPatientIndex(patientIndex + 1);
                }
            }
        }
    }

    useEffect(() => {
        const fetchProviders = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/facilities/authorized-providers`, {
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });
            const data = await response.json();
            setProviders(data);
        };
        fetchProviders();
    }, []);

    const validateCurrentPatient = (patient) => {
        if (!patient.firstname) {
            toast.error('Please enter first name');
            return false;
        }
        if (!patient.lastname) {
            toast.error('Please enter last name');
            return false;
        }
        if (!patient.dateofbirth) {
            toast.error('Please select date of birth');
            return false;
        }
        if (!patient.admitdate) {
            toast.error('Please select admit date');
            return false;
        }
        if (!patient.visittype) {
            toast.error('Please select visit type');
            return false;
        }
        const hasHospital = patient.location?.id;
        if (!hasHospital) {
            toast.error('Please select place of service');
            return false;
        }
        if (!patient.owning_provider_id) {
            toast.error('Please select owning provider');
            return false;
        }
        return true;
    };
    const saveAllPatients = async () => {
        try {
            // Add duplicate check
            for (let i = 0; i < patients.length; i++) {
                for (let j = i + 1; j < patients.length; j++) {
                    if (
                        patients[i].firstname === patients[j].firstname &&
                        patients[i].lastname === patients[j].lastname &&
                        patients[i].dateofbirth === patients[j].dateofbirth
                    ) {
                        toast.error("The same facesheet has been detected in this batch. Please try uploading again. Only upload unique facesheets.");
                        return;
                    }
                }
            }

            const savePatientsPromiseArray = patients.map(p => {
                const hospitalId = p.location?.id || p.hospital_id;
                return addPatient(p.id, p.firstname, p.lastname, p.middlename, p.gender, p.dateofbirth, p.room, hospitalId, p.admitdate, p.dischargedate, p.visittype, p.status, p.facesheetalias, p.owning_provider_id);
            });

            let result = await Promise.all(savePatientsPromiseArray);

            const patientResponses = await Promise.all(result.map(r => r.json()));

            // Check if all patients were added successfully
            const successfulPatients = patientResponses.filter(response => response.success);
            if (successfulPatients.length > 0) {
                const diagnosesPromiseArray = successfulPatients.map((p, index) => {
                    return saveDiagnosis(p.id, patients[index].selectedDiagnosis ? patients[index].selectedDiagnosis : []);
                });

                toast.success(successfulPatients.length + ' Patients added successfully');

                if (diagnosesPromiseArray.length > 0) {
                    await Promise.all(diagnosesPromiseArray);
                    toast.success('Diagnoses Saved Successfully!');
                }

                if (addPatientToChargesPage) {
                    const addPatientToChargesPagePromiseArray = successfulPatients.map((p, index) => addPatientIdToChargesPage(p.id, patientResponses[index].admission_id));
                    await Promise.all(addPatientToChargesPagePromiseArray);
                    toast.success('Patients added to Charges Page');
                }
            } else {
                toast.error('Some patients could not be added.');
            }
        } catch (error) {
            toast.error('An error occurred');
        }
    }

    return (
        <div className={styles.multiplePatientSubContainer}>
            <div className={styles.summaryPageNavigation}>
                <PatientDetailsLeftArrow fill={patientIndex === 0 ? 'grey' : 'black'} onClick={() => changePatientIndex('left')} />
                <p>{isSummaryPage ?
                    `Summary (${patients.length + 1} / ${patients.length + 1})` :
                    `Patient (${patientIndex + 1} / ${patients.length + 1})`}
                </p>
                <PatientDetailsRightArrow fill={patientIndex === patients.length ? 'grey' : 'black'} onClick={() => changePatientIndex('right')} />
            </div>
            {isSummaryPage ? (
                <div className={styles.summaryPage}>
                    <h2>Summary</h2>
                    <div className={t.container}>
                        <div className={t.tableWrapper}>
                            <table className={`${t.compactTable2} ${t.mobileText}`}>
                                <thead id="tableHeader">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">DOB</th>
                                        <th scope="col">Admit Date</th>
                                        <th scope="col">Facility Name</th>
                                        <th scope="col">Provider</th>
                                        <th scope="col">Visit Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {patients.map((row, index) => (
                                        <tr key={index}>
                                            <td>{capitalizeNames(row.firstname, row.middlename, row.lastname)}</td>
                                            <td>{isValidDate(row.dateofbirth) && convertToCustomDate(row.dateofbirth)}</td>
                                            <td>{isValidDate(row.admitdate) && convertToCustomDate(row.admitdate)}</td>
                                            <td>
                                                {row.location?.abbreviation || (row.hospital_id &&
                                                    hospitals.find(h => h.id === Number(row.hospital_id))?.abbreviation) || 'N/A'}
                                            </td>
                                            <td>
                                                {providers.find(p => Number(p.id) === Number(row.owning_provider_id)) ?
                                                    formatProviderName(providers.find(p => Number(p.id) === Number(row.owning_provider_id))) : ''}
                                            </td>
                                            <td>{row.visittype}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={t.totalCounter}>
                            Total Patients: {patients.length}
                        </div>
                    </div>
                    <div style={{ justifyContent: 'center', marginTop: '2rem' }} className={styles.checkboxContainer}>
                        <input type="checkbox" checked={addPatientToChargesPage} onChange={() => setAddPatientToChargesPage(!addPatientToChargesPage)} />
                        <span>Add all patients to charges page</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <div style={{ backgroundColor: 'black' }} onClick={saveAllPatients} className="purple-btn">Save all</div>
                    </div>
                </div>
            ) : (
                <PatientDetails
                    key={patientIndex}
                    patient={patients[patientIndex]}
                    mode={'add'}
                    subMode={'addMultiplePatients'}
                    selectedDiagnosisProps={patients[patientIndex]?.selectedDiagnosis}
                />
            )}
        </div>
    );
};

export default AddMultiplePatients;