import React, { useState, useEffect } from 'react';
import { ReactComponent as Tick } from '../../assets/icons/facesheet_tick.svg';
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import styles from '../../assets/css/ChargesTab.module.css';
import { useNavigate } from 'react-router-dom';
import { convertToCustomDate, isValidDate, ViewFacesheet } from '../../helpers';
import { ReactComponent as DragHandle } from '../../assets/icons/drag-handle.svg'
import HoverContent from './HoverContent';
import Loader from '../reusable/Loader';

const ChargesTable = ({ patient, onSelect, isSelected }) => {
    const navigate = useNavigate();
    const [loadingFacesheet, setLoadingFacesheet] = useState(false);

    function capitalizeNames(firstname, middlename, lastname) {
        const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        const capitalizedFirstName = firstname ? capitalizeFirstLetter(firstname) : '';
        const capitalizedLastName = lastname ? capitalizeFirstLetter(lastname) : '';
        const capitalizedMiddleName = middlename ? capitalizeFirstLetter(middlename) : '';
        return `${capitalizedLastName}, ${capitalizedFirstName}`;
    }

    const EditDetails = (patient) => {
        navigate('/patient', { state: { patient: patient, mode: 'view&edit', autoFillChoice: true } });
    };

    const shared_visits = (patient) => {
        return patient && patient.shared_visits && patient.shared_visits.length > 0
            ? patient.shared_visits.map((visit, index) => (
                `${visit.name}${index < patient.shared_visits.length - 1 ? ', ' : ''}`
            ))
            : '-';
    };

    const handleViewFacesheet = async (e) => {
        e.stopPropagation();
        setLoadingFacesheet(true);

        try {
            await ViewFacesheet(patient);
        } catch (error) {
            console.error("Error viewing facesheet:", error);
        } finally {
            setLoadingFacesheet(false);
        }
    };

    return (
        <>
            <td>
                <DragHandle className={styles.dragHandle} />
            </td>
            <td>
                <input
                    type='checkbox'
                    onChange={(e) => onSelect(e.target.checked)}
                    checked={isSelected}
                    className={styles.checkbox}
                />
            </td>
            <td>
                <Eye fill={'var(--purple)'} onClick={() => EditDetails(patient)} className={styles.eyeIcon} />
                {capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}
            </td>
            <td>
                <HoverContent
                    hoverContent={
                        patient.visit_codes.map((c, index) => (
                            <div key={index}>
                                {c.visit_code} - {c.description}
                            </div>
                        ))
                    }
                    position="top"
                    maxHeight="200px"
                >
                    {patient.visit_codes.map((c, index) => (
                        <span key={index}>
                            {c.visit_code}
                            {(patient.visit_codes.length - 1) !== index && ', '}
                        </span>
                    ))}
                </HoverContent>
            </td>
            <td>{shared_visits(patient)}</td>
            <td className={styles.HideMobile}>
                {isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)}
            </td>
            <td className={styles.HideMobile}>
                {patient.hospital_abbreviation}
            </td>
            <td className={styles.HideMobile}>
                {patient.visittype}
            </td>
            <td>
                <HoverContent
                    hoverContent={
                        patient.diagnoses.slice().sort((a, b) => (b?.is_primary ? 1 : 0) - (a?.is_primary ? 1 : 0)).map((diagnosis, index) => (
                            <div key={index}>
                                {diagnosis?.is_primary ? <span className={styles.required}>*</span> : null}
                                {diagnosis.code} - {diagnosis.description}
                            </div>
                        ))
                    }
                    position="top"
                    maxHeight="200px"
                >
                    {patient.diagnoses.slice().sort((a, b) => (b?.is_primary ? 1 : 0) - (a?.is_primary ? 1 : 0)).map((code, index) => (
                        <span key={index}>
                            {code?.is_primary ? <span className={styles.required}>*</span> : null}
                            {code.code}
                            {(patient?.diagnoses?.length - 1) !== index && ", "}
                        </span>
                    ))}
                </HoverContent>
            </td>
            <td className={styles.HideMobile}>
                {patient.status}
            </td>
            <td>
                {patient.facesheetalias ? (
                    loadingFacesheet ? (
                        <Loader width={20} height={20} />
                    ) : (
                        <div onClick={handleViewFacesheet} className={styles.viewBtn}>
                            View
                        </div>
                    )
                ) : (
                    <Cross fill={'red'} />
                )}
            </td>
        </>
    );
};

export default ChargesTable;