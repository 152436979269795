import React, { useEffect, useState, useRef } from 'react'
import styles from '../../assets/css/ChargesTab.module.css'
import styles1 from '../../assets/css/Charges.module.css';
import { ReactComponent as PlusIcon } from '../../assets/icons/charges-plus.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/charges-delete.svg'
import { ReactComponent as SharedIcon } from '../../assets/icons/charges-shared-visit.svg'
import { ReactComponent as SubmitIcon } from '../../assets/icons/charges-submit.svg'
import { ReactComponent as VisitCodesPlusIcon } from '../../assets/icons/visit-code-plus.svg'
import ChargesAddPatient from '../reusable/ChargesAddPatient'
import ChargesSharedVisit from '../reusable/ChargesSharedVisits'
import { getTokenFromLocalStorage } from '../../helpers'
import ChargesVisitCodes from '../reusable/ChargesVisitCodes'
import { toast } from 'react-toastify';
import Loader from '../reusable/Loader'
import { convertToCalendarSpecificDate, isValidDate } from '../../helpers';
import ChargesTable from './ChargesTable'
import { useMediaQuery } from '@mui/material';

const ChargesTab = () => {
    const [showModal, setShowModal] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [modalName, setModalName] = useState('');
    const [patients, setPatients] = useState([]);
    const [selectedPatients, setSelectedPatients] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [date, setDate] = useState('');
    const isLandscape = useMediaQuery('(orientation: landscape) and (max-width: 1000px)');

    const isInitialMount = useRef(true);
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            fetchChargesPatients();
        } else {
            // This block will run on subsequent renders when patients changes
            // You can add any additional logic here if needed
        }
    }, [patients]);

    const fetchChargesPatients = async () => {
        await fetch(`${process.env.REACT_APP_API_URL}/charges/charges-patients-list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
            }
        }).then(res => res.json()).then(data => {
            setPatients(data);
        }).catch(err => {
            console.log('err : ', err);
        });

    }

    const toggleModal = (name) => {
        if ((name === 'visit codes' || name === 'shared visits') && selectedPatients.length === 0) {
            toast.error('Please select a patient');
            return;
        }
        setShowModal(!showModal);
        setModalName(name);
    };

    const deletePatients = async () => {
        if (selectedPatients.length === 0) {
            toast.error('Please select at least one patient to delete');
            return;
        }
        try {
            let promiseArray = [];
            selectedPatients.forEach(patient => {
                promiseArray.push(fetch(`${process.env.REACT_APP_API_URL}/charges/charges-patients-list`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
                    },
                    body: JSON.stringify({ admission_id: patient.id })
                }));
            });

            await Promise.all(promiseArray);

            const arrayofpaitentsid = selectedPatients.map(patient => patient.patient_id);
            await fetch(`${process.env.REACT_APP_API_URL}/charges/delete-patient-order`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage('token')}`
                },
                body: JSON.stringify({ arrayofpaitentsid })
            });

            await fetchChargesPatients();
            setSelectedPatients([]);
            if (isAllSelected) {
                setIsAllSelected(false);
            }
            toast.success('Patients deleted successfully');
        } catch (error) {
            console.error('Error deleting patients:', error);
        }
    };

    const reRenderPatients = async (message) => {
        toast.success(message);
        await fetchChargesPatients();
    }

    const submitCharges = async () => {
        try {
            if (selectedPatients.length === 0) {
                toast.error('Please select at least one patient to submit charges');
                return;
            }
            if (date === '') {
                toast.error('Please select date of service before submitting charges');
                return;
            }

            const patientsWithoutVisitCodes = selectedPatients.filter(
                patient => !patient.visit_codes || patient.visit_codes.length === 0
            );
            if (patientsWithoutVisitCodes.length > 0) {
                toast.error(`${patientsWithoutVisitCodes.length} patient(s) don't have any visit codes assigned`);
                return;
            }

            let charges_ids = [];
            selectedPatients.forEach(patientData => {
                patientData.visit_codes.forEach(vc => charges_ids.push(vc.id));
            });
            //remove duplicates from charges_ids integer array
            const removeDuplicates = (array) => [...new Set(array)];
            charges_ids = removeDuplicates(charges_ids);

            setSubmitLoading(true);

            let promiseArray = [];
            let timestamp = new Date().toISOString();

            selectedPatients.forEach((patient, index) => {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    },
                    body: JSON.stringify({
                        patient,
                        date_of_service: date,
                        timestamp
                    })
                }
                promiseArray.push(fetch(`${process.env.REACT_APP_API_URL}/charges/patient-charges-history`, requestOptions));
            });

            const responses = await Promise.all(promiseArray);
            const results = await Promise.all(responses.map(r => r.json()));

            // Check if all requests were successful
            const allSuccessful = results.every(result => result.success);

            setSubmitLoading(false);

            if (allSuccessful) {
                reRenderPatients('Charges Submitted Successfully');
            } else {
                // Some requests failed
                const failedCount = results.filter(result => !result.success).length;
                toast.warning(`${failedCount} charge submissions failed`);
            }
        } catch (error) {
            setSubmitLoading(false);
            console.log('Error submitting charges:', error);
            toast.error('Error submitting charges');
        }
    }

    return (
        <>
            {showModal && modalName === 'add patients' && <ChargesAddPatient reRenderPatients={reRenderPatients} setShowModal={setShowModal} patientsList={patients} />}
            {showModal && modalName === 'shared visits' && <ChargesSharedVisit reRenderPatients={reRenderPatients} selectedPatients={selectedPatients} setShowModal={setShowModal} />}
            {showModal && modalName === 'visit codes' && <ChargesVisitCodes reRenderPatients={reRenderPatients} selectedPatients={selectedPatients} setShowModal={setShowModal} />}

            <div className={`marginContainer ${isLandscape ? styles.landscapeContainer : ''}`}>
                <div className={styles.optionsContainer}>
                    <div className={styles.partA}>
                        <div onClick={() => toggleModal('add patients')} className={[styles.button, styles.AddPatientsBtn].join(' ')}>
                            <PlusIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>Add Patients</div>
                        </div>
                        <div onClick={() => deletePatients()} className={[styles.button, styles.DeletePatientsBtn].join(' ')}>
                            <TrashIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>Delete Patients</div>
                        </div>
                    </div>
                    <div className={styles.partA}>
                        <div onClick={() => toggleModal('visit codes')} className={[styles.button, styles.VisitCodesBtn].join(' ')}>
                            <VisitCodesPlusIcon fill={'white'} className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>Visit Codes</div>
                        </div>
                        <div onClick={() => toggleModal('shared visits')} className={[styles.button, styles.SharedVisitsBtn].join(' ')}>
                            <SharedIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>Shared Visits</div>
                        </div>
                    </div>
                </div>

                <div className={styles.tableContainer}>
                    <ChargesTable
                        isAllSelected={isAllSelected}
                        setIsAllSelected={setIsAllSelected}
                        setSelectedPatients={setSelectedPatients}
                        patients={patients}
                        selectedPatients={selectedPatients}
                    />
                </div>

                <div className={`${styles1.dateContainer} ${isLandscape ? styles.landscapeDateContainer : ''}`}>
                    <div className={styles.dateText}>Date Of Service:</div>
                    <input className={styles1.dateField} type="date" value={date && isValidDate(date) && convertToCalendarSpecificDate(date)} onChange={(e) => setDate(e.target.value)} />
                    {submitLoading ? <Loader /> : <div onClick={() => submitCharges()} className={[styles.button, styles.SubmitChargesBtn].join(' ')}>
                        <SubmitIcon className={styles.OptionButtonsIcon} />
                        <div className={styles.actionsBtnText}>Submit Charges</div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default ChargesTab
