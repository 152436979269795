import React, { useState, useEffect, useCallback } from 'react';
import styles from '../../assets/css/Patient.module.css'
import { getTokenFromLocalStorage, formatProviderName, authorizedTitles } from '../../helpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import AddMultiplePatients from './AddMultiplePatients';
import PatientDetails from './PatientDetails';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';

const GetMultiplePatientsData = () => {
    const [loading, setLoading] = useState(false);
    const [facesheets, setFacesheets] = useState([]);
    const [visittype, setVisitType] = useState('');
    const [patients, setPatients] = useState([]);
    const [owningProvider, setOwningProvider] = useState('');
    const [authorizedProviders, setAuthorizedProviders] = useState([]);
    const [showPatientDetails, setShowPatientDetails] = useState(false);
    const [singlePatient, setSinglePatient] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            const requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            };
            const [userResponse, providersResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/profile/user-details`, requestOptions),
                fetch(`${process.env.REACT_APP_API_URL}/facilities/authorized-providers`, requestOptions)
            ]);

            const userData = await userResponse.json();
            const providers = await providersResponse.json();

            if (providersResponse.ok && userResponse.ok) {
                setAuthorizedProviders(providers);
                if (authorizedTitles.includes(userData.title)) {
                    setOwningProvider(userData.id);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleSkipFacesheet = async () => {
        const formData = new FormData();
        formData.append('WillUploadFaceSheet', "False");
        formData.append('owning_provider_id', owningProvider);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents/upload-facesheet`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            });
            setSinglePatient(response.data);
            setShowPatientDetails(true);
        } catch (error) {
            toast.error('Error initializing patient data');
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

        const validFiles = files.filter(file => {
            if (!allowedExtensions.exec(file.name)) {
                toast.error(`${file.name} is not a valid file type`);
                return false;
            }
            return true;
        });

        setFacesheets(validFiles);
    };

    const uploadFacesheet = async () => {
        if (facesheets.length === 0) {
            toast.error('Please select facesheet files');
            return;
        }

        if (visittype === '') {
            toast.error('Please select a visit type');
            return;
        }

        if (owningProvider === '') {
            toast.error('Please select owning provider');
            return;
        }

        try {
            setLoading(true);

            const uploadPromises = facesheets.map(file => {
                const formData = new FormData();
                formData.append('facesheet', file);
                formData.append('visittype', visittype);
                formData.append('WillUploadFaceSheet', "True");
                formData.append('owning_provider_id', owningProvider);

                return axios.post(`${process.env.REACT_APP_API_URL}/documents/upload-facesheet`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    }
                });
            });

            const results = await Promise.all(uploadPromises);
            const patientsArray = [];
            results.forEach(result => {
                const patient = result.data;
                patientsArray.push(patient);
            });
            setPatients(patientsArray);
            toast.success('All facesheets uploaded successfully');
            setFacesheets([]);

        } catch (error) {
            toast.error('Error uploading some facesheets');
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.multiplePatientContainer}>
            {showPatientDetails ? (
                <PatientDetails
                    patient={singlePatient}
                    mode="add"
                    subMode="edit"
                />
            ) : patients.length > 0 ? (
                <AddMultiplePatients patients={patients} owningProvider={owningProvider} />
            ) : (
                <div className={styles.multiplePatientContent}>
                    <h2 className={styles.multiplePatientHeading}>Upload Patient Facesheets</h2>
                    <div className={styles.owningProviderField}>
                        <label htmlFor="owningProvider">Owning Provider<span className={styles.required}>*</span></label>
                        <select
                            className={styles.owningProviderSelect}
                            value={owningProvider}
                            onChange={(e) => setOwningProvider(e.target.value)}
                            required
                        >
                            <option value="">Select Owning Provider</option>
                            {authorizedProviders.map(provider => {
                                const fullName = formatProviderName(provider);
                                return (
                                    <option key={provider.id} value={provider.id}>{fullName}</option>
                                );
                            })}
                        </select>
                    </div>
                    <div className={styles.visitTypeSelector}>
                        <span>Visit Type</span>
                        <div className={styles.visitTypeOptions}>
                            <div
                                onClick={() => setVisitType('inpatient')}
                                className={`${styles.visitTypeInactiveButton} ${visittype === 'inpatient' ? styles.activeButton : ''}`}>
                                Inpatient
                            </div>
                            <div
                                onClick={() => setVisitType('consult')}
                                className={`${styles.visitTypeInactiveButton} ${visittype === 'consult' ? styles.activeButton : ''}`}>
                                Consult
                            </div>
                        </div>
                    </div>
                    <div className={styles.fileUploadBox}>
                        <label htmlFor="fileInput" className={styles.customFileInput}>
                            <UploadIcon />
                            <span>Choose Files</span>
                        </label>
                        <input
                            type="file"
                            id="fileInput"
                            multiple
                            accept="image/*,application/pdf"
                            onChange={handleFileChange}
                            className={styles.hiddenInput}
                        />
                    </div>

                    {facesheets.length > 0 && (
                        <div>Selected files: {facesheets.length}</div>
                    )}
                    {loading ? (
                        <div>Uploading facesheets...</div>
                    ) : (
                        <>
                            <div onClick={uploadFacesheet} className={styles.uplaodButton}>
                                Upload Facesheets
                            </div>
                            <div onClick={handleSkipFacesheet} className={styles.skipUplaodButton}>
                                Skip Uploading
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default GetMultiplePatientsData;