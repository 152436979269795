import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from '../../assets/css/Consult.module.css'
import { convertToCalendarSpecificDate, handleDates, getTokenFromLocalStorage, isValidDate, formatProviderName, authorizedTitles } from '../../helpers/index'
import Loader from '../reusable/Loader';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import CustomDatePicker from '../reusable/CustomDatePicker';

const ConsultDetails = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { consults, mode } = state || { consults: null, mode: 'view&edit' };
    const [subMode, setSubMode] = useState((mode === 'add' || mode === 'view&edit') ? 'edit' : 'view');
    const [hospital_id, setHospitalId] = useState(consults?.hospital_id || '');
    const [hospitalfacilityname, setHospitalFacilityName] = useState(consults?.hospital_name || '');
    const [roomnumber, setRoomNumber] = useState(consults?.roomnumber || '');
    const [firstname, setFirstName] = useState(consults?.firstname || '');
    const [lastname, setLastName] = useState(consults?.lastname || '');
    const [smsAlert, setSmsAlert] = useState(true);
    const [dob, setDob] = useState(consults?.dob ? convertToCalendarSpecificDate(consults.dob) : '');
    const [daterequested, setDateRequested] = useState(
        consults?.daterequested
            ? convertToCalendarSpecificDate(consults.daterequested)
            : new Date().toISOString()
    );
    const [timerequested, setTimeRequested] = useState(() => {
        if (consults?.timerequested) {
            // Create a Date object for existing time
            const now = new Date();
            const [hours, minutes] = consults.timerequested.split(':');
            now.setHours(parseInt(hours), parseInt(minutes), 0, 0);
            return now;
        }

        // Set current time for new consults
        const now = new Date();
        now.setSeconds(0, 0);
        return now;
    });

    const [visitdate, setVisitDate] = useState(consults?.visitdate ? convertToCalendarSpecificDate(consults.visitdate) : '');
    const [followupdate, setFollowupDate] = useState(consults?.followupdate ? convertToCalendarSpecificDate(consults.followupdate) : '');
    const [owningProvider, setOwningProvider] = useState(consults?.owning_provider_id || '');
    const [insurancecarrier, setInsuranceCarrier] = useState(consults?.insurancecarrier || '');
    const [rehabdiagnosis, setRehabDiagnosis] = useState(consults?.rehabdiagnosis || '');
    const [rehabrecs, setRehabRecs] = useState(consults?.rehabrecs || '');
    const [notes, setNotes] = useState(consults?.notes || '');
    const [status, setStatus] = useState(consults?.status || 'open');
    const [loading, setLoading] = useState(false);
    const [authorizedProviders, setAuthorizedProviders] = useState([]);
    const [hospitals, setHospitals] = useState([]);

    const validateDOB = (date) => {
        const birthYear = new Date(date).getFullYear();
        const currentYear = new Date().getFullYear();
        const minValidYear = currentYear - 18; // 18 years ago

        return birthYear <= minValidYear;
    };

    const fetchAuthorizedProviders = useCallback(async () => {
        try {
            const requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            };
            const [userResponse, providersResponse, hospitalsResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/profile/user-details`, requestOptions),
                fetch(`${process.env.REACT_APP_API_URL}/facilities/authorized-providers`, requestOptions),
                fetch(`${process.env.REACT_APP_API_URL}/facilities/hospitals`, requestOptions)
            ]);

            const userData = await userResponse.json();
            const providers = await providersResponse.json();
            const hospitals = await hospitalsResponse.json();

            if (providersResponse?.ok && userResponse?.ok && hospitalsResponse?.ok) {
                setAuthorizedProviders(providers);
                setHospitals(hospitals);
                if (mode === 'add') {
                    if (authorizedTitles.includes(userData.title)) {
                        setOwningProvider(userData.id);
                        handleConsultOnChange('owning_provider_id', userData.id);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, []);




    useEffect(() => {
        fetchAuthorizedProviders();
    }, [fetchAuthorizedProviders]);

    const handleConsultOnChange = (field, value) => {
        const setters = {
            roomnumber: setRoomNumber,
            firstname: setFirstName,
            lastname: setLastName,
            dob: setDob,
            daterequested: setDateRequested,
            timerequested: setTimeRequested,
            visitdate: setVisitDate,
            followupdate: setFollowupDate,
            owningProvider: setOwningProvider,
            insurancecarrier: setInsuranceCarrier,
            rehabdiagnosis: setRehabDiagnosis,
            rehabrecs: setRehabRecs,
            notes: setNotes,
            status: setStatus,
        };

        if (setters[field]) {
            setters[field](value);
        }

        if (field === "timerequested") {
            if (value instanceof Date) {
                // Format as "HH:mm"
                const formattedTime = value.toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false, // Ensures 24-hour format
                });

                setTimeRequested(formattedTime);
                return;
            }
        }

        if (field === 'hospital_id') {
            setHospitalId(value);
            setHospitalFacilityName(hospitals.filter(hospital => hospital.id === Number(value))[0]?.hospital);
        }

        if (field === 'dob') {
            setDob(value);
        }
        if (field === 'daterequested') {
            setDateRequested(value);
        }
        if (field === 'visitdate') {
            setVisitDate(value);
        }
    };


    const validateForm = () => {
        if (!hospital_id) return 'Please enter Hospital facility name';
        if (!firstname) return 'Please enter first name';
        if (!lastname) return 'Please enter last name';
        if (!daterequested) return 'Please select Date request';
        if (!timerequested) return 'Please select Time request';
        if (!owningProvider) return 'Please enter Assigned Provider Name';
        return null;
    };

    const submitForm = async () => {

        if (dob && !validateDOB(dob)) {
            const confirmSubmit = window.confirm('Warning: Patient appears to be under 18 years old. Do you want to proceed?');
            if (!confirmSubmit) {
                return;
            }
        }

        const validationError = validateForm();
        if (validationError) {
            toast.error(validationError);
            return;
        }

        const formData = new URLSearchParams();

        let formattedTime;
        if (timerequested instanceof Date) {
            formattedTime = timerequested.toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
        } else {
            formattedTime = timerequested;
        }

        if (!consults?.id) {
            formData.append('hospitalfacilityname', hospitalfacilityname); // using to sending email at backend
            const fields = {
                hospital_id, roomnumber, firstname, lastname, dob,
                daterequested, timerequested: formattedTime, visitdate, followupdate, owning_provider_id: owningProvider,
                insurancecarrier, rehabdiagnosis, rehabrecs, notes, status, smsAlert
            };
            Object.entries(fields).forEach(([key, value]) => {
                if (value) formData.append(key, value);
            });
        }
        else {
            formData.append('id', consults.id);

            const nonRequiredFields = [
                'roomnumber',
                'visitdate',
                'followupdate',
                'insurancecarrier',
                'rehabdiagnosis',
                'rehabrecs',
                'notes',
            ];

            const currentValues = {
                hospital_id,
                roomnumber,
                firstname,
                lastname,
                dob,
                daterequested,
                timerequested: formattedTime,
                visitdate,
                followupdate,
                owning_provider_id: owningProvider,
                insurancecarrier,
                rehabdiagnosis,
                rehabrecs,
                notes,
                status
            };

            Object.entries(currentValues).forEach(([key, value]) => {
                if (nonRequiredFields.includes(key)) {
                    if (value === '' || value === null || value === undefined) {
                        formData.append(key, null);
                    } else if (value !== consults[key]) {
                        formData.append(key, value);
                    }
                } else if (value && value !== consults[key]) {
                    formData.append(key, value);
                }
            });
        }

        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/consults/add-or-update-consult`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                },
                body: formData.toString(),
            });

            if (response.ok) {
                toast.success('Added Successfully');
                setTimeout(() => {
                    navigate('/consults-tracking-table');
                }, 2000);

            } else {
                toast.error('Operation failed');
            }
        } catch (error) {
            toast.error('An error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '6rem', alignItems: 'center' }} className="UtilitiesMainContainer whiteContainerWide">
            <div className="NavigationContainer">
                <BackIcon onClick={() => { navigate(-1) }} className="back-button" />
                {mode !== 'add' &&
                    <div className="toggle-container">
                        <div className="toggle" onClick={() => setSubMode(subMode === 'view' ? 'edit' : 'view')}>
                            <div className={`toggle-slider ${subMode === 'edit' ? 'active' : ''}`}></div>
                        </div>
                        <span>{subMode === 'view' ? 'View' : 'Edit'} Mode</span>
                    </div>}
            </div>
            <div className={styles.consultsDetailsContent}>
                <h3 className={styles.consultDetailsHeadings}>Consult Details</h3>
                <div className={styles.columnContainer}>

                    <div className={styles.inputWrapper}>

                        <label htmlFor="hospitalFacilityName">Hospital Facility Name<span className={styles.required}>*</span></label>
                        <select
                            id="hospitalFacilityName"
                            className={[styles.consultDetailsSelectField, styles.select].join(' ')}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={hospital_id}
                            onChange={(e) => handleConsultOnChange('hospital_id', e.target.value)}
                        >
                            <option value="">Select Hospital Facility Name</option>
                            {
                                hospitals.map((hospital, index) => (
                                    <option key={hospital?.id} value={hospital?.id}>
                                        {hospital?.hospital}
                                    </option>
                                ))
                            }
                        </select>

                        <label htmlFor="admitDate">Owning Provider<span className={styles.required}>*</span></label>
                        <select
                            className={styles.consultDetailsSelectField}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={owningProvider}
                            onChange={(e) => handleConsultOnChange('owningProvider', e.target.value)}
                        >
                            <option value="">Select Owning Provider</option>
                            {authorizedProviders.map(provider => {
                                const fullName = formatProviderName(provider);
                                return (
                                    <option
                                        key={provider.id}
                                        value={provider.id}
                                    >
                                        {fullName}
                                    </option>
                                );
                            })}
                        </select>

                        <label htmlFor="firstName">First Name<span className={styles.required}>*</span></label>
                        <input
                            id="firstName"
                            className={styles.consultDetailsInput}
                            placeholder="First Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={firstname ? firstname.toString().charAt(0).toUpperCase() + firstname.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handleConsultOnChange('firstname', e.target.value)}
                        />

                        <label htmlFor="lastName">Last Name<span className={styles.required}>*</span></label>
                        <input
                            id="lastName"
                            className={styles.consultDetailsInput}
                            placeholder="Last Name"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={lastname ? lastname.toString().charAt(0).toUpperCase() + lastname.toString().toLowerCase().slice(1) : ''}
                            onChange={(e) => handleConsultOnChange('lastname', e.target.value)}
                        />

                        <label htmlFor="dob">Date of Birth</label>
                        <input
                            type="date"
                            value={dob && isValidDate(dob) ? handleDates(dob, mode, subMode) : ''}
                            onChange={(date) => handleConsultOnChange('dob', date.target.value)}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            mode={mode}
                            subMode={subMode}
                            placeholder="Date of Birth"
                            required={true}
                            className={styles.consultDetailsDateField}
                        />

                        <label htmlFor="dateRequested">Date Requested<span className={styles.required}>*</span></label>
                        <input
                            type="date"
                            value={daterequested && isValidDate(daterequested) ? handleDates(daterequested, mode, subMode) : ''}
                            onChange={(date) => handleConsultOnChange('daterequested', date.target.value)}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            mode={mode}
                            subMode={subMode}
                            placeholder="Date Requested"
                            required={true}
                            className={styles.consultDetailsDateField}
                        />

                        <label htmlFor="timeRequested">Time Requested<span className={styles.required}>*</span></label>
                        <CustomDatePicker
                            id="timeRequested"
                            placeholder="Time Requested"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            className={styles.consultDetailsInput}
                            required
                            value={timerequested}
                            onChange={(time) => handleConsultOnChange('timerequested', time)}
                            type="time"
                            mode={mode}
                            subMode={subMode}
                        />

                    </div>

                    <div className={styles.inputWrapper}>

                        <label htmlFor="roomNumber">Room</label>
                        <input
                            id="roomNumber"
                            className={styles.consultDetailsInput}
                            placeholder="Room Number"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={roomnumber ? roomnumber : ''}
                            onChange={(e) => handleConsultOnChange('roomnumber', e.target.value)}
                        />

                        <label htmlFor="visitDate">Visit Date</label>
                        <input
                            type="date"
                            value={visitdate || ''}
                            onChange={(date) => {
                                const newValue = date.target.value || null;
                                handleConsultOnChange('visitdate', newValue);
                            }}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            mode={mode}
                            subMode={subMode}
                            placeholder="Visit Date"
                            className={styles.consultDetailsDateField}
                        />

                        <label htmlFor="followupDate">Follow up Date</label>
                        <input
                            type="date"
                            value={followupdate || ''}
                            onChange={(date) => {
                                const newValue = date.target.value || null;
                                handleConsultOnChange('followupdate', newValue);
                            }}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            mode={mode}
                            subMode={subMode}
                            placeholder="Follow up Date"
                            className={styles.consultDetailsDateField}
                        />

                        <label htmlFor="insuranceCarrier">Insurance Carrier</label>
                        <input
                            id="insuranceCarrier"
                            className={styles.consultDetailsInput}
                            placeholder="Insurance Carrier"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={insurancecarrier ? insurancecarrier : ''}
                            onChange={(e) => handleConsultOnChange('insurancecarrier', e.target.value)}
                        />

                        <label htmlFor="rehabDiagnosis">Rehab Diagnosis</label>
                        <input
                            id="rehabDiagnosis"
                            className={styles.consultDetailsInput}
                            placeholder="Rehab Diagnosis"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={rehabdiagnosis ? rehabdiagnosis : ''}
                            onChange={(e) => handleConsultOnChange('rehabdiagnosis', e.target.value)}
                        />

                        <label htmlFor="rehabRecs">Rehab Recs</label>
                        <select
                            id="rehabRecs"
                            className={styles.consultDetailsSelectField}
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            value={rehabrecs ? rehabrecs : ''}
                            onChange={(e) => handleConsultOnChange('rehabrecs', e.target.value)}
                        >
                            <option value="">Select Rehab Recs</option>
                            <option value="IRF">IRF</option>
                            <option value="SNF">SNF</option>
                            <option value="HH">HH</option>
                            <option value="Other">Other</option>
                        </select>

                        <label htmlFor="notes">Notes</label>
                        <input
                            id="notes"
                            className={styles.consultDetailsInput}
                            placeholder="Notes"
                            type="text"
                            disabled={(mode === 'view&edit' && subMode === 'view')}
                            required
                            value={notes ? notes : ''}
                            onChange={(e) => handleConsultOnChange('notes', e.target.value)}
                        />
                    </div>

                </div>

                <h3 className={styles.consultDetailsHeadings}>Status<span className={styles.required}>*</span></h3>
                <div style={{ width: '100%' }} className={styles.customOptions}>
                    <div style={{ width: '50%' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handleConsultOnChange('status', 'open') }} className={`${styles.customInactiveButton} ${status === 'open' ? styles.activeButton : ''}`}>Open</div>
                    <div style={{ width: '50%' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handleConsultOnChange('status', 'resolved') }} className={`${styles.customInactiveButton} ${status === 'resolved' ? styles.activeButton : ''}`}>Resolved</div>
                </div>
                {
                    mode === 'add' &&
                    <div className={styles.checkboxContainer}>
                        <input type='checkbox' onClick={(e) => setSmsAlert(!smsAlert)} checked={smsAlert} />
                        <p>Send SMS Alert</p>
                    </div>
                }
                {
                    loading ?
                        <div className='mt2'><Loader /></div>
                        :
                        (mode === 'add' || subMode === 'edit') &&
                        (
                            <div onClick={() => submitForm()} className={styles.rectangleBtn}>
                                Save
                            </div>
                        )
                }
            </div>
        </div >
    )
}

export default ConsultDetails
