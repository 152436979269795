import React, { useEffect, useState } from 'react'
import '../../assets/css/DashboardStats.css'
import { ReactComponent as ActiveIcon } from '../../assets/icons/active-patient.svg'
import { ReactComponent as InpatientIcon } from '../../assets/icons/inpatient.svg'
import { ReactComponent as ConsultIcon } from '../../assets/icons/consult.svg'
import { fetchDashboardStats } from '../../helpers/dashboard-stats'
import '../../App.css';


const DashboardStats = () => {
    const [active, setActive] = useState('');
    const [inpatient, setInpatient] = useState('');
    const [consult, setConsult] = useState('');

    useEffect(() => {
        getDashboardStats();
    }, [])

    const getDashboardStats = async () => {
        try {
            const data = await fetchDashboardStats();
            setActive(data.activePatients);
            setInpatient(data.inpatient);
            setConsult(data.consult);
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    return (
        <div className='whiteContainerWide dashboard-container'>
            <div className='stat-main'>
                <div className='dash-icon'><ActiveIcon height={50} width={50} /></div>
                <div className='stat-details'>
                    <p className='stat-info'>Active</p>
                    <p className='stat-count'>{active}</p>
                </div>
            </div>

            <div className="border"></div>

            <div className='stat-main'>
                <div className='dash-icon'><InpatientIcon height={25} width={50} /></div>
                <div className='stat-details'>
                    <p className='stat-info'>Inpatient</p>
                    <p className='stat-count'>{inpatient}</p>
                </div>
            </div>

            <div className="border"></div>

            <div className='stat-main'>
                <div className='dash-icon'><ConsultIcon height={50} width={50} /></div>
                <div className='stat-details'>
                    <p className='stat-info'>Consult</p>
                    <p className='stat-count'>{consult}</p>
                </div>
            </div>
        </div>
    )
}

export default DashboardStats
