import React, { useState, useEffect, useCallback } from 'react';
import styles from '../../assets/css/Patient.module.css'
import s2 from '../../assets/css/Admissions.module.css';
import { convertToCalendarSpecificDate, isValidDate, handleDates, getTokenFromLocalStorage, fetchPatientDiagnoses, saveDiagnosis, formatProviderName, authorizedTitles } from '../../helpers';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg'
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import axios from 'axios';
import Loader from '../reusable/Loader';
import { toast } from 'react-toastify';
import Diagnosis from '../reusable/Diagnosis';

const AdmissionDetails = ({ patient, subMode, mode, onBack }) => {

    const [admitdate, setAdmitDate] = useState(patient && patient.admitdate ? convertToCalendarSpecificDate(patient.admitdate) : null)
    const [room, setRoom] = useState(patient && patient.room ? patient.room : null)
    const [location, setLocation] = useState(patient && patient.hospital_id ? patient?.hospital_id : null)
    const [dischargedate, setDischargeDate] = useState(patient && patient.dischargedate ? convertToCalendarSpecificDate(patient.dischargedate) : null)
    const [visittype, setVisitType] = useState(patient && patient.visittype ? patient.visittype : null)
    const [facesheet, setFacesheet] = useState(null);
    const [facesheetalias, setFacesheetAlias] = useState(patient && patient.facesheetalias ? patient.facesheetalias : null)
    const [owningProvider, setOwningProvider] = useState(patient && patient.owning_provider_id ? patient.owning_provider_id : null)
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
    const [loading, setLoading] = useState(false);
    const [admission_id, setAdmissionId] = useState(patient.id ? patient.id : null);
    const [authorizedProviders, setAuthorizedProviders] = useState([]);
    const [hospitals, setHospitals] = useState([]);
    const [loadingFacesheet, setLoadingFacesheet] = useState(false);

    const fetchAuthorizedProviders = useCallback(async () => {
        try {
            const requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                }
            };
            const [userResponse, providersResponse, hospitalsResponse] = await Promise.all([
                fetch(`${process.env.REACT_APP_API_URL}/profile/user-details`, requestOptions),
                fetch(`${process.env.REACT_APP_API_URL}/facilities/authorized-providers`, requestOptions),
                fetch(`${process.env.REACT_APP_API_URL}/facilities/hospitals`, requestOptions)
            ]);

            const userData = await userResponse.json();
            const providers = await providersResponse.json();
            const hospitals = await hospitalsResponse.json();

            if (providersResponse.ok && userResponse.ok && hospitalsResponse?.ok) {
                setAuthorizedProviders(providers);
                setHospitals(hospitals);
                if (mode === "add") {
                    if (authorizedTitles.includes(userData.title)) {
                        setOwningProvider(userData.id);
                        handlePatientOnChange('owning_provider_id', userData.id);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchAuthorizedProviders();
    }, [fetchAuthorizedProviders]);

    useEffect(() => {
        if (admission_id && mode !== 'add') {
            fetchPatientDiagnoses(admission_id).then(diagnoses => {
                setSelectedDiagnosis(diagnoses);
            })
        }
    }, []);

    const ViewFacesheet = () => {
        setLoadingFacesheet(true);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        };
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ id: admission_id, filealias: facesheetalias })
        };

        fetch(`${process.env.REACT_APP_API_URL}/documents/view-file`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setTimeout(() => {
                    window.open(data.signedUrl, '_blank');
                    setLoadingFacesheet(false);
                }, 100)
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Network error');
                setLoadingFacesheet(false);
            });
    }

    const DeleteFacesheet = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        };
        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                id: admission_id,
                facesheetalias
            })
        };
        const result = await fetch(`${process.env.REACT_APP_API_URL}/documents/delete-facesheet`, requestOptions);
        if (result.status === 200) {
            toast.success('Facesheet deleted successfully');
            setFacesheetAlias(null);
            setFacesheet(null);
            setSelectedFileName('');
            patient.facesheetalias = null;
        } else {
            toast.error('Error deleting facesheet');
        }
    }

    const handlePatientOnChange = (patient_key, value) => {

        if (patient_key === 'room') {
            setRoom(value);
            patient.room = value;
        }

        if (patient_key === 'location') {
            setLocation(value);
            patient.hospital_id = value;
        }

        if (patient_key === 'admitdate') {
            setAdmitDate(value);
            value = handleDates(admitdate, mode, subMode);
            patient.admitdate = value;
        }

        if (patient_key === 'dischargedate') {
            setDischargeDate(value);
            value = handleDates(dischargedate, mode, subMode);
            patient.dischargedate = value;
        }

        if (patient_key === 'visittype') {
            setVisitType(value);
            patient.visittype = value;
        }

        if (patient_key === 'owning_provider_id') {
            setOwningProvider(value);
            patient.owning_provider_id = value;
        }

    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

        if (!allowedExtensions.exec(file.name)) {
            toast.error('Invalid image file');
        } else {
            setFacesheet(file);
            setSelectedFileName(file.name);
        }
    };

    const uploadFacesheet = async (WillUploadFaceSheet, file) => {
        const formData = new FormData();
        formData.append('admission_page', true);
        formData.append('admitdate', admitdate);
        formData.append('hospital_id', location);
        formData.append('owning_provider_id', owningProvider);
        formData.append('visittype', visittype);
        formData.append('dischargedate', dischargedate);
        formData.append('room', room);
        formData.append('facesheetalias', facesheetalias);

        if (WillUploadFaceSheet === "True" && file) {
            formData.append('facesheet', file);
        }

        if (admission_id) {
            formData.append('admission_id', admission_id);
        }
        else {
            formData.append('patient_id', patient.patient_id);
        }
        formData.append('WillUploadFaceSheet', WillUploadFaceSheet);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/documents/upload-facesheet`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}` // Include the JWT token in the Authorization header
                }
            });
            WillUploadFaceSheet === "True" && (toast.success('Facesheet uploaded successfully'))
            patient.facesheetalias = response.data.facesheetalias;
            if (response.data.facesheetalias) {
                setFacesheetAlias(response.data.facesheetalias);
            }
            setAdmissionId(response.data.id);
            return response.data;
        } catch (error) {
            setLoading(false);
            toast.error(error?.response?.data?.message || 'An error occurred while uploading the facesheet.');
            console.error(error);
        }
        finally {
            setLoading(false);
        }
    };

    const savePatientDetails = async (e) => {
        e.preventDefault();
        if (!owningProvider) {
            toast.error('Please select owning provider');
            return;
        }

        if (!location) {
            toast.error('Please select Place of Service');
            return;
        }

        if (!visittype) {
            toast.error('Please select visit type');
            return;
        }

        try {
            setLoading(true);
            let isUploadFacesheet = (facesheet && selectedFileName) ? "True" : "False";
            const res = await uploadFacesheet(isUploadFacesheet, facesheet);

            const result = await saveDiagnosis(res.id, selectedDiagnosis);
            if (result) {
                toast.success('Diagnoses Saved Successfully!');
            }
            toast.success('Admission details saved successfully');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error)
            toast.error('Failed to save admission details');
        }
    }



    const handleSelectedDiagnosis = (diagArray) => {
        patient.selectedDiagnosis = diagArray;
        setSelectedDiagnosis(diagArray);
    };


    return (
        <>
            <div className={s2.backButtonContainer}>
                <div className="NavigationContainer">
                    <BackIcon onClick={onBack} className="back-button" />
                </div>
            </div>
            <div className={`${styles.patientDetailsContent} ${s2.admissionDetailsContent}`}>
                <form onSubmit={savePatientDetails} className={styles.patientDetailsSubContent}>
                    <h3 className={styles.patientDetailsHeadings}>Admission Details</h3>
                    <div className={styles.columnContainer}>
                        <div className={styles.inputWrapper}>

                            <label htmlFor="admitDate">Admit Date<span className={styles.required}>*</span></label>
                            <input
                                id="admitDate"
                                className={styles.patientDetailsDateField}
                                placeholder="Admit Date"
                                type="date"
                                disabled={(mode === 'view&edit' && subMode === 'view')}
                                required
                                value={admitdate && isValidDate(admitdate) ? handleDates(admitdate, mode, subMode) : ''}
                                onChange={(e) => handlePatientOnChange('admitdate', e.target.value)}
                            />

                            <label htmlFor="placeOfService">Place of Service<span className={styles.required}>*</span></label>
                            <select
                                id="placeOfService"
                                className={styles.patientDetailsSelectField}
                                disabled={(mode === 'view&edit' && subMode === 'view')}
                                required
                                value={location ? location : ''}
                                onChange={(e) => handlePatientOnChange('location', e.target.value)}
                            >
                                <option value="">Select Place of Service</option>
                                {hospitals?.map(hospital => (
                                    <option
                                        key={hospital?.id}
                                        value={hospital?.id}
                                    >
                                        {hospital?.hospital}
                                    </option>
                                ))}
                            </select>


                            <label htmlFor="owningProvider">Owning Provider<span className={styles.required}>*</span></label>
                            <select
                                className={styles.patientDetailsSelectField}
                                disabled={(mode === 'view&edit' && subMode === 'view')}
                                value={owningProvider}
                                onChange={(e) => handlePatientOnChange('owning_provider_id', e.target.value)}
                            >
                                <option value="">Select Owning Provider</option>
                                {authorizedProviders.map(provider => {
                                    const fullName = formatProviderName(provider);
                                    return (
                                        <option
                                            key={provider.id}
                                            value={provider.id}
                                        >
                                            {fullName}
                                        </option>
                                    );
                                })}
                            </select>

                        </div>

                        <div className={styles.inputWrapper}>
                            <label htmlFor="room">Room</label>
                            <input
                                id="room"
                                className={styles.patientDetailsInputField}
                                placeholder="room"
                                type="text"
                                disabled={(mode === 'view&edit' && subMode === 'view')}
                                value={room ? room : ''}
                                onChange={(e) => handlePatientOnChange('room', e.target.value)}
                            />


                            <label htmlFor="dischargeDate">Discharge Date</label>
                            <input
                                id="dischargeDate"
                                className={styles.patientDetailsDateField}
                                placeholder="Discharge Date"
                                type="date"
                                disabled={(mode === 'view&edit' && subMode === 'view')}
                                value={dischargedate && isValidDate(dischargedate) ? handleDates(dischargedate, mode, subMode) : ''}
                                onChange={(e) => handlePatientOnChange('dischargedate', e.target.value)}
                            />

                            <label htmlFor="facesheet">Facesheet</label>
                            {facesheetalias && facesheetalias !== 'null' ?
                                <div className={styles.facesheetRow}>
                                    Facesheet
                                    <div className={styles.actionBtns}>
                                        {subMode === 'view' && (
                                            loadingFacesheet ?
                                                <Loader width={15} height={15} /> :
                                                <div onClick={() => ViewFacesheet()}><Eye fill={'var(--purple)'} width={15} height={15} /></div>
                                        )}
                                        {subMode === 'edit' && <DeleteIcon onClick={() => DeleteFacesheet()} style={{ cursor: 'pointer' }} width={20} height={20} fill={'#E52B50'} />}
                                    </div>
                                </div>
                                : loading ? <div style={{ textAlign: 'center' }} >Please wait...</div> : <>
                                    {subMode === 'edit' && <div style={{ textAlign: 'center' }} className="file-input-container">
                                        <input
                                            type="file"
                                            className="file-input"
                                            id="file"
                                            accept="image/*,application/pdf"
                                            onChange={handleFileChange} />

                                        <label htmlFor="file" className="file-input-label">
                                            {selectedFileName ? selectedFileName.slice(0, 15) : 'Choose File'}
                                        </label>
                                    </div>}
                                </>}
                        </div>
                    </div>

                    <h3 style={{ marginBottom: (mode === 'view&edit' && subMode === 'view') ? '-6px' : '6px' }} className={styles.patientDetailsHeadings}>Diagnosis</h3>
                    <div className={styles.diagnosisContainer}>
                        <Diagnosis subMode={subMode} mode={mode} DiagnosisArray={selectedDiagnosis} handleSelectedDiagnosis={handleSelectedDiagnosis} />
                    </div>

                    <h3 className={styles.patientDetailsHeadings}>Visit Type<span className={styles.required}>*</span></h3>
                    <div style={{ width: '100%' }} className={styles.visitTypeOptions}>
                        <div style={{ width: '50%' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('visittype', 'inpatient') }} className={`${styles.visitTypeInactiveButton} ${visittype === 'inpatient' ? styles.activeButton : ''}`}>Inpatient</div>
                        <div style={{ width: '50%' }} onClick={() => { (mode === 'add' || subMode === 'edit') && handlePatientOnChange('visittype', 'consult') }} className={`${styles.visitTypeInactiveButton} ${visittype === 'consult' ? styles.activeButton : ''}`}>Consult</div>
                    </div>
                    {loading === false ?
                        ((mode === 'view&edit' && subMode === 'edit') || mode === 'add') &&
                        <div onClick={savePatientDetails} className={styles.rectangleBtn}>
                            Save
                        </div>
                        : <center><div className='mt1'></div><Loader /></center>}
                </form>
            </div >
        </>
    );
};

export default AdmissionDetails;