import React, { useState } from 'react';
import styles from '../../assets/css/Profile.module.css';
import styles2 from "../../assets/css/Signup.module.css";
import Toast from "../reusable/Toast";
import { getTokenFromLocalStorage } from '../../helpers';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';


const ChangePassword = () => {
    const { userData } = useOutletContext();
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };

    const navigate = useNavigate();

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        let errors = {};

        if (!currentPassword) {
            errors.currentPassword = '*Current Password is required';
        }
        if (!password) {
            errors.password = '*Password is required';
        } else if (password.length < 6 || !/\d/.test(password) || !/[a-zA-Z]/.test(password)) {
            errors.password = '*Password must be at least 6 characters long and contain both digits and letters';
        }
        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setLoading(true);
        setErrors({});

        const formDataToSend = new FormData();
        // Add all existing user data
        formDataToSend.append('firstname', userData.firstname);
        formDataToSend.append('lastname', userData.lastname);
        formDataToSend.append('title', userData.title);
        formDataToSend.append('phone', userData.phone);
        formDataToSend.append('street', userData.street);
        formDataToSend.append('city', userData.city);
        formDataToSend.append('state', userData.state);
        formDataToSend.append('zipcode', userData.zipcode);
        formDataToSend.append('division', userData.division);
        // Add password data
        formDataToSend.append('password', password);
        formDataToSend.append('currentPassword', currentPassword);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/user-details`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                },
                body: formDataToSend
            });

            if (response.ok) {
                showSuccessToast('Password updated successfully');
                setCurrentPassword('');
                setPassword('');
                setConfirmPassword('');
            } else {
                const data = await response.json();
                showErrorToast(data.message);
            }
        } catch (error) {
            showErrorToast('Network error occurred');
        } finally {
            setLoading(false);
        }
    };

    const eyeIconStyle = {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        width: '20px',
        height: '20px',
        fill: 'grey'
    };

    const activeEyeIconStyle = {
        ...eyeIconStyle,
        fill: 'var(--purple)'
    };

    const inputWrapperStyle = {
        position: 'relative',
        width: '100%'
    };

    return (
        <>
            <div className="NavigationContainer">
                <div className="back-button-container">
                    <BackIcon onClick={() => navigate(-1)} className="back-button" />
                </div>
            </div>
            <div className={styles.commonOuterProfileContainer}>
                <div className={styles.personalInformationContainer}>
                    <h3 style={{ alignSelf: 'center' }}>Change Password</h3>
                    <form className={styles.securityAndPrivacySignupForm}>
                        <div className={styles2.inputWrapper} style={inputWrapperStyle}>
                            <input
                                className={styles2.inputField}
                                placeholder="Current Password"
                                type={showCurrentPassword ? "text" : "password"}
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)} />
                            <Eye
                                style={showCurrentPassword ? activeEyeIconStyle : eyeIconStyle}
                                onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                            {errors.currentPassword && <div className={styles2.error}>{errors.currentPassword}</div>}
                        </div>

                        <div className={styles2.inputWrapper} style={inputWrapperStyle}>
                            <input
                                className={styles2.inputField}
                                placeholder="New Password"
                                type={showNewPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                            <Eye
                                style={showNewPassword ? activeEyeIconStyle : eyeIconStyle}
                                onClick={() => setShowNewPassword(!showNewPassword)} />
                            {errors.password && <div className={styles2.error}>{errors.password}</div>}
                        </div>

                        <div className={styles2.inputWrapper} style={inputWrapperStyle}>
                            <input
                                className={styles2.inputField}
                                placeholder="Confirm Password"
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)} />
                            <Eye
                                style={showConfirmPassword ? activeEyeIconStyle : eyeIconStyle}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                            {errors.confirmPassword && <div className={styles2.error}>{errors.confirmPassword}</div>}
                        </div>

                        <div className={styles2.inputWrapper}>
                            {loading ?
                                <div className={styles2.loading}>Please wait...</div> :
                                <button onClick={handlePasswordChange} className={styles2.submitButton}>
                                    Update Password
                                </button>}
                        </div>
                    </form>
                </div>
                {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
            </div></>
    );
};

export default ChangePassword;
