import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/VisitCode.module.css'
import styles2 from '../../assets/css/ChargesVisitCodes.module.css'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { fetchSharedVisitUsers, attachSharedVisitsToAdmission } from '../../helpers';
import { ReactComponent as TickPending } from '../../assets/icons/tick_pending.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { toast } from 'react-toastify';

const ChargesSharedVisit = ({ setShowModal, selectedPatients, reRenderPatients }) => {
    const [sharedVisitUsers, setSharedVisitUsers] = useState([]);
    const [selectedSharedVisits, setSelectedSharedVisits] = useState([]);
    
    useEffect(() => {
        handleFetchSharedVisitUsers()
        let SharedVisits = [];
        selectedPatients.map((p) => {
            return p.shared_visits.forEach(sv => {
                SharedVisits.push(sv);
            })
        })
        SharedVisits = SharedVisits.filter((sv, index, self) =>
            index === self.findIndex((t) => t.id === sv.id)
        );

        setSelectedSharedVisits(SharedVisits)
    }, []);

    const handleFetchSharedVisitUsers = async () => {
        try {
            const users = await fetchSharedVisitUsers();
            setSharedVisitUsers(users);
        } catch (error) {
            console.error("Error fetching shared visit users:", error);
        }
    }

    const handleSave = async () => {
        const anyPatientsHaveSharedVisits = selectedPatients.some(patient =>
            patient.shared_visits && patient.shared_visits.length > 0
        );

        if (!anyPatientsHaveSharedVisits && selectedSharedVisits.length === 0) {
            toast.error('Please select at least one shared visit');
            return;
        }

        let ids_array = [];
        if (selectedSharedVisits.length > 0) {
            selectedPatients.forEach((patient, index) => {
                selectedSharedVisits.forEach((sharedvisit, index) => {
                    ids_array.push({
                        patientId: patient.patient_id,
                        sharedVisitId: sharedvisit.id,
                        charges_page_id: patient.charges_page_id,
                        admission_id: patient.id
                    });
                });
                patient.shared_visits = selectedSharedVisits;
            });
        }
        else {
            selectedPatients.forEach((patient, index) => {
                ids_array.push({
                    patientId: patient.patient_id,
                    sharedVisitId: null,
                    charges_page_id: patient.charges_page_id,
                    admission_id: patient.id
                });
                patient.shared_visits = [];
            });
        }
        await attachSharedVisitsToAdmission(ids_array);
        setShowModal(false);
        reRenderPatients(' Shared Visits Updated! ')
    }

    const handleVisitCode = (VisitCode, mode) => {
        setSelectedSharedVisits([VisitCode]);
    };

    return (
        <div className="modal">
            <div className="modalContent">
                <div className="closeModalButton" onClick={() => setShowModal(false)}><Cross fill={'red'} /></div>
                <h3 className={styles2.modalTitle}>Select the Shared Visits :</h3>
                <div className={styles2.container}>
                    <div className={styles.box}>
                        <div className={styles.DiagnosisContainer}>
                            <div className={styles.accordionTitle}>
                                <p>Shared Visits</p>
                            </div>
                            <div className={styles.selectedContainer}>
                                {
                                    sharedVisitUsers.map((user, index) => {
                                        return (
                                            <div className={styles.DiagnosisItem} key={index}>
                                                <div className={styles.DiagnosisItemCode}>{user.name}</div>
                                                <div className={styles.DiagnosisItemDescription}> </div>
                                                <div className={styles.checkmarkIcon}><TickPending onClick={() => handleVisitCode(user, "add")} width={20} height={20} fill={selectedSharedVisits.some((sv) => sv.id === user.id) ? '#5e3ae4' : 'grey'} /></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={styles.DiagnosisContainer}>
                            <div className={styles.accordionTitle}>
                                <p>Selected Shared Visit</p>
                                <div className={styles.checkmarkIcon} style={{ border: '1px solid red', borderRadius: '5px' }}><Trash onClick={() => { setSelectedSharedVisits([]) }} width={20} height={20} fill={'red'} /></div>
                            </div>
                            <div className={styles.selectedContainer}>
                                {
                                    selectedSharedVisits.map((user, index) => {
                                        return (
                                            <div className={styles2.DiagnosisItem} key={index}>
                                                <div className={styles.DiagnosisItemCode}>{user.name}</div>
                                                <div className={styles.DiagnosisItemDescription}> </div>
                                                <div className={styles.checkmarkIcon}></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => handleSave()} className={[styles2.button, styles2.SaveButton].join(' ')}>
                    Save
                </div>
            </div>
        </div>
    )

}
export default ChargesSharedVisit;