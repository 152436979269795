import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Patient.module.css';
import styles1 from '../../assets/css/ChargesTab.module.css';
import styles2 from '../../assets/css/Notes.module.css';
import styles3 from '../../assets/css/PatientList.module.css';
import { ReactComponent as SharedIcon } from '../../assets/icons/charges-shared-visit.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { fetchNotes } from '../../helpers/notes/index.js';
import Note from './Note.js';
import { convertToCustomDate, isValidDate } from '../../helpers/index.js';
import t from '../../assets/css/Table.module.css'
import HoverContent from '../reusable/HoverContent';

const Notes = ({ patient }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 631);
    const [showPatientNote, setShowPatientNote] = useState(false);
    const [currentPatientNote, setCurrentPatientNote] = useState(null);
    const [currentPatient, setCurrentPatient] = useState(null);
    const [notes, setNotes] = useState([]);
    const [isNewPatientNote, setIsNewPatientNote] = useState(false);

    const loadNotes = () => {
        if (patient?.patient_id) {
            fetchNotes(patient.patient_id).then(notes => {
                setNotes(notes);
            });
        }
    };

    useEffect(() => {
        loadNotes();
    }, []);

    const redirectToNotelist = () => {
        setShowPatientNote(false);
        loadNotes();
    }

    const onCellClick = (row) => {
        setCurrentPatientNote(row);
        setShowPatientNote(true);
        setIsNewPatientNote(false);
    };

    const handleNewNoteClick = () => {
        setIsNewPatientNote(true);
        setShowPatientNote(true);
        setCurrentPatient({
            admission_id: patient.id,
            patient_id: patient.patient_id,
            admitdate: patient.admitdate,
            location: null,
            room: null,
            dischargedate: null,
            visittype: null,
            facesheetalias: null,
            owning_provider: null,
            status: 'active'
        });
    };

    const handleBack = () => {
        setShowPatientNote(false);
        setCurrentPatientNote(null);
        setIsNewPatientNote(false);
    };

    return (
        <div className={styles2.container}>
            {showPatientNote ? (
                <Note
                    mode={isNewPatientNote ? 'add' : 'view&edit'}
                    currentPatientNote={currentPatientNote}
                    patient={isNewPatientNote ? currentPatient : null}
                    onBack={handleBack}
                    redirectToNotelist={redirectToNotelist}
                />
            ) : (
                <>
                    <div className={styles2.newAdmissionContainer}>
                        <div onClick={handleNewNoteClick} style={{ gap: '1rem' }} className={[styles1.button, styles1.SharedVisitsBtn].join(' ')}>
                            <SharedIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>New Note</div>
                        </div>
                    </div>
                    <div className={t.container}>
                        <div className={t.tableWrapper}>
                            <table className={`${t.compactTable2} ${t.mobileText}`}>
                                <thead id="tableHeader">
                                    <tr>
                                        <th scope="col">Admit Date</th>
                                        <th scope="col">Date of Service</th>
                                        <th scope="col">Visit Codes</th>
                                        <th scope="col">Diagnoses Codes</th>
                                        <th scope="col">Notes</th>
                                        <th scope="col">Provider</th>
                                        <th scope="col">Timestamp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...notes].reverse().map((row, index) => (
                                        <tr key={index}>
                                            <td>{isValidDate(row.admitdate) && convertToCustomDate(row.admitdate)}</td>
                                            <td>{isValidDate(row?.date_of_service) && convertToCustomDate(row?.date_of_service)}</td>
                                            <td>
                                                {row.visit_codes && row.visit_codes.length > 0 ? (
                                                    <HoverContent
                                                        hoverContent={
                                                            row.visit_codes.map((visitCode, vcIndex) => (
                                                                <div key={vcIndex}>
                                                                    {visitCode.visit_code} - {visitCode.description}
                                                                </div>
                                                            ))
                                                        }
                                                        position="top"
                                                        maxHeight="200px"
                                                    >
                                                        {row.visit_codes.map((r, vcIndex) => (
                                                            <span key={vcIndex}>
                                                                {r.visit_code}
                                                                {(row.visit_codes.length - 1) !== vcIndex && ', '}
                                                            </span>
                                                        ))}
                                                    </HoverContent>
                                                ) : (
                                                    ' - '
                                                )}
                                            </td>
                                            <td>
                                                {row.diagnoses && row.diagnoses.length > 0 ? (
                                                    <HoverContent
                                                        hoverContent={
                                                            row.diagnoses.slice().sort((a, b) => (b?.is_primary ? 1 : 0) - (a?.is_primary ? 1 : 0)).map((diagnosis, diagIndex) => (
                                                                <div key={diagIndex}>
                                                                    {diagnosis?.is_primary ? <span className={styles.required}>*</span> : null}
                                                                    {diagnosis.code} - {diagnosis.description}
                                                                </div>
                                                            ))
                                                        }
                                                        position="top"
                                                        maxHeight="200px"
                                                    >
                                                        {row.diagnoses.slice().sort((a, b) => (b?.is_primary ? 1 : 0) - (a?.is_primary ? 1 : 0)).map((r, diagIndex) => (
                                                            <span key={diagIndex}>
                                                                {r?.is_primary ? <span className={styles.required}>*</span> : null}
                                                                {r.code}
                                                                {(row.diagnoses.length - 1) !== diagIndex && ', '}
                                                            </span>
                                                        ))}
                                                    </HoverContent>
                                                ) : (
                                                    ' - '
                                                )}
                                            </td>
                                            <td>
                                                <div onClick={() => onCellClick(row)} className={styles2.viewBtn}>
                                                    View
                                                </div>
                                            </td>
                                            <td>{row.provider_fullname}</td>
                                            <td>
                                                {isValidDate(row.created_at) && new Date(row.created_at).toLocaleString('en-US', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: false
                                                })}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={t.totalCounter}>
                            Total Notes: {notes.length}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Notes;