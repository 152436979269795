import React, { useState } from 'react';
import styles from "../../assets/css/DiagnosisCode.module.css";
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { toast } from 'react-toastify';
import { getTokenFromLocalStorage } from '../../helpers';

function DiagnosisCode({ favoritesDiagnosis, selectedDiagnosisCodes, handleChange, updateSelectedCode }) {
  const [notesInput, setNotesInput] = useState('');

  const handleNotesChange = (e) => {
    setNotesInput(e.target.value);
  };

  const handleConvertNotes = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/notes/convert-notes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getTokenFromLocalStorage()
        },
        body: JSON.stringify({ description: notesInput })
      });

      if (response.ok) {
        const data = await response.json();
        data.forEach(item => handleChange("diagnosisCodes", item));

        toast.success('Notes converted and codes added successfully');
      } else {
        toast.error('Failed to convert notes');
      }
    } catch (error) {
      toast.error('An error occurred while converting notes');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.column}>
          <div className={styles.listContainer}>
            <h4 className={styles.title}>Convert Notes to ICD-10</h4>
            <textarea
              className={styles.notesTextArea}
              placeholder="Paste your notes here or type for diagnosis..."
              value={notesInput}
              onChange={handleNotesChange}
            ></textarea>
            <button
              type="button"
              className={styles.convertButton}
              onClick={handleConvertNotes}
            >
              Convert Notes
            </button>
          </div>
        </div>

        <div className={styles.DiagnosisBlock}>
          <div className={styles.accordionTitle}>
            <p>Selected Diagnosis</p>
          </div>
          <div className={styles.selectedContainer}>
            {
              selectedDiagnosisCodes?.slice().sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((item, index) => (
                <div className={styles.DiagnosisItem} key={index}>
                  <div className={styles.DiagnosisItemCode}>{item?.is_primary ? <div className={styles.required}>*</div> : null}
                    {item.code}: {item.description}</div>
                  <div className={styles.checkmarkIcon}><Trash onClick={() => updateSelectedCode("diagnosisCodes", item)} width={12} height={12} fill={'#E52B50'} /></div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiagnosisCode;
