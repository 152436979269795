import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from '../../assets/css/NoteForm.module.css';
import Loader from '../reusable/Loader';
import VisitCode from './VisitCode';
import DiagnosisCode from './DiagnosisCode';
import { getTokenFromLocalStorage, convertToCalendarSpecificDate, addPatientIdToChargesPage, updatePatientOrder, fetchSharedVisitUsers, attachSharedVisitsToAdmission, fetchVisitCodes, updatePatientCharges } from '../../helpers';

function NoteForm({ patient, mode, currentPatientNote, macroMateText, redirectToNotelist }) {
  const [admitDate, setAdmitDate] = useState(currentPatientNote?.admitdate && mode === "view&edit" ? convertToCalendarSpecificDate(currentPatientNote?.admitdate) : "");
  const [selectedVisitCodes, setSelectedVisitCodes] = useState(currentPatientNote?.visit_codes && mode === "view&edit" ? currentPatientNote?.visit_codes : []);
  const [visitCodes, setVisitCodes] = useState("");
  const [allAdmitDate, setAllAdmitDate] = useState([]);
  const [sharedVisitUsers, setSharedVisitUsers] = useState([]);
  const [favoritesDiagnosis, setFavoritesDiagnosis] = useState([]);
  const [selectedDiagnosisCodes, setSelectedDiagnosisCodes] = useState(currentPatientNote?.diagnoses && mode === "view&edit" ? currentPatientNote?.diagnoses : []);
  const [addToCharges, setAddToCharges] = useState(currentPatientNote?.add_to_charges && mode === "view&edit" ? currentPatientNote?.add_to_charges : true);
  const [loading, setIsLoading] = useState(false);
  const [selectedSharedVisit, setSelectedSharedVisit] = useState(currentPatientNote?.shared_visits && mode === "view&edit" ? currentPatientNote?.shared_visits[0]?.id : "");
  const [dateOfService, setDateOfService] = useState(currentPatientNote?.date_of_service && mode === "view&edit" ? convertToCalendarSpecificDate(currentPatientNote?.date_of_service) : convertToCalendarSpecificDate(new Date().toISOString()));
  const [admissionId, setAdmissionId] = useState(mode === 'add' ? null : currentPatientNote?.admission_id);

  const handleFetchSharedVisitUsers = async () => {
    try {
      const users = await fetchSharedVisitUsers();
      setSharedVisitUsers(users);
    } catch (error) {
      console.error("Error fetching shared visit users:", error);
    }
  }

  const handleFetchVisitCodes = async () => {
    try {
      const visitCodes = await fetchVisitCodes();
      setVisitCodes(visitCodes);
    } catch (error) {
      console.error('Error fetching visit codes:', error);
    }
  };

  const fetchFavoriteDiagnosis = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/diagnoses/favorite-diagnoses`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getTokenFromLocalStorage()
      },
    });
    if (response.ok) {
      const data = await response.json();
      setFavoritesDiagnosis(data);
    } else {
      console.error('Error:', response.status);
    }
  }

  async function fetchLatestAdmissionDetail(patientId) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admission/latest-admission?patient_id=${patientId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      if (!response.ok) {
        setAdmitDate(patient.admitdate ? convertToCalendarSpecificDate(patient?.admitdate) : "")
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setAdmitDate(convertToCalendarSpecificDate(data.admitdate));
      setSelectedDiagnosisCodes(data.diagnoses);
      setAdmissionId(data.admission_id);
    } catch (error) {
      setAdmitDate(patient.admitdate ? convertToCalendarSpecificDate(patient?.admitdate) : "")
      console.error("Failed to fetch latest admission:", error.message);
    }
  }

  async function getalladmitdate(patientId) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admission/admissions/admit-dates?patient_id=${patientId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getTokenFromLocalStorage()}`
        }
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setAllAdmitDate(data.map(date => convertToCalendarSpecificDate(date)));
    }
    catch (error) {
      console.error("Failed to fetch admit dates:", error.message);
    }
  }

  useEffect(() => {
    handleFetchVisitCodes();
    fetchFavoriteDiagnosis();
    handleFetchSharedVisitUsers();
    if (mode === 'add') {
      fetchLatestAdmissionDetail(patient.patient_id);
    }
    getalladmitdate(patient?.patient_id || currentPatientNote?.patient_id);
  }, []);

  const updateSelectedCode = (field, value) => {
    if (field === "visitCodes") {
      setSelectedVisitCodes((prev) =>
        prev?.filter((item) => item.id !== value.id
        ));
    }
    if (field === "diagnosisCodes") {
      setSelectedDiagnosisCodes((prev) =>
        prev?.filter((item) => item.id !== value.id
        ));
    }
  };

  const handleChange = (field, value) => {
    if (field === "admitDate") setAdmitDate(value);
    if (field === "dateOfService") setDateOfService(value);
    if (field === "visitCodes") {
      setSelectedVisitCodes((prev) => {
        const exists = prev?.some((item) => item.id === value.id);
        return exists ? prev : [...prev, value];
      });
    }
    if (field === "diagnosisCodes") {
      setSelectedDiagnosisCodes((prev) => {
        const exists = prev?.some((item) => item.id === value.id);
        return exists ? prev : [...prev, value];
      });
    }
    if (field === "sharedVisit") setSelectedSharedVisit(value);
    if (field === "addToCharges") setAddToCharges(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!admitDate) {
      toast.error("Please select an admit date.");
      setIsLoading(false);
      return;
    }
    if (selectedVisitCodes.length === 0) {
      toast.error("Please select visit codes.");
      setIsLoading(false);
      return;
    }

    try {
      let notesData;

      if (mode === 'add') {
        const notesResponse = await fetch(`${process.env.REACT_APP_API_URL}/notes/patient-notes`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${getTokenFromLocalStorage()}` },
          body: JSON.stringify({ patient_id: patient.patient_id, admitdate: admitDate, date_of_service: dateOfService, macro_mate_clinical_text: macroMateText })
        });

        if (!notesResponse.ok) {
          throw new Error("Failed to create note.");
        }

        notesData = await notesResponse.json();
      } else if (mode === 'view&edit') {
        const updateResponse = await fetch(`${process.env.REACT_APP_API_URL}/notes/patient-notes/${currentPatientNote.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenFromLocalStorage()}`
          },
          body: JSON.stringify({ date_of_service: dateOfService, admitdate: admitDate, macro_mate_clinical_text: macroMateText })
        });

        notesData = await updateResponse.json();

        if (!updateResponse.ok) {
          throw new Error("Failed to update note.");
        }

        toast.success("Note Updated Successfully!");
      }

      const chargesResponse = await addPatientIdToChargesPage(admissionId);
      const chargesData = await chargesResponse.json();

      const apiCalls = [];

      if (selectedSharedVisit !== "") {
        apiCalls.push(
          attachSharedVisitsToAdmission([
            {
              admission_id: admissionId,
              sharedVisitId: [Number(selectedSharedVisit)],
              charges_page_id: chargesData.charges_page_id
            }
          ])
        )
      }

      if (mode === 'add') {
        apiCalls.push(updatePatientOrder([patient.patient_id]));
      }

      await Promise.all([...apiCalls,
      updatePatientCharges(
        selectedVisitCodes.map((code) => ({
          chargesId: Number(code.id),
          charges_page_id: chargesData.charges_page_id,
          admission_id: admissionId,
        }))
      ),

      fetch(`${process.env.REACT_APP_API_URL}/diagnoses/patient-diagnoses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${getTokenFromLocalStorage()}` },
        body: JSON.stringify({ admission_id: admissionId, selectedDiagnosis: selectedDiagnosisCodes })
      }),

      fetch(`${process.env.REACT_APP_API_URL}/notes/patient-notes-relationships`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${getTokenFromLocalStorage()}` },
        body: JSON.stringify({ patient_note_id: notesData.patient_notes_id, diagnoses: selectedDiagnosisCodes, charges: selectedVisitCodes.map((d) => d.id), shared_visits: selectedSharedVisit ? [Number(selectedSharedVisit)] : [] })
      })
      ])

      toast.success(mode === 'add' ? "Note Added Successfully!" : "Note Updated Successfully!");
      redirectToNotelist();
    } catch (error) {
      toast.error("An error occurred while submitting.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.columnContainer}>
      <div className={styles.inputWrapper}>
        <label htmlFor="admitDate">Admit Date <span className={styles.required}>*</span></label>
        <select
          id="admitDate"
          className={styles.selectInputField}
          value={admitDate}
          onChange={(e) => handleChange("admitDate", e.target.value)}
          required
        >
          <option className={styles.option} value="">Select</option>
          {allAdmitDate.map((admitDate) => (
            <option key={admitDate} value={admitDate}>
              {admitDate}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.inputWrapper}>
        <label htmlFor="dateOfService">Date of Service <span className={styles.required}>*</span></label>
        <input
          id="dateOfService"
          type="date"
          className={styles.dateField}
          value={dateOfService}
          onChange={(e) => handleChange("dateOfService", e.target.value)}
          required
        />
      </div>

      <VisitCode visitCodes={visitCodes} handleChange={handleChange} selectedVisitCodes={selectedVisitCodes} updateSelectedCode={updateSelectedCode} />
      <DiagnosisCode favoritesDiagnosis={favoritesDiagnosis} handleChange={handleChange} selectedDiagnosisCodes={selectedDiagnosisCodes} updateSelectedCode={updateSelectedCode} />

      <div className={styles.inputWrapper}>
        <label htmlFor="SharedVisit">Shared Visits</label>
        <select
          id="SharedVisit"
          className={styles.selectInputField}
          value={selectedSharedVisit}
          onChange={(e) => handleChange("sharedVisit", e.target.value)}
        >
          <option className={styles.option} value="">Select</option>
          {sharedVisitUsers.map((user, index) => (
            <option key={index} value={user.id}>{user.name}</option>
          ))}
        </select>
      </div>

      <div className={styles.checkboxContainer}>
        <span>Add to Charges</span>
        <input id="addToCharges" type="checkbox" checked={addToCharges} onChange={(e) => handleChange("addToCharges", e.target.checked)} />
      </div>

      {loading ? (
        <div style={{ margin: '3px' }}><Loader /></div>
      ) : (
        <button type="submit" className={styles.rectangleBtn} >{mode === "view&edit" ? "Update" : "Save"}</button>
      )}
    </form>
  )
}

export default NoteForm