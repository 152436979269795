import React from 'react'
import styles from '../../assets/css/ChargesTab.module.css'
import { convertToCustomDate, isValidDate, capitalizeNames } from '../../helpers'

const AddPatientTableRow = ({ patient, isSelected, onSelectChange }) => {

    const handleCheckboxChange = (event) => {
        onSelectChange(patient.id, event.target.checked);
    };

    return (
        <>
            <tr>
                <td>
                    <input className={styles.checkbox} type='checkbox' style={{ height: '15px', width: '15px' }} onChange={handleCheckboxChange} checked={isSelected} />
                </td>
                <td>{capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}</td>
                <td>{isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)}</td>
                <td>{patient.hospital_abbreviation}</td>
                <td>{patient.visittype}</td>
                <td>{patient.status}</td>
            </tr>
        </>
    )
}

export default AddPatientTableRow
