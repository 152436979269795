import React, { useState } from 'react'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import styles from '../../assets/css/ChargesTab.module.css'
import { capitalizeNames, convertToCustomDate, isValidDate, ViewFacesheet } from '../../helpers';
import t from '../../assets/css/Table.module.css'
import HoverContent from './HoverContent';
import Loader from '../reusable/Loader';

const SubmittedChargesHistoryTable = ({ patients, handleTableChange }) => {
    const [loadingFacesheetId, setLoadingFacesheetId] = useState(null);

    const shared_visits = (patient) => {
        return patient && patient.shared_visits && patient.shared_visits.length > 0 ?
            patient.shared_visits.map((visit, index) => (
                <div key={index}>{visit.name}&nbsp;
                </div>
            )) : '-'
    };

    const handleViewFacesheet = async (patient, index) => {
        setLoadingFacesheetId(index);

        try {
            await ViewFacesheet(patient);
        } catch (error) {
            console.error("Error viewing facesheet:", error);
        } finally {
            setLoadingFacesheetId(null);
        }
    };

    return (
        <table className={`${t.compactTable2} ${t.mobileText}`}>
            <thead id="tableHeader">
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col" className={styles.HideMobile}>Visit Code</th>
                    <th scope="col" className={styles.HideMobile}>Shared Visit</th>
                    <th scope="col">Admit Date</th>
                    <th scope="col">Date of Service</th>
                    <th scope="col" className={styles.HideMobile}>Visit Type</th>
                    <th scope="col" className={styles.HideMobile}>Location</th>
                    <th scope="col">Diagnosis</th>
                    <th scope="col">Status</th>
                    <th scope="col">Facesheet</th>
                    <th scope="col">Provider</th>
                </tr>
            </thead>
            <tbody>
                {patients.map((patient, index) => (
                    <tr key={index}>
                        <td>{capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}</td>
                        <td className={styles.HideMobile}>
                            <div className={t.truncateText}>
                                {patient.visit_codes && patient.visit_codes.length > 0 ? (
                                    <HoverContent
                                        hoverContent={
                                            patient.visit_codes.map((vc, vcIndex) => (
                                                <div key={vcIndex}>
                                                    {vc.visit_code} - {vc.description}
                                                </div>
                                            ))
                                        }
                                        position="top"
                                        maxHeight="200px"
                                    >
                                        {patient.visit_codes.map((vc, vcIndex) => (
                                            <span key={vcIndex}>
                                                {vc.visit_code}
                                                {(patient.visit_codes.length - 1) !== vcIndex && ", "}
                                            </span>
                                        ))}
                                    </HoverContent>
                                ) : (
                                    " - "
                                )}
                            </div>
                        </td>
                        <td className={styles.HideMobile}>{shared_visits(patient)}</td>
                        <td>{isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)}</td>
                        <td>{isValidDate(patient.date_of_service) && convertToCustomDate(patient.date_of_service)}</td>
                        <td className={styles.HideMobile}>{patient.visittype}</td>
                        <td className={styles.HideMobile}>{patient.hospital_abbreviation}</td>
                        <td>
                            <div className={t.truncateText}>
                                {patient?.diagnoses?.length > 0 ? (
                                    <HoverContent
                                        hoverContent={
                                            patient.diagnoses.slice().sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((diagnosis, diagIndex) => (
                                                <div key={diagIndex}>
                                                    {diagnosis?.is_primary ? <span className={styles.required}>*</span> : null}
                                                    {diagnosis.code} - {diagnosis.description}
                                                </div>
                                            ))
                                        }
                                        position="top"
                                        maxHeight="200px"
                                    >
                                        {patient.diagnoses.slice().sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((code, diagIndex) => (
                                            <span key={diagIndex}>
                                                {code?.is_primary ? <span className={styles.required}>*</span> : null}
                                                {code.code}
                                                {(patient?.diagnoses?.length - 1) !== diagIndex && ","}
                                            </span>
                                        ))}
                                    </HoverContent>
                                ) : (
                                    " - "
                                )}
                            </div>
                        </td>
                        <td>{patient.status}</td>
                        <td>
                            {patient.facesheetalias ? (
                                loadingFacesheetId === index ? (
                                    <Loader width={20} height={20} />
                                ) : (
                                    <div onClick={() => handleViewFacesheet(patient, index)} className={styles.viewBtn}>
                                        View
                                    </div>
                                )
                            ) : (
                                <Cross fill={'red'} />
                            )}
                        </td>
                        <td>
                            <span>{patient?.charges_provider_full_name}</span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SubmittedChargesHistoryTable
