import React, { useEffect, useState } from 'react'
import { getTokenFromLocalStorage, isValidDate, convertToCustomDate } from '../../helpers';
import styles from '../../assets/css/ChargesTab.module.css'
import styles2 from '../../assets/css/SubmittedChargesHistory.module.css'
import SubmittedChargesHistoryTable from '../reusable/SubmittedChargesHistoryTable';
import Loader from '../reusable/Loader';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'
import t from '../../assets/css/Table.module.css'

const SubmittedChargesHistory = () => {

    const [chargesHistory, setChargesHistory] = useState([]);
    const [patients, setPatients] = useState([]);
    const [hideTable, setHideTable] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchSubmittedChargesHistory();
    }, [patients]);

    const fetchSubmittedChargesHistory = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`
            }
        };

        const res = await fetch(`${process.env.REACT_APP_API_URL}/charges/submitted-charges-history`, requestOptions);
        const data = await res.json();
        setChargesHistory(data);
        const groupedByTimestamp = data.reduce((acc, obj) => {
            const timestamp = obj.timestamp;
            if (!acc[timestamp]) {
                acc[timestamp] = [];
            }
            acc[timestamp].push(obj);
            return acc;
        }, {});
        setChargesHistory(groupedByTimestamp);
    };

    const fetchPatientChargesHistory = async (patientsData) => {
        try {
            let promiseArray = [];
            patientsData.forEach(patient => {
                const requestOptions = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                    }
                };
                promiseArray.push(fetch(`${process.env.REACT_APP_API_URL}/charges/patient-charges-history?patient_charges_history_id=${patient.patient_charges_history_id}&patientId=${patient.patient_id}`, requestOptions));
            })
            setLoading(true);
            let result = await Promise.all(promiseArray);
            const data = await Promise.all(result.map(res => res.json()));
            const flattenedData = data.flatMap(array => array);
            const uniqueData = [...new Set(flattenedData)];
            setPatients(uniqueData);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const toggleTable = (patients) => {
        setPatients(patients);
        setHideTable(!hideTable);
        if (hideTable) {
            fetchPatientChargesHistory(patients);
        }
    };

    return (<>

        <div className="marginContainer">
            <div className={[styles2.backButton, 'NavigationContainer'].join(' ')}>
                {!hideTable && <div className="back-button-container">
                    <BackIcon onClick={() => toggleTable([])} className="back-button" />
                </div>}
            </div>
            <div className={t.container}>
                <div className={t.tableWrapper}>
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}><Loader /></div>
                    ) : hideTable ? (
                        <table className={`${t.compactTable2} ${t.mobileText}`}>
                            <thead id="tableHeader">
                                <tr>
                                    <th scope="col">Index</th>
                                    <th scope="col">Date of Service</th>
                                    <th scope="col">Total Charges</th>
                                    <th scope="col">Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(chargesHistory).map(([timestamp, group], index) => (
                                    <tr key={index} onClick={() => toggleTable(chargesHistory[timestamp])} style={{ textAlign: 'left', cursor: 'pointer' }}>
                                        <td>
                                            <Eye style={{ height: '15px', width: '15px' }} fill={'var(--purple)'} />&nbsp; {index}
                                        </td>
                                        <td>
                                            {isValidDate(chargesHistory[timestamp][0].date_of_service) && convertToCustomDate(chargesHistory[timestamp][0].date_of_service)}
                                        </td>
                                        <td>{group.length}</td>
                                        <td>{new Date(chargesHistory[timestamp][0].timestamp).toLocaleString()}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <SubmittedChargesHistoryTable handleTableChange={() => toggleTable()} patients={patients} />
                    )}
                </div>
                <div className={t.totalCounter}>
                    {hideTable ? (
                        `Total Submissions: ${Object.keys(chargesHistory).length}`
                    ) : (
                        `Total Patients: ${patients.length}`
                    )}
                </div>
            </div>
        </div>

    </>
    )

}

export default SubmittedChargesHistory