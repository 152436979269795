import React, { useState, useRef, useEffect } from 'react';
import styles from '../../assets/css/HoverContent.module.css';

const HoverContent = ({
    children,
    hoverContent,
    position = 'top',
    maxHeight = '300px',
    className = ''
}) => {
    const [showContent, setShowContent] = useState(false);
    const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
    const triggerRef = useRef(null);
    const contentRef = useRef(null);

    const handleMouseEnter = () => {
        if (triggerRef.current && hoverContent) {
            const rect = triggerRef.current.getBoundingClientRect();

            let top, left;

            switch (position) {
                case 'bottom':
                    top = rect.bottom + 10;
                    left = rect.left + rect.width / 2;
                    break;
                case 'left':
                    top = rect.top + rect.height / 2;
                    left = rect.left - 10;
                    break;
                case 'right':
                    top = rect.top + rect.height / 2;
                    left = rect.right + 10;
                    break;
                case 'top':
                default:
                    top = rect.top - 10;
                    left = rect.left + rect.width / 2;
                    break;
            }

            setHoverPosition({ top, left });
            setShowContent(true);
        }
    };

    const handleMouseLeave = (e) => {
        if (contentRef.current && contentRef.current.contains(e.relatedTarget)) {
            return;
        }
        setShowContent(false);
    };

    const handleContentMouseLeave = (e) => {
        if (triggerRef.current && triggerRef.current.contains(e.relatedTarget)) {
            return;
        }
        setShowContent(false);
    };

    const handleWheel = (e) => {
        e.stopPropagation();
    };

    useEffect(() => {
        if (showContent && contentRef.current) {
            const contentRect = contentRef.current.getBoundingClientRect();
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            let { top, left } = hoverPosition;

            if (contentRect.right > windowWidth) {
                left = windowWidth - contentRect.width - 10;
            } else if (contentRect.left < 0) {
                left = 10;
            }

            if (contentRect.bottom > windowHeight) {
                top = windowHeight - contentRect.height - 10;
            } else if (contentRect.top < 0) {
                top = 10;
            }

            if (top !== hoverPosition.top || left !== hoverPosition.left) {
                setHoverPosition({ top, left });
            }
        }
    }, [showContent, hoverPosition]);

    const getTransform = () => {
        switch (position) {
            case 'bottom': return 'translate(-50%, 0)';
            case 'left': return 'translate(-100%, -50%)';
            case 'right': return 'translate(0, -50%)';
            case 'top':
            default: return 'translate(-50%, -100%)';
        }
    };

    return (
        <div
            className={`${styles.hoverContainer} ${className}`}
            ref={triggerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}

            {showContent && (
                <div
                    ref={contentRef}
                    className={styles.hoverContent}
                    style={{
                        top: `${hoverPosition.top}px`,
                        left: `${hoverPosition.left}px`,
                        transform: getTransform(),
                        maxHeight,
                        width: 'auto'
                    }}
                    onMouseLeave={handleContentMouseLeave}
                    onWheel={handleWheel}
                >
                    {hoverContent}
                </div>
            )}
        </div>
    );
};

export default HoverContent;
