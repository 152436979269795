import { toast } from 'react-toastify';

export const resetPassword = async (token, newPassword, navigate) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, newPassword })
    });

    const data = await response.json();
    if (response.ok) {
      toast.success('Password reset successful');
      setTimeout(() => navigate('/signin'), 2000);
    } else {
      toast.error(data.message || 'Password reset failed');
    }
  } catch (error) {
    toast.error('Network error');
  }
};