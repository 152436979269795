import React, { useMemo } from 'react';
import styles from "../../assets/css/VisitCode.module.css";
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as TickPending } from '../../assets/icons/tick_pending.svg';

function VisitCode({ visitCodes, selectedVisitCodes, handleChange, updateSelectedCode }) {

  const categorizedCodes = useMemo(() => {
    return Array.isArray(visitCodes)
      ? visitCodes.reduce((acc, code) => {
        if (!code.category) return acc;
        acc[code.category] = acc[code.category] || [];
        acc[code.category].push(code);
        return acc;
      }, {})
      : {};
  }, [visitCodes]);

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.column}>
          {/* <div className={styles.listContainer}> */}
          {Object.entries(categorizedCodes).map(([category, codes]) => (
            <div className={styles.DiagnosisBlock}>
              <div className={styles.accordionTitle}>
                <p>{category}</p>
              </div>
              {codes.map((item, index) => (
                <div className={styles.DiagnosisItem} key={index}>
                  <div className={styles.DiagnosisItemCode}>{item.visit_code}: {item.description}</div>
                  <div className={styles.DiagnosisItemDescription}> </div>
                  <div className={styles.checkmarkIcon}><TickPending onClick={() => {
                    const originalCode = visitCodes.find((code) => code.id === item.id);
                    if (originalCode) handleChange("visitCodes", originalCode);
                  }
                  } width={20} height={20} fill={selectedVisitCodes.some((vc) => vc.visit_code === item.visit_code) ? '#5e3ae4' : 'grey'} /></div>
                </div>
              ))}
            </div>
          ))}
          {/* </div> */}
        </div>

        <div className={styles.DiagnosisContainer}>
          <div className={styles.accordionTitle}>
            <p>Selected Visit Codes</p>
            <div className={styles.checkmarkIcon} style={{ border: '1px solid red', borderRadius: '5px' }}><Trash onClick={() => { }} width={20} height={20} fill={'red'} /></div>
          </div>
          <div className={styles.selectedContainer}>
            {
              selectedVisitCodes.map((item, index) => {
                return (
                  <div className={styles.DiagnosisItem} key={index}>
                    <div className={styles.DiagnosisItemCode}>{item.visit_code}: {item.description}</div>
                    <div className={styles.checkmarkIcon}><Trash onClick={() => updateSelectedCode("visitCodes", item)} width={20} height={20} fill={'#E52B50'} /></div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisitCode;
