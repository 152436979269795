
import { toast } from 'react-toastify';
import { getTokenFromLocalStorage } from '../index.js';

export const deleteAllPatients = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/patient/delete-all-patients`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getTokenFromLocalStorage()}`,
        'Content-Type': 'application/json'
      }
    });
    toast.success('All patients deleted successfully');
    return response;
  } catch (error) {
    toast.error('Failed to delete patients');
    throw error;
  }
};