import React, { useState } from 'react';
import { ReactComponent as Tick } from '../../assets/icons/facesheet_tick.svg'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { ReactComponent as Eye } from '../../assets/icons/eye.svg'
import styles from '../../assets/css/PatientList.module.css'
import { useNavigate } from 'react-router-dom'
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate, capitalizeNames, ViewFacesheet } from '../../helpers'
import HoverContent from './HoverContent';
import Loader from '../reusable/Loader';

const TableRow = ({ patient, isEditMode, setPatients }) => {
    const navigate = useNavigate();
    const [loadingFacesheet, setLoadingFacesheet] = useState(false);

    const EditDetails = (patient) => {
        navigate('/patient', { state: { patient: patient, mode: 'view&edit', autoFillChoice: true } });
    };

    const handleStatusToggle = async () => {
        const newStatus = patient.status === 'active' ? 'inactive' : 'active';
        const formData = new URLSearchParams();

        if (patient.id) formData.append('id', patient.id);
        if (patient.firstname) formData.append('firstname', patient.firstname);
        if (patient.lastname) formData.append('lastname', patient.lastname);
        if (patient.middlename) formData.append('middlename', patient.middlename);
        if (patient.dateofbirth) formData.append('dateofbirth', patient.dateofbirth);
        if (patient.room) formData.append('room', patient.room);
        if (patient.hospital_id) formData.append('hospital_id', patient.hospital_id);
        if (patient.admitdate) formData.append('admitdate', patient.admitdate);
        if (patient.dischargedate) formData.append('dischargedate', patient.dischargedate);
        if (patient.visittype) formData.append('visittype', patient.visittype);
        if (patient.gender) formData.append('gender', patient.gender);
        if (patient.owning_provider_id) formData.append('owning_provider_id', patient.owning_provider_id);
        formData.append('status', newStatus);
        if (patient.facesheetalias) formData.append('facesheetalias', patient.facesheetalias);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/patient/add-patient`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + getTokenFromLocalStorage(),
                },
                body: formData.toString(),
            });

            if (response.ok) {
                setPatients((prevPatients) =>
                    prevPatients.map((p) =>
                        p.id === patient.id ? { ...p, status: newStatus } : p
                    )
                );
            } else {
                throw new Error('Failed to update patient status');
            }
        } catch (error) {
            console.error('Error updating status:', error);
            alert('Error updating status');
        }
    };

    const ViewNotelist = () => {
        navigate('/patient', { state: { patient: patient, mode: 'view&edit', autoFillChoice: true, activeTab: 'Notes' } });
    };

    const handleViewFacesheet = async (e) => {
        e.stopPropagation();
        setLoadingFacesheet(true);

        try {
            await ViewFacesheet(patient);
        } catch (error) {
            console.error("Error viewing facesheet:", error);
        } finally {
            setLoadingFacesheet(false);
        }
    };

    return (
        <tr>
            <td onClick={() => EditDetails(patient)}>
                <Eye fill={'var(--purple)'} className={styles.listIcons} />
            </td>
            <td onClick={() => EditDetails(patient)}>
                {capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}
            </td>
            <td onClick={() => EditDetails(patient)} className={styles.HideMobile}>
                {isValidDate(patient.dateofbirth) && convertToCustomDate(patient.dateofbirth)}
            </td>
            <td onClick={() => EditDetails(patient)} className={styles.HideMobile}>
                {isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)}
            </td>
            <td onClick={() => EditDetails(patient)} className={styles.HideMobile} >
                {patient.hospital_abbreviation}
            </td>
            <td onClick={() => EditDetails(patient)} className={styles.HideMobile} >
                {patient.owning_provider_name || '-'}
            </td>
            <td onClick={() => EditDetails(patient)} >
                {patient.visittype !== 'null' ? patient.visittype : ''}
            </td>
            <td onClick={() => EditDetails(patient)} className={styles.HideMobile}>
                {patient.diagnoses?.length > 0 ? (
                    <HoverContent
                        hoverContent={
                            patient.diagnoses.slice().sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((diagnosis, index) => (
                                <div key={index}>
                                    {diagnosis?.is_primary && <span className={styles.required}>*</span>}
                                    {diagnosis.code} - {diagnosis.description}
                                </div>
                            ))
                        }
                        position="top"
                        maxHeight="200px"
                    >
                        {patient.diagnoses.slice().sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((diagnosis, index) => (
                            <span key={index} className={styles.diagnosisName}>
                                {diagnosis?.is_primary && <span className={styles.required}>*</span>}
                                <span style={{ color: 'black' }}>{diagnosis.code}</span>
                                {index !== patient.diagnoses.length - 1 && <span>, </span>}
                            </span>
                        ))}
                    </HoverContent>
                ) : (
                    ' - '
                )}
            </td>
            <td >
                {isEditMode ? (
                    <div className={`${styles.toggleSwitch} ${patient.status === 'active' ? styles.active : styles.inactive}`}
                        onClick={handleStatusToggle}>
                        <div className={styles.toggleButton}></div>
                        <span className={styles.toggleLabel}>
                            {patient.status === 'active' ? 'active' : 'inactive'}
                        </span>
                    </div>
                ) : (
                    patient.status
                )}
            </td>
            <td onClick={() => EditDetails(patient)}>
                {patient.facesheetalias ? (
                    loadingFacesheet ? (
                        <Loader width={20} height={20} />
                    ) : (
                        <div onClick={handleViewFacesheet} className={styles.viewBtn}>
                            View
                        </div>
                    )
                ) : (
                    <Cross fill={'red'} />
                )}
            </td>
            <td onClick={() => EditDetails(patient)}>
                {patient?.latest_note ? (
                    <Tick fill={'green'} onClick={(e) => { e.stopPropagation(); ViewNotelist(); }} />
                ) : (
                    <Cross fill={'red'} onClick={(e) => { e.stopPropagation(); ViewNotelist(); }} />
                )}
            </td>
        </tr>
    )
};

export default TableRow
