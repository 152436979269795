import React, { useState, useEffect } from 'react';
import styles from '../../assets/css/DiagnosisCode.module.css';
import styles1 from '../../assets/css/Diagnosis.module.css';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { getTokenFromLocalStorage } from '../../helpers';
import { toast } from 'react-toastify';

const Diagnosis = ({ handleSelectedDiagnosis, DiagnosisArray, subMode, mode }) => {
    const [showModal, setShowModal] = useState(false);
    const [notesInput, setNotesInput] = useState('');

    const [selectedDiagnosis, setSelectedDiagnosis] = useState(DiagnosisArray);
    const [tempSelectedDiagnosis, setTempSelectedDiagnosis] = useState(DiagnosisArray);

    const handleSave = () => {
        handleSelectedDiagnosis(tempSelectedDiagnosis);
        setSelectedDiagnosis(tempSelectedDiagnosis);
        toggleModal();
    };

    useEffect(() => {
        if (DiagnosisArray.length > 0) {
            setSelectedDiagnosis(DiagnosisArray);
            setTempSelectedDiagnosis(DiagnosisArray);
        }
    }, [DiagnosisArray]);

    const toggleModal = () => {
        setShowModal(!showModal);
        setTempSelectedDiagnosis(selectedDiagnosis);
    };

    const handleNotesChange = (e) => {
        setNotesInput(e.target.value);
    };

    const handleConvertNotes = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/notes/convert-notes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify({ description: notesInput })
            });

            if (response.ok) {
                const data = await response.json();
                const updatedDiagnoses = [...tempSelectedDiagnosis, ...data];
                setTempSelectedDiagnosis(updatedDiagnoses);
                toast.success('Notes converted successfully');
            } else {
                toast.error('Failed to convert notes');
            }
        } catch (error) {
            toast.error('An error occurred while converting notes');
        }
    };

    const handleDelete = (itemToDelete) => {
        const updatedDiagnoses = tempSelectedDiagnosis.filter(
            (item) => item.id !== itemToDelete.id
        );
        setTempSelectedDiagnosis(updatedDiagnoses);
    };

    return (
        <div className={styles1.container}>
            <div className={styles1.diagnosisHeading}>
                {(mode === 'add' || subMode === 'edit') && <div className={styles1.addButton} onClick={toggleModal} >
                    <div>+Add Diagnosis</div>
                </div>}
            </div>

            <div className={styles1.selectedDiagnosisContainer}>
                <div className={styles1.selectedDiagnosisWrapper} style={{ padding: selectedDiagnosis.length > 0 ? '10px' : '0px' }}>
                    {selectedDiagnosis.slice().sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((item) => (
                        <div className={styles1.selectedDiagnosis} key={item.id}>
                            <div className={styles1.diagnosisName}>
                                {item?.is_primary ? <span className={styles.required}>*</span> : null} <span style={{ color: 'black' }}>{item.code}: </span> {item.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modalContent" style={{ minWidth: '40vw' }}>
                        <div className="closeModalButton" onClick={toggleModal}><Cross fill={'red'} /></div>
                        <h3 className={styles1.modalTitle}>Diagnosis:</h3>
                        <div className={styles1.boxWrapper}>
                            <div className={styles.box}>
                                <div className={styles.column}>
                                    <div className={styles.listContainer}>
                                        <h4 className={styles.title}>Convert Notes to ICD-10</h4>
                                        <textarea
                                            className={styles.notesTextArea}
                                            placeholder="Paste your notes here or type for diagnosis..."
                                            value={notesInput}
                                            onChange={handleNotesChange}
                                        ></textarea>
                                        <button
                                            type="button"
                                            className={styles.convertButton}
                                            onClick={handleConvertNotes}
                                        >
                                            Convert Notes
                                        </button>
                                    </div>
                                </div>

                                <div className={styles.DiagnosisBlock}>
                                    <div className={styles.accordionTitle}>
                                        <p>Selected Diagnosis</p>
                                    </div>
                                    <div className={styles.selectedContainer}>
                                        {tempSelectedDiagnosis?.slice().sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((item, index) => (
                                            <div style={{ justifyContent: 'space-between' }} className={styles.DiagnosisItem} key={index}>
                                                <div className={styles.DiagnosisItemCode}>{item?.is_primary ? <div className={styles.required}>*</div> : null}
                                                    {item.code}: {item.description}</div>
                                                <div className={styles.checkmarkIcon}>
                                                    <Trash onClick={() => handleDelete(item)} width={12} height={12} fill={'#E52B50'} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleSave()} className={[styles1.button, styles1.SaveButton].join(' ')}>
                            Save
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Diagnosis;
