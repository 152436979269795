import { toast } from 'react-toastify';

export const verifyOtp = async (formData, navigate) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verify-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData.toString(),
    });
    const data = await response.json();
    if (data.message === 'User registered successfully') {
      toast.success('User Registered successfully!');
      navigate('/signin');
    }
    if (data.message === 'Invalid OTP') {
      toast.error('Invalid OTP');
    }
  } catch (error) {
    toast.error(error.message);
  }
}

export const sendOTP = async (formData, setOtpStatus) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData.toString(),
    });

    const data = await response.json();
    if (response.ok) {
      toast.success('OTP sent successfully!');
      setOtpStatus(true);
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    console.log(error);
  }
};