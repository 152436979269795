import React, { useEffect, useState } from 'react';
import styles from '../../assets/css/Patient.module.css';
import GenericTable from '../reusable/GenericTable.js';
import styles1 from '../../assets/css/ChargesTab.module.css';
import styles2 from '../../assets/css/Admissions.module.css';
import styles3 from '../../assets/css/PatientList.module.css';
import { ReactComponent as SharedIcon } from '../../assets/icons/charges-shared-visit.svg';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg';
import { convertToCustomDate, getTokenFromLocalStorage, isValidDate, ViewFacesheet } from '../../helpers/index.js';
import Loader from '../reusable/Loader.js';
import AdmissionDetails from './AdmissionDetails.js';
import t from '../../assets/css/Table.module.css';
import HoverContent from '../reusable/HoverContent';

const Admissions = ({ patient, subMode }) => {
    const [admissionsData, setAdmissionsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAdmissionDetails, setShowAdmissionDetails] = useState(false);
    const [currentPatient, setCurrentPatient] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 631);
    const [isNewAdmission, setIsNewAdmission] = useState(false);
    const [loadingFacesheetId, setLoadingFacesheetId] = useState(null); // Track which facesheet is loading by ID

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 631);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchAdmissions = async (patient_id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admission/admissions?patient_id=${patient_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                }
            });
            if (response.ok) {
                const data = await response.json();
                setAdmissionsData(data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching admissions data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAdmissions(patient.patient_id);
    }, [patient.patient_id]);

    const onCellClick = (row) => {
        setIsNewAdmission(false);
        setShowAdmissionDetails(true);
        setCurrentPatient(row);
    };

    const handleBack = () => {
        setShowAdmissionDetails(false);
        setCurrentPatient(null);
        setIsNewAdmission(false);
        fetchAdmissions(patient.patient_id);
    };

    const handleNewAdmissionClick = () => {
        setIsNewAdmission(true);
        setShowAdmissionDetails(true);
        setCurrentPatient({
            patient_id: patient.patient_id,
            admitdate: null,
            location: null,
            room: null,
            dischargedate: null,
            visittype: null,
            facesheetalias: null,
            owning_provider_id: null,
        });
    };

    if (loading) {
        return <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}><Loader width={100} height={100} /></div>;
    }

    const handleViewFacesheet = async (row, index) => {
        setLoadingFacesheetId(index);

        try {
            const url = await ViewFacesheet(row);

            if (url) {
                window.open(url, "_blank");
            }
        } catch (error) {
            console.error("Error opening facesheet:", error);
        } finally {
            setLoadingFacesheetId(null);
        }
    };

    return (
        <div className={styles2.container}>
            {showAdmissionDetails ? (
                <AdmissionDetails
                    mode={isNewAdmission ? 'add' : 'view&edit'}
                    subMode={isNewAdmission ? 'edit' : subMode}
                    patient={currentPatient}
                    onBack={handleBack}
                />
            ) : (
                <>
                    <div className={styles2.newAdmissionContainer}>
                        <div onClick={handleNewAdmissionClick} style={{ gap: '1rem' }} className={[styles1.button, styles1.SharedVisitsBtn].join(' ')}>
                            <SharedIcon className={styles.OptionButtonsIcon} />
                            <div className={styles.actionsBtnText}>New Admission</div>
                        </div>
                    </div>
                    {admissionsData.length > 0 && (
                        <div className={t.container}>
                            <div className={t.tableWrapper}>
                                <table className={`${t.compactTable2} ${t.mobileText}`} >
                                    <thead id='tableHeader'>
                                        <tr>
                                            <th scope="col" ></th>
                                            <th scope="col" >Admit Date</th>
                                            <th scope="col" >Location</th>
                                            <th scope="col" >Provider</th>
                                            <th scope="col" >Diagnoses Codes</th>
                                            <th scope="col" >Visit Type</th>
                                            <th scope="col" >Discharge Date</th>
                                            <th scope="col" >Facesheet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {admissionsData.map((row, index) => (
                                            <tr key={index} >
                                                <td onClick={() => onCellClick(row)}>
                                                    <Eye
                                                        fill={'var(--purple)'}
                                                        className={styles2.eyeIcon}
                                                    />
                                                </td>
                                                <td>{isValidDate(row.admitdate) && convertToCustomDate(row.admitdate)}</td>
                                                <td>{row.hospital_abbreviation || 'N/A'}</td>
                                                <td>{row.owning_provider_name}</td>
                                                <td>
                                                    {row.diagnoses && row.diagnoses.length > 0 ? (
                                                        <HoverContent
                                                            hoverContent={
                                                                row.diagnoses.sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((diagnosis, diagIndex) => (
                                                                    <div key={diagIndex}>
                                                                        {diagnosis?.is_primary ? <span className={styles.required}>*</span> : null}
                                                                        {diagnosis.code} - {diagnosis.description}
                                                                    </div>
                                                                ))
                                                            }
                                                            position="top"
                                                            maxHeight="200px"
                                                        >
                                                            {row.diagnoses.sort((a, b) => (b.is_primary ? 1 : 0) - (a.is_primary ? 1 : 0)).map((r, diagIndex) => (
                                                                <span key={diagIndex}>
                                                                    {r?.is_primary ? <span className={styles.required}>*</span> : null}
                                                                    {r.code}
                                                                    {(row.diagnoses.length - 1) !== diagIndex && ', '}
                                                                </span>
                                                            ))}
                                                        </HoverContent>
                                                    ) : (
                                                        ' - '
                                                    )}
                                                </td>
                                                <td>{row.visittype}</td>
                                                <td>{isValidDate(row.dischargedate) && convertToCustomDate(row.dischargedate)}</td>
                                                <td>
                                                    {row.facesheetalias && row.facesheetalias !== 'null' ? (
                                                        loadingFacesheetId === index ? (
                                                            <Loader width={20} height={20} />
                                                        ) : (
                                                            <div onClick={() => handleViewFacesheet(row, index)} className={styles2.viewBtn}>
                                                                View
                                                            </div>
                                                        )
                                                    ) : (
                                                        <Cross fill={'red'} />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className={t.totalCounter}>
                                Total Admissions: {admissionsData.length}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Admissions;
