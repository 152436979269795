import React, { useEffect, useState, useMemo } from 'react'
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg'
import { fetchVisitCodes, updatePatientCharges } from '../../helpers';
import styles2 from '../../assets/css/ChargesVisitCodes.module.css'
import styles from '../../assets/css/VisitCode.module.css'
import { ReactComponent as TickPending } from '../../assets/icons/tick_pending.svg';
import { ReactComponent as Trash } from '../../assets/icons/trash.svg';
import { toast } from 'react-toastify';

const ChargesVisitCodes = ({ setShowModal, selectedPatients, reRenderPatients }) => {
    const [visitCodes, setVisitCodes] = useState([]);
    const [selectedVisitCodes, setSelectedVisitCodes] = useState([]);

    useEffect(() => {
        handleFetchVisitCodes();
        let visitCodes = [];
        selectedPatients.map((p) => {
            return p.visit_codes.forEach(vc => {
                visitCodes.push(vc);
            })
        })
        //remove duplicates from visitCodes
        visitCodes = visitCodes.filter((visitCode, index, self) =>
            index === self.findIndex((t) => t.id === visitCode.id)
        );
        setSelectedVisitCodes(visitCodes);
    }, []);


    const handleFetchVisitCodes = async () => {
        try {
            const visitCodes = await fetchVisitCodes();
            setVisitCodes(visitCodes);
        } catch (error) {
            console.error('Error fetching visit codes:', error);
        }
    };

    const handleSave = async () => {
        try {
            let ids_array = [];
            if (selectedVisitCodes.length > 0) {
                selectedPatients.forEach((patient) => {
                    selectedVisitCodes.forEach((visitCode) => {
                        ids_array.push({
                            patientId: patient.patient_id,
                            chargesId: visitCode.id,
                            charges_page_id: patient.charges_page_id,
                            admission_id: patient.id
                        });
                    });
                    patient.visit_codes = selectedVisitCodes;
                });
            }
            else {
                selectedPatients.forEach((patient) => {
                    ids_array.push({
                        patientId: patient.patient_id,
                        chargesId: null,
                        charges_page_id: patient.charges_page_id,
                        admission_id: patient.id
                    });
                    patient.visit_codes = [];
                });
            }
            await updatePatientCharges(ids_array);
            setShowModal(false);
            reRenderPatients(' Visit Codes Updated! ')
        } catch (error) {
            console.error('Error updating visit codes:', error);
            toast.error('Error updating visit codes');
        }
    }

    const handleVisitCode = (VisitCode, mode) => {
        if (mode === "delete") {
            const updatedVC = selectedVisitCodes.filter((vc) => VisitCode.id !== vc.id);
            setSelectedVisitCodes(updatedVC);
        }
        else {
            const alreadyAdded = selectedVisitCodes.find((vc) => VisitCode.id === vc.id);
            if (alreadyAdded) {
                return;
            }
            const updatedVC = [...selectedVisitCodes, VisitCode];
            setSelectedVisitCodes(updatedVC);
        }
    }

    const categorizedCodes = useMemo(() => {
        return Array.isArray(visitCodes)
            ? visitCodes.reduce((acc, code) => {
                if (!code.category) return acc;
                acc[code.category] = acc[code.category] || [];
                acc[code.category].push(code);
                return acc;
            }, {})
            : {};
    }, [visitCodes]);


    return (
        <div className="modal">
            <div className="modalContent">
                <div className="closeModalButton" onClick={() => setShowModal(false)}><Cross fill={'red'} /></div>
                <h3 className={styles2.modalTitle}>Select the Visit Codes :</h3>
                <div className={styles2.container}>
                    <div className={styles.box}>
                        <div className={styles.column}>
                            {Object.entries(categorizedCodes).map(([category, codes]) => (
                                <div className={styles.DiagnosisBlock}>
                                    <div className={styles.accordionTitle}>
                                        <p>{category}</p>
                                    </div>
                                    {codes.map((item, index) => (
                                        <div className={styles.DiagnosisItem} key={index}>
                                            <div className={styles.DiagnosisItemCode}>{item.visit_code}: {item.description}</div>
                                            <div className={styles.DiagnosisItemDescription}> </div>
                                            <div className={styles.checkmarkIcon}><TickPending onClick={() => {
                                                const originalCode = visitCodes.find((code) => code.id === item.id);
                                                if (originalCode) handleVisitCode(originalCode, "add");
                                            }
                                            } width={20} height={20} fill={selectedVisitCodes.some((vc) => vc.visit_code === item.visit_code) ? '#5e3ae4' : 'grey'} /></div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                        <div className={styles.DiagnosisContainer}>
                            <div className={styles.accordionTitle}>
                                <p>Selected Visit Codes</p>
                                <div className={styles.checkmarkIcon} style={{ border: '1px solid red', borderRadius: '5px' }}><Trash onClick={() => setSelectedVisitCodes([])} width={20} height={20} fill={'red'} /></div>
                            </div>
                            <div className={styles.selectedContainer}>
                                {
                                    selectedVisitCodes.map((item, index) => {
                                        return (
                                            <div className={styles.DiagnosisItem} key={index}>
                                                <div className={styles.DiagnosisItemCode}>{item.visit_code}: {item.description}</div>
                                                {/* <div className={styles.DiagnosisItemDescription}> </div> */}
                                                <div className={styles.checkmarkIcon}><Trash onClick={() => handleVisitCode(item, "delete")} width={20} height={20} fill={'#E52B50'} /></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div onClick={() => handleSave()} className={[styles2.button, styles2.SaveButton].join(' ')}>
                    Save
                </div>
            </div>
        </div>
    )
}

export default ChargesVisitCodes
