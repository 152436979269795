import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import s from '../../assets/css/ChargeReview.module.css';
import t from '../../assets/css/Table.module.css'
import { shared_visits, submitCharges } from '../../helpers/Charges/charges-review';
import { fetchUserAddedCharges } from '../../helpers/Charges/charges-review';
import { ReactComponent as Tick } from '../../assets/icons/facesheet_tick.svg';
import { ReactComponent as Cross } from '../../assets/icons/facesheet_cross.svg';
import Loader from '../reusable/Loader';
import { ReactComponent as SubmitIcon } from '../../assets/icons/charges-submit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg'
import s2 from '../../assets/css/ChargesTab.module.css';
import { capitalizeNames, convertToCalendarSpecificDate, convertToCustomDate, fetchAuthorizedProviders, fetchSharedVisitUsers, fetchVisitCodes, getTokenFromLocalStorage, handleDates, isValidDate } from '../../helpers';
import { toast } from 'react-toastify';
import { useMediaQuery } from '@mui/material';

const ChargeReview = () => {
    const [patientChargesHistory, setPatientChargesHistory] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [authorizedProviders, setAuthorizedProviders] = useState([]);
    const [visitCodes, setVisitCodes] = useState([]);
    const [sharedVisitUsers, setSharedVisitUsers] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const isLandscape = useMediaQuery('(orientation: landscape) and (max-width: 1000px)');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [visitCodesData, authorizedProvidersData, sharedVisitUsersData, userAddedChargesData] = await Promise.all([
                    fetchVisitCodes(),
                    fetchAuthorizedProviders(),
                    fetchSharedVisitUsers(),
                    fetchUserAddedCharges()
                ]);

                setVisitCodes(visitCodesData);
                setAuthorizedProviders(authorizedProvidersData);
                setSharedVisitUsers(sharedVisitUsersData);
                setPatientChargesHistory(userAddedChargesData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const handleVisitCodeChange = async (selectedOptions, patientIndex) => {
        try {
            const updatedPatientChargesHistory = [...patientChargesHistory];
            const visit_codes = selectedOptions.map(option => ({
                id: option.id,
                visit_code: option.visit_code,
                description: option.description
            }));

            await updatePatientChargeHistory(patientChargesHistory[patientIndex], {
                visit_codes
            }, patientChargesHistory[patientIndex].charges_provider_id);

            updatedPatientChargesHistory[patientIndex].visit_codes = visit_codes;
            setPatientChargesHistory(updatedPatientChargesHistory);
        } catch (error) {
            // Handle error - maybe show a notification to user
            console.error('Failed to update visit codes:', error);
        }
    };

    const handleAdmitDateChange = async (date, patientIndex) => {
        try {
            const updatedPatientChargesHistory = [...patientChargesHistory];

            await updatePatientChargeHistory(patientChargesHistory[patientIndex], {
                admitdate: date
            }, patientChargesHistory[patientIndex].charges_provider_id);

            updatedPatientChargesHistory[patientIndex].admitdate = date;
            setPatientChargesHistory(updatedPatientChargesHistory);
        } catch (error) {
            console.error('Failed to update admit date:', error);
        }
    };

    const handleDateOfServiceChange = async (date, patientIndex) => {
        try {
            const updatedPatientChargesHistory = [...patientChargesHistory];

            await updatePatientChargeHistory(patientChargesHistory[patientIndex], {
                date_of_service: date
            }, patientChargesHistory[patientIndex].charges_provider_id);

            updatedPatientChargesHistory[patientIndex].date_of_service = date;
            setPatientChargesHistory(updatedPatientChargesHistory);
        } catch (error) {
            console.error('Failed to update date of service:', error);
        }
    };

    const handleSharedVisitChange = async (selectedOption, patientIndex) => {
        try {
            const updatedPatientChargesHistory = [...patientChargesHistory];
            const shared_visits = selectedOption ? [{
                id: selectedOption.value,
                name: selectedOption.label
            }] : [];

            await updatePatientChargeHistory(patientChargesHistory[patientIndex], {
                shared_visits
            }, patientChargesHistory[patientIndex].charges_provider_id);

            updatedPatientChargesHistory[patientIndex].shared_visits = shared_visits;
            setPatientChargesHistory(updatedPatientChargesHistory);
        } catch (error) {
            console.error('Failed to update shared visits:', error);
        }
    };

    const handleProviderChange = async (selectedOption, patientIndex) => {
        try {
            const updatedPatientChargesHistory = [...patientChargesHistory];

            // Update in the database first
            const charges_provider_id = selectedOption ? selectedOption.value : null;
            await updatePatientChargeHistory(patientChargesHistory[patientIndex], {
                charges_provider_id
            }, charges_provider_id);

            // If database update successful, update the local state
            updatedPatientChargesHistory[patientIndex].charges_provider_id = selectedOption ? selectedOption.value : null;
            updatedPatientChargesHistory[patientIndex].submitter_firstname = selectedOption ? selectedOption.firstname : null;
            updatedPatientChargesHistory[patientIndex].submitter_lastname = selectedOption ? selectedOption.lastname : null;
            updatedPatientChargesHistory[patientIndex].submitter_title = selectedOption ? selectedOption.title : null;
            setPatientChargesHistory(updatedPatientChargesHistory);
        } catch (error) {
            console.error('Failed to update provider:', error);
            toast.error('Failed to update provider');
        }
    };

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setSelectedRows(patientChargesHistory.map(patient => patient.id));
        } else {
            setSelectedRows([]);
        }
    };

    const handleSelectRow = (patientId) => {
        setSelectedRows(prev => {
            if (prev.includes(patientId)) {
                const newSelected = prev.filter(id => id !== patientId);
                setSelectAll(false);
                return newSelected;
            } else {
                const newSelected = [...prev, patientId];
                setSelectAll(newSelected.length === patientChargesHistory.length);
                return newSelected;
            }
        });
    };

    const updatePatientChargeHistory = async (patient_charges_history, updateData, charges_provider_id) => {

        patient_charges_history.charges_provider_id = charges_provider_id;
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/charges/patient-charges-history`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                },
                body: JSON.stringify({
                    patient_charges_history,
                    ...updateData,
                    charges_provider_id
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update patient charge history');
            }

            const result = await response.json();
            if (result.success) {
                toast.success('Updated!');
            }
        } catch (error) {
            console.error('Error updating patient charge history:', error);
            throw error;
        }
    };

    const handleDelete = async () => {
        if (selectedRows.length === 0) {
            toast.warning('Please select records to delete');
            return;
        }

        if (!window.confirm(`Are you sure you want to delete ${selectedRows.length} selected record(s)?`)) {
            return;
        }

        setDeleteLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/charges/patient-charges-history`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenFromLocalStorage()}`
                },
                body: JSON.stringify({
                    ids: selectedRows
                })
            });

            const result = await response.json();

            if (result.success) {
                toast.success('Records deleted successfully');
                // Remove deleted records from the state
                setPatientChargesHistory(prev => prev.filter(record => !selectedRows.includes(record.id)));
                setSelectedRows([]);
                setSelectAll(false);
            } else {
                toast.error(result.message || 'Failed to delete records');
            }
        } catch (error) {
            console.error('Error deleting records:', error);
            toast.error('Error deleting records');
        } finally {
            setDeleteLoading(false);
        }
    };

    return (
        <div className={`marginContainer mt1 ${isLandscape ? s.mt0 : ''}`}>
            <div className={[s.toggleContainer, 'mb1'].join(' ')}>
                <span>Edit Mode</span>
                <div className="toggle" onClick={() => setIsEditMode(!isEditMode)}>
                    <div className={`toggle-slider ${isEditMode ? 'active' : ''}`}></div>
                </div>
                {isEditMode && (deleteLoading ? <Loader /> :
                    <DeleteIcon onClick={handleDelete} style={{ cursor: 'pointer' }} width={20} height={20} fill={'#E52B50'} />
                )}
            </div>
            <div className={t.container}>
                <div className={t.tableWrapper}>
                    <table className={`${t.compactTable2} ${t.mobileText}`}>
                        <thead id="tableHeader">
                            <tr>
                                {isEditMode && (
                                    <th scope="col">
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            className={s.checkbox}
                                        />
                                    </th>
                                )}
                                <th scope="col">Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Visit Code</th>
                                <th scope="col">Shared Visit</th>
                                <th scope="col">Admit Date</th>
                                <th scope="col">Date Of Service</th>
                                <th scope="col">Visit Type</th>
                                <th scope="col">Location</th>
                                <th scope="col">Diagnoses</th>
                                <th scope="col">Status</th>
                                <th scope="col">Facesheet</th>
                                <th scope="col">Provider</th>
                            </tr >
                        </thead >
                        <tbody>
                            {patientChargesHistory.map((patient, index) => {
                                return (
                                    <tr key={index}>
                                        {isEditMode && (
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(patient.id)}
                                                    onChange={() => handleSelectRow(patient.id)}
                                                    className={s.checkbox}
                                                />
                                            </td>
                                        )}
                                        <th scope="col">{patient.id}</th>
                                        <td>{capitalizeNames(patient.firstname, patient.middlename, patient.lastname)}</td>
                                        <td>
                                            {isEditMode ? (
                                                <Select
                                                    menuPortalTarget={document.body}
                                                    isMulti
                                                    options={visitCodes.map(code => ({
                                                        id: code.id,
                                                        visit_code: code.visit_code,
                                                        description: code.description,
                                                        value: code.visit_code,
                                                        label: `${code.visit_code} - ${code.description}`
                                                    }))}
                                                    value={patient.visit_codes.map(vc => ({
                                                        id: vc.id,
                                                        visit_code: vc.visit_code,
                                                        description: vc.description,
                                                        value: vc.visit_code,
                                                        label: `${vc.visit_code}`
                                                    }))}
                                                    onChange={(selectedOptions) => handleVisitCodeChange(selectedOptions, index)}
                                                    styles={{
                                                        container: (provided) => ({
                                                            ...provided,
                                                            minWidth: '151px'
                                                        }),
                                                        option: (provided) => ({
                                                            ...provided,
                                                            fontSize: '10px',
                                                            color: '#333',
                                                        }),
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '14px',
                                                            borderColor: state.isFocused ? 'var(--table-purple)' : provided.borderColor,
                                                            '&:hover': {
                                                                borderColor: 'var(--table-purple)'
                                                            },
                                                            boxShadow: state.isFocused ? '0 0 0 1px var(--table-purple)' : provided.boxShadow,
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            fontSize: '14px',
                                                        }),
                                                        multiValue: (provided) => ({
                                                            ...provided,
                                                            fontSize: '14px',
                                                            width: '100%',
                                                            '& > div:first-of-type': {
                                                                textOverflow: 'unset',
                                                                paddingRight: '10px'
                                                            }
                                                        }),
                                                        multiValueLabel: (provided) => ({
                                                            ...provided,
                                                        }),
                                                        dropdownIndicator: (provided, state) => ({
                                                            ...provided,
                                                            color: state.isFocused ? 'var(--table-purple)' : provided.color,
                                                            '&:hover': {
                                                                color: 'var(--table-purple)'
                                                            }
                                                        }),
                                                        clearIndicator: (provided, state) => ({
                                                            ...provided,
                                                            color: state.isFocused ? 'red' : provided.color,
                                                            '&:hover': {
                                                                color: 'red'
                                                            }
                                                        })
                                                    }}
                                                />

                                            ) : (
                                                patient.visit_codes && patient.visit_codes.map((vc, i) => {
                                                    return (vc.visit_code + (patient.visit_codes.length - 1 !== i ? ', ' : ''));
                                                })
                                            )}
                                        </td>
                                        <td>
                                            {isEditMode ? (
                                                <Select
                                                    menuPortalTarget={document.body}
                                                    options={sharedVisitUsers.map(user => ({
                                                        value: user.id,
                                                        label: user.name
                                                    }))}
                                                    value={patient.shared_visits?.[0] ? {
                                                        value: patient.shared_visits[0].id,
                                                        label: patient.shared_visits[0].name
                                                    } : null}
                                                    onChange={(selectedOption) => handleSharedVisitChange(selectedOption, index)}
                                                    isClearable
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '10px',
                                                            color: state.isSelected ? 'white' : '#333',
                                                            backgroundColor: state.isSelected ? 'var(--table-purple)' : provided.backgroundColor,
                                                            '&:hover': {
                                                                opacity: 0.8
                                                            }
                                                        }),
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '14px',
                                                            borderColor: state.isFocused ? 'var(--table-purple)' : provided.borderColor,
                                                            '&:hover': {
                                                                borderColor: 'var(--table-purple)'
                                                            },
                                                            boxShadow: state.isFocused ? '0 0 0 1px var(--table-purple)' : provided.boxShadow,
                                                            minWidth: '180px',
                                                            maxWidth: '350px'
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            fontSize: '14px'
                                                        }),
                                                        dropdownIndicator: (provided, state) => ({
                                                            ...provided,
                                                            color: state.isFocused ? 'var(--table-purple)' : provided.color,
                                                            '&:hover': {
                                                                color: 'var(--table-purple)'
                                                            }
                                                        }),
                                                        clearIndicator: (provided, state) => ({
                                                            ...provided,
                                                            color: state.isFocused ? 'red' : provided.color,
                                                            '&:hover': {
                                                                color: 'red'
                                                            }
                                                        })
                                                    }}
                                                />
                                            ) : (
                                                shared_visits(patient)
                                            )}
                                        </td>
                                        <td>
                                            {isEditMode ? (
                                                <input
                                                    type="date"
                                                    value={patient.admitdate && isValidDate(patient.admitdate) ? convertToCalendarSpecificDate(patient.admitdate) : ''}
                                                    onChange={(e) => handleAdmitDateChange(e.target.value, index)}
                                                    className={t.dateInput}
                                                />
                                            ) : (
                                                isValidDate(patient.admitdate) && convertToCustomDate(patient.admitdate)
                                            )}
                                        </td>
                                        <td>
                                            {isEditMode ? (
                                                <input
                                                    type="date"
                                                    value={patient.date_of_service && isValidDate(patient.date_of_service) ? convertToCalendarSpecificDate(patient.date_of_service) : ''}
                                                    onChange={(e) => handleDateOfServiceChange(e.target.value, index)}
                                                    className={t.dateInput}
                                                />
                                            ) : (
                                                isValidDate(patient.date_of_service) && convertToCustomDate(patient.date_of_service)
                                            )}
                                        </td>
                                        <td>{patient.visittype}</td>
                                        <td>{patient.hospital_abbreviation}</td>
                                        <td>
                                            {patient?.diagnoses?.slice().sort((a, b) => (b?.is_primary ? 1 : 0) - (a?.is_primary ? 1 : 0)).map((code, index) => (
                                                <span key={index}>
                                                    {code?.is_primary ? <span className={s.required}>*</span> : null}
                                                    {code.code}
                                                    {(patient?.diagnoses?.length - 1) !== index && ","}
                                                </span>
                                            ))}
                                        </td>
                                        <td>{patient.status}</td>
                                        <td>{patient.facesheetalias ? <Tick fill={'green'} /> : <Cross fill={'red'} />}</td>
                                        <td>
                                            {isEditMode ? (
                                                <Select
                                                    menuPortalTarget={document.body}
                                                    options={authorizedProviders.map(provider => ({
                                                        value: provider.id,
                                                        label: `${provider.firstname} ${provider.lastname} - ${provider.title}`,
                                                        firstname: provider.firstname,
                                                        lastname: provider.lastname,
                                                        title: provider.title
                                                    }))}
                                                    value={patient.submitter_firstname ? {
                                                        value: patient.charges_provider_id,
                                                        label: `${patient.submitter_firstname} ${patient.submitter_lastname}`,
                                                        firstname: patient.submitter_firstname,
                                                        lastname: patient.submitter_lastname,
                                                        title: patient.submitter_title
                                                    } : null}
                                                    onChange={(selectedOption) => handleProviderChange(selectedOption, index)}
                                                    isClearable
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '10px',
                                                            color: state.isSelected ? 'white' : '#333',
                                                            backgroundColor: state.isSelected ? 'var(--table-purple)' : provided.backgroundColor,
                                                            '&:hover': {
                                                                backgroundColor: 'var(--table-purple)',
                                                                opacity: 0.8
                                                            }
                                                        }),
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            fontSize: '14px',
                                                            borderColor: state.isFocused ? 'var(--table-purple)' : provided.borderColor,
                                                            '&:hover': {
                                                                borderColor: 'var(--table-purple)'
                                                            },
                                                            boxShadow: state.isFocused ? '0 0 0 1px var(--table-purple)' : provided.boxShadow,
                                                            minWidth: '180px',
                                                            maxWidth: '350px'
                                                        }),
                                                        singleValue: (provided) => ({
                                                            ...provided,
                                                            fontSize: '14px'
                                                        }),
                                                        dropdownIndicator: (provided, state) => ({
                                                            ...provided,
                                                            color: state.isFocused ? 'var(--table-purple)' : provided.color,
                                                            '&:hover': {
                                                                color: 'var(--table-purple)'
                                                            }
                                                        }),
                                                        clearIndicator: (provided, state) => ({
                                                            ...provided,
                                                            color: state.isFocused ? 'red' : provided.color,
                                                            '&:hover': {
                                                                color: 'red'
                                                            }
                                                        })
                                                    }}
                                                />
                                            ) : (
                                                capitalizeNames(patient.submitter_firstname, '', patient.submitter_lastname)
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className={t.totalCounter}>
                    Total Patients: {patientChargesHistory.length}
                </div>

            </div>
            <div className={s2.actionButtonsContainer}>
                {submitLoading ? <Loader /> :
                    <div onClick={() => submitCharges()} className={[s2.button, s2.SubmitChargesBtn].join(' ')}>
                        <SubmitIcon className={s2.OptionButtonsIcon} />
                        <div className={s2.actionsBtnText}>Submit Charges to AMD</div>
                    </div>
                }
            </div>
        </div >
    );
};

export default ChargeReview;