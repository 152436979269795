import React, { useState } from 'react';
import { getTokenFromLocalStorage } from '../../../helpers';
import Toast from '../Toast';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format } from 'date-fns';

const MultipleAppointmentModal = ({ onClose, onSubmit }) => {
    const [timeLimit, setTimeLimit] = useState(30);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [text, setText] = useState('Available for appointments');
    const [classname] = useState('green');
    const [hospitalId] = useState('1');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('');

    const showErrorToast = (message) => {
        setToastMessage(message);
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const showSuccessToast = (message) => {
        setToastMessage(message);
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 3000);
    };

    const handleSubmit = async () => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start > end) {
            showErrorToast("Start date cannot be greater than end date");
            return;
        }

        const diffInMinutes = (end - start) / (1000 * 60);
        if (diffInMinutes % timeLimit !== 0) {
            showErrorToast("The appointment duration cannot fit within the chosen start and end times");
            return;
        }

        const appointments = [];

        while (start < end) {
            const appointmentEnd = new Date(start.getTime() + timeLimit * 60000);
            if (appointmentEnd > end) break;

            appointments.push({
                start_date: format(start, 'yyyy-MM-dd HH:mm'),
                end_date: format(appointmentEnd, 'yyyy-MM-dd HH:mm'),
                text,
                classname,
                hospitalId
            });

            start.setTime(start.getTime() + timeLimit * 60000);
        }

        const promises = appointments.map(appointment => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenFromLocalStorage()
                },
                body: JSON.stringify(appointment)
            };

            return fetch(process.env.REACT_APP_API_URL + '/appointment/appointment-setter', requestOptions);
        });

        await Promise.all(promises);
        onSubmit();
    };

    const handleSetDate = (mode, isoDate) => {
        if (mode === 'start_date') {
            setStartDate(isoDate);
        }
        else {
            setEndDate(isoDate);
        }
    }

    return (
        <div className="modal">
            {showToast && <Toast message={toastMessage} type={toastType} onClose={() => setShowToast(false)} />}
            <div className="modern-modal">
                <div className="modern-modal-content">
                    <h2 className="modern-modal-header">Add Multiple Appointments</h2>
                    <div className="modern-modal-body">
                        <div className="modal-item-container">
                            <div className="modal-item">
                                <label className="labelLeftMarginUnset">Appointment Length</label>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            value="15"
                                            checked={timeLimit === 15}
                                            onChange={(e) => setTimeLimit(parseInt(e.target.value))}
                                        />
                                        15 minutes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="30"
                                            checked={timeLimit === 30}
                                            onChange={(e) => setTimeLimit(parseInt(e.target.value))}
                                        />
                                        30 minutes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value="60"
                                            checked={timeLimit === 60}
                                            onChange={(e) => setTimeLimit(parseInt(e.target.value))}
                                        />
                                        60 minutes
                                    </label>
                                </div>
                            </div>
                            <div className="modal-item">
                                <label className="labelLeftMarginUnset">Start Date & Time</label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        value={startDate ? startDate : null}
                                        onChange={(newValue) => handleSetDate('start_date', newValue)}
                                        minutesStep={15}
                                        skipDisabled
                                        ampm={false}
                                        minTime={new Date(0, 0, 0, 6)}
                                        maxTime={new Date(0, 0, 0, 19)}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="modal-item">
                                <label className="labelLeftMarginUnset">End Date & Time</label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateTimePicker
                                        value={endDate ? endDate : null}
                                        onChange={(newValue) => handleSetDate('end_date', newValue)}
                                        minutesStep={15}
                                        skipDisabled
                                        ampm={false}
                                        minTime={new Date(0, 0, 0, 6)}
                                        maxTime={new Date(0, 0, 0, 19)}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="modal-item">
                                <label className="labelLeftMarginUnset">Description</label>
                                <input className="inputDefault" type="text" value={text} onChange={(e) => setText(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="modern-modal-footer">
                        <button className="modern-btn modern-btn-secondary" onClick={onClose}>Cancel</button>
                        <button className="modern-btn modern-btn-primary" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultipleAppointmentModal;    